import React, { useState, useEffect } from 'react';
import { Drawer, Card, CardHeader, CardContent, CardActions, Divider, Box, TextField, IconButton, Button, CircularProgress, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Grid, Paper } from '@mui/material';
import { useFormik } from 'formik';
import api from '../../../axios';
import { SaveSupplierSchema } from './SaveSupplierSchema';

import useStyles from '../formStyle';
import CloseIcon from '@mui/icons-material/Close';

const SaveSupplier = ({ isOpen, toggleDrawer, entity, ShowTost }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            supplierId: entity.supplierId || '',
            createdDate: entity.createdDate || '',
            modifiedDate: entity.modifiedDate || '',
            createdId: entity.createdId || '',
            Id: entity.Id || '',
            Title: entity.Title || 0,
            GivenName: entity.GivenName || '',
            MiddleName: entity.MiddleName || '',
            FamilyName: entity.FamilyName || '',
            Suffix: entity.Suffix || '',
            CompanyName: entity.CompanyName || '',
            DisplayName: entity.DisplayName || '',
            Active: entity.Active || '',
            PrimaryPhone: entity.PrimaryPhone || '',
            AlternatePhone: entity.AlternatePhone || '',
            Mobile: entity.Mobile || '',
            Fax: entity.Fax || '',
            PrimaryEmailAddr: entity.PrimaryEmailAddr || '',
            WebAddr: entity.WebAddr || '',
            BillAddrLine1: entity.BillAddrLine1 || '',
            BillAddrLine2: entity.BillAddrLine2 || '',
            BillAddrCity: entity.BillAddrCity || '',
            BillAddrCountry: entity.BillAddrCountry || '',
            BillAddrCountrySubDivisionCode: entity.BillAddrCountrySubDivisionCode || '',
            BillAddrPostalCode: entity.BillAddrPostalCode || '',
        },
        validationSchema: SaveSupplierSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const apiUrl = '/api/Suppliers/SaveSupplier';
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const data = new FormData();
                if (values.supplierId) {
                    data.append('supplierId', values.supplierId);
                }
                if (values.createdDate) {
                    data.append('createdDate', values.createdDate);
                }
                if (values.modifiedDate) {
                    data.append('modifiedDate', values.modifiedDate);
                }
                if (values.createdId) {
                    data.append('createdId', values.createdId);
                }
                if (values.Id) {
                    data.append('Id', values.Id);
                }
                if (values.Title) {
                    data.append('Title', values.Title);
                }
                if (values.GivenName) {
                    data.append('GivenName', values.GivenName);
                }
                if (values.MiddleName) {
                    data.append('MiddleName', values.MiddleName);
                }
                if (values.FamilyName) {
                    data.append('FamilyName', values.FamilyName);
                }
                if (values.Suffix) {
                    data.append('Suffix', values.Suffix);
                }
                if (values.CompanyName) {
                    data.append('CompanyName', values.CompanyName);
                }
                if (values.DisplayName) {
                    data.append('DisplayName', values.DisplayName);
                }
                if (values.Active) {
                    data.append('Active', values.Active);
                }
                if (values.PrimaryPhone) {
                    data.append('PrimaryPhone', values.PrimaryPhone);
                }
                if (values.AlternatePhone) {
                    data.append('AlternatePhone', values.AlternatePhone);
                }
                if (values.Mobile) {
                    data.append('Mobile', values.Mobile);
                }
                if (values.Fax) {
                    data.append('Fax', values.Fax);
                }
                if (values.PrimaryEmailAddr) {
                    data.append('PrimaryEmailAddr', values.PrimaryEmailAddr);
                }
                if (values.WebAddr) {
                    data.append('WebAddr', values.WebAddr);
                }
                if (values.BillAddrLine1) {
                    data.append('BillAddrLine1', values.BillAddrLine1);
                }
                if (values.BillAddrLine2) {
                    data.append('BillAddrLine2', values.BillAddrLine2);
                }
                if (values.BillAddrCity) {
                    data.append('BillAddrCity', values.BillAddrCity);
                }
                if (values.BillAddrCountry) {
                    data.append('BillAddrCountry', values.BillAddrCountry);
                }
                if (values.BillAddrCountrySubDivisionCode) {
                    data.append('BillAddrCountrySubDivisionCode', values.BillAddrCountrySubDivisionCode);
                }
                if (values.BillAddrPostalCode) {
                    data.append('BillAddrPostalCode', values.BillAddrPostalCode);
                }
                const response = await api.post(apiUrl, data, config);
                if (response.status === 200) {
                    console.log('created successfully.');
                    ShowTost(response.data);
                    formik.resetForm();
                } else {
                    ShowTost('Something went wrong.');
                    console.error('Something went wrong.');
                }
            } catch (error) {
                ShowTost('Error:', error.message);
                console.error('Error:', error.message);
            } finally {
                setLoading(false);
                toggleDrawer();
            }
        },
    });
    useEffect(() => {
        formik.setValues({
            supplierId: entity.supplierId || '',
            createdDate: entity.createdDate || '',
            modifiedDate: entity.modifiedDate || '',
            createdId: entity.createdId || '',
            Id: entity.Id || '',
            Title: 'Mr',
            GivenName: entity.GivenName || '',
            MiddleName: entity.MiddleName || '',
            FamilyName: entity.FamilyName || '',
            Suffix: entity.Suffix || '',
            CompanyName: entity.CompanyName || '',
            DisplayName: entity.DisplayName || '',
            Active: entity.Active || '',
            PrimaryPhone: entity.PrimaryPhone || '',
            AlternatePhone: entity.AlternatePhone || '',
            Mobile: entity.Mobile || '',
            Fax: entity.Fax || '',
            PrimaryEmailAddr: entity.PrimaryEmailAddr || '',
            WebAddr: entity.WebAddr || '',
            BillAddrLine1: entity.BillAddrLine1 || '',
            BillAddrLine2: entity.BillAddrLine2 || '',
            BillAddrCity: entity.BillAddrCity || '',
            BillAddrCountry: entity.BillAddrCountry || '',
            BillAddrCountrySubDivisionCode: entity.BillAddrCountrySubDivisionCode || '',
            BillAddrPostalCode: entity.BillAddrPostalCode || '',
        });
    }, [entity]);

    return (
        <>
            <Drawer anchor='right'
                open={isOpen}
                onClose={toggleDrawer}
                variant='temporary'
                ModalProps={{
                    keepMounted: true,
                }}
                PaperProps={{
                    sx: {
                        width: '100%',
                        maxWidth: '700px', // Adjust width as needed
                    },
                }} >

                <div style={{ height: '100vh', overflow: 'auto' }}>
                    <Card>
                        <CardHeader title="Save Supplier" className={classes.header}
                            action={
                                <>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        aria-label="close"
                                        onClick={toggleDrawer}
                                        style={{ marginRight: 2 }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </>
                            }>
                        </CardHeader>
                        <CardContent>
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={1} >

                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                label='CompanyName'
                                                name='CompanyName'
                                                value={formik.values.CompanyName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                label='DisplayName'
                                                name='DisplayName'
                                                value={formik.values.DisplayName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.DisplayName && Boolean(formik.errors.DisplayName)}
                                                helperText={formik.touched.DisplayName && formik.errors.DisplayName}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={1}>
                                        <FormControl fullWidth margin='normal'>
                                            <Select
                                                name='Title'
                                                value={formik.values.Title}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.Title && Boolean(formik.errors.Title)}
                                                helperText={formik.touched.Title && formik.errors.Title}
                                            >
                                                <MenuItem value='Mr'>Mr</MenuItem>
                                                <MenuItem value='Mrs'>Mrs</MenuItem>
                                                <MenuItem value='Miss'>Miss</MenuItem>
                                                <MenuItem value='Ms'>Ms</MenuItem>
                                                <MenuItem value='Mx'>Mx</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                label='First Name'
                                                name='GivenName'
                                                value={formik.values.GivenName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.GivenName && Boolean(formik.errors.GivenName)}
                                                helperText={formik.touched.GivenName && formik.errors.GivenName}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                label='Middle Name'
                                                name='MiddleName'
                                                value={formik.values.MiddleName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                label='Last Name'
                                                name='FamilyName'
                                                value={formik.values.FamilyName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                label='Suffix'
                                                name='Suffix'
                                                value={formik.values.Suffix}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                label='Email Address'
                                                name='PrimaryEmailAddr'
                                                value={formik.values.PrimaryEmailAddr}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.PrimaryEmailAddr && Boolean(formik.errors.PrimaryEmailAddr)}
                                                helperText={formik.touched.PrimaryEmailAddr && formik.errors.PrimaryEmailAddr}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                label='Phone Number'
                                                name='PrimaryPhone'
                                                value={formik.values.PrimaryPhone}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                label='Mobile'
                                                name='Mobile'
                                                value={formik.values.Mobile}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.Mobile && Boolean(formik.errors.Mobile)}
                                                helperText={formik.touched.Mobile && formik.errors.Mobile}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                label='Alternate Phone'
                                                name='AlternatePhone'
                                                value={formik.values.AlternatePhone}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                label='Fax'
                                                name='Fax'
                                                value={formik.values.Fax}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                label='Website'
                                                name='WebAddr'
                                                value={formik.values.WebAddr}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Divider orientation="vertical" flexItem>---Address---</Divider>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                label='Street address 1'
                                                name='BillAddrLine1'
                                                value={formik.values.BillAddrLine1}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                label='Street address 2'
                                                name='BillAddrLine2'
                                                value={formik.values.BillAddrLine2}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                label='City'
                                                name='BillAddrCity'
                                                value={formik.values.BillAddrCity}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                label='State'
                                                name='BillAddrCountrySubDivisionCode'
                                                value={formik.values.BillAddrCountrySubDivisionCode}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                label='ZIP code'
                                                name='BillAddrPostalCode'
                                                value={formik.values.BillAddrPostalCode}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                label='Country'
                                                name='BillAddrCountry'
                                                value={formik.values.BillAddrCountry}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <FormControl fullWidth margin='normal'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name='Active'
                                                        checked={formik.values.Active}
                                                        onChange={formik.handleChange}
                                                    />
                                                }
                                                label='Active'
                                            />
                                        </FormControl>
                                    </Grid>

                                </Grid>
                                
                                <CardActions className={classes.footer}>
                                    <Button type="submit" disabled={loading} variant="contained" color="primary" size="large" className={classes.largeButton}>
                                        {loading ? (
                                            <>
                                                Please wait...
                                                <CircularProgress size={20} style={{ marginLeft: 8 }} />
                                            </>
                                        ) : (
                                            'Save'
                                        )}
                                    </Button>
                                </CardActions>
                            </form>
                        </CardContent>
                    </Card>
                </div>
            </Drawer >
        </>
    )
};
export default SaveSupplier;
