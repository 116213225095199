import React, { useState, useEffect } from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, TextField, TablePagination, Card, CardHeader, CardContent, IconButton, Skeleton, Menu, MenuItem, Collapse, Box, Typography, Chip, Tooltip } from '@mui/material';
import { Edit as EditIcon, AddCircle as AddIcon, KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material';
import api from '../../../axios';
import useStyles from '../formStyle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReusableSnackbar from '../../../Component/ReusableSnackbar';
import SaveGroups from './SaveGroups';
import SaveGroupItem from './SaveGroupItem';

const Groups = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [entity, setEntity] = useState({
        groupId: 0,
        createdDate: '',
        modifiedDate: '',
        createdId: 0,
        name: '',
        notes: '',
        colorCode: '',
        active: true,
    });
    //---group item
    const [entity2, setEntity2] = useState({
        giId: 0,
        name: '',
        price: 0,
        notes: '',
        active: true,
        groupId: 0

    });

    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get('/api/Masters/GetAllGroups', config);
            console.log(response.data);
            setData(response.data || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setEntity({
            groupId: 0,
            createdDate: '',
            modifiedDate: '',
            createdId: 0,
            name: '',
            notes: '',
            colorCode: '',
            active: true,
        });
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = data.filter((row) =>
        Object.values(row).some((value) => value.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleToggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    //------Group Item-------
    const [drawerOpen2, setDrawerOpen2] = useState(false);
    const handleToggleDrawer2 = () => {
        setDrawerOpen2(!drawerOpen2);
    };

    // useEffect(() => {
    //     if (!drawerOpen || !drawerOpen2) {
    //         console.log('!drawerOpen');
    //         fetchData();
    //     }
    //     if (!drawerOpen2) {
    //         console.log('drawerOpen');
    //         fetchData();
    //     }
    // }, [drawerOpen, drawerOpen2]);

    const handleEdit = (editData) => {
        setEntity({
            groupId: editData.groupId,
            createdDate: editData.createdDate,
            modifiedDate: editData.modifiedDate,
            createdId: editData.createdId,
            name: editData.name,
            notes: editData.notes,
            colorCode: editData.colorCode,
            active: editData.active,
        });
        setDrawerOpen(true);
    }

    const handleAddGroupItem = (editData) => {
        setEntity({
            groupId: editData.groupId,
            createdDate: editData.createdDate,
            modifiedDate: editData.modifiedDate,
            createdId: editData.createdId,
            name: editData.name,
            notes: editData.notes,
            colorCode: editData.colorCode,
            active: editData.active,
        });
        setDrawerOpen2(true);
    }

    const handleEditGroupItem = (editData) => {
        setEntity(prevEntity => ({
            ...prevEntity, // Keep other fields unchanged
            groupId: editData.groupId, // Update groupId
        }));
        setEntity2({
            giId: editData.giId,
            name: editData.name,
            price: editData.price,
            notes: editData.notes,
            active: editData.active,
            groupId: editData.groupId,
        });
        setDrawerOpen2(true);
    }

    const handleLoadData = () => {
        fetchData();
    };

    const handleTost = (msg) => {
        setSnackbarMessage(msg);
        setOpenSnackbar(true);
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const [openGroups, setOpenGroups] = useState({});

    const toggleGroup = (groupId) => {
        setOpenGroups((prevState) => ({
            ...prevState,
            [groupId]: !prevState[groupId]
        }));
    };

    return (
        <>
            <ReusableSnackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
            <SaveGroups isOpen={drawerOpen} toggleDrawer={handleToggleDrawer} entity={entity} LoadData={handleLoadData} ShowTost={handleTost} />
            <SaveGroupItem isOpen={drawerOpen2} toggleDrawer={handleToggleDrawer2} group={entity} entity={entity2} LoadData={handleLoadData} ShowTost={handleTost} />
            <Card>
                <CardHeader title='Options Master' className={classes.header}
                    action={
                        <>
                            <IconButton
                                aria-label='more'
                                id='long-button'
                                aria-haspopup='true'
                                onClick={handleMenuClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={() => { handleToggleDrawer(); handleMenuClose(); }}>Add New</MenuItem>
                            </Menu>
                        </>
                    }
                >
                </CardHeader>

                <CardContent>
                    <TextField
                        label='Search'
                        variant='outlined'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: '10px', float: 'right' }}
                    />
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.header}>
                                    <TableCell />
                                    {/* <TableCell style={{ fontWeight: 'bold' }}>Id</TableCell> */}
                                    <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Notes</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Colour Code</TableCell>
                                    <TableCell style={{ float: 'right', fontWeight: 'bold' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <Skeleton animation='wave' />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    paginatedData.map((user, index) => (
                                        <React.Fragment key={user.groupId}>
                                            <TableRow style={{backgroundColor: user.active ? 'inherit' : 'lightgrey',}}>
                                                <TableCell>
                                                    <Tooltip title='Expand'>
                                                        <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => toggleGroup(user.groupId)}
                                                        >
                                                            {openGroups[user.groupId] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                                {/* <TableCell>{user.groupId}</TableCell> */}
                                                <TableCell>{user.name}</TableCell>
                                                <TableCell>{user.notes}</TableCell>

                                                <TableCell>
                                                    <Chip
                                                        label={user.active ? 'Active' : 'Not Active'}
                                                        color={user.active ? 'primary' : 'secondary'}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <span style={{ backgroundColor: user.colorCode, padding: '5px' }}>Code {user.colorCode}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title='Edit'>
                                                        <IconButton
                                                            color='primary'
                                                            onClick={() => handleEdit(user)}
                                                            style={{ float: 'right' }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title='Add Group Item'>
                                                        <IconButton
                                                            color='primary'
                                                            onClick={() => handleAddGroupItem(user)}
                                                            style={{ float: 'right' }}
                                                        >
                                                            <AddIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                            {openGroups[user.groupId] && user.GroupItems && user.GroupItems.length > 0 && (
                                                <TableRow>
                                                    <TableCell colSpan={6}>
                                                        <Collapse in={openGroups[user.groupId]} timeout="auto" unmountOnExit>
                                                            <Box sx={{ margin: 1 }}>
                                                                <Typography variant="h6" gutterBottom component="div">
                                                                    Group Items
                                                                </Typography>
                                                                <Table size="small" aria-label="a dense table">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold' }}>Item Name</TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold' }}>Price</TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold' }}>Notes</TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                                                                            <TableCell style={{ float: 'right', fontWeight: 'bold' }}>Edit</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {user.GroupItems.map((item) => (
                                                                            <TableRow key={item.giId}>
                                                                                <TableCell>{item.name}</TableCell>
                                                                                <TableCell>{item.price}</TableCell>
                                                                                <TableCell>{item.notes}</TableCell>
                                                                                <TableCell>
                                                                                    <Chip
                                                                                        label={item.active ? 'Active' : 'Not Active'}
                                                                                        color={item.active ? 'primary' : 'secondary'}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Tooltip title='Edit Group Item'>
                                                                                        <IconButton
                                                                                            color='primary'
                                                                                            onClick={() => handleEditGroupItem(item)}
                                                                                            style={{ float: 'right' }}
                                                                                        >
                                                                                            <EditIcon />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </React.Fragment>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card>
        </>
    )
};

export default Groups;
