import React, { useState, useEffect } from 'react';
import { Drawer, Divider, Box, TextField, TextareaAutosize, Button, CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../../../axios';

const validationSchema = Yup.object({
    roleName: Yup.string().required('Please enter a role name'),
});

const SaveRole = ({ isOpen, toggleDrawer, entity }) => {
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            roleName: entity.roleName,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const apiUrl = '/api/Users/SaveRole';
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`,
                    },
                };


                const response = await api.post(apiUrl, values, config);

                if (response.status === 200) {
                    console.log('Plan markers created successfully.');
                    formik.resetForm();
                } else {
                    console.error('Something went wrong.');
                }
            } catch (error) {
                console.error('Error:', error.message);
            } finally {
                setLoading(false);
                toggleDrawer();
            }
        },
    });

    useEffect(() => {
        formik.setValues({
            id: entity.id || 0,
            roleName: entity.roleName || '',
        });
    }, [entity]);


    return (
        <div>
            <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
                {/* Drawer content goes here */}
                <div style={{ width: '300px', paddingLeft: '10px', paddingRight: '10px' }}>
                    <h2>Save Role</h2>
                    <Divider style={{ marginBottom: '25px' }} />

                    <form onSubmit={formik.handleSubmit}>
                        <Box mt={3}>
                            <TextField
                                label="Role Name"
                                variant="outlined"
                                fullWidth
                                name="roleName"
                                value={formik.values.roleName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.roleName && Boolean(formik.errors.roleName)}
                                helperText={formik.touched.roleName && formik.errors.roleName}
                            />
                        </Box>
                        <Box mt={3}>
                            <Button type="submit" disabled={loading} fullWidth variant="contained" size="large" style={{ marginTop: '10px' }} sx={{ mt: 2 }}>
                                {loading ? (
                                    <>
                                        Please wait...
                                        <CircularProgress size={20} style={{ marginLeft: 8 }} />
                                    </>
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </Box>
                    </form>

                </div>
            </Drawer>
        </div>
    )
};

export default SaveRole;
