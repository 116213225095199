import React, { useState, useEffect } from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, TextField, TablePagination, Card, CardHeader, CardContent, IconButton, Skeleton, Menu, MenuItem, Tooltip } from '@mui/material';
import { Edit as EditIcon, Download as ReportIcon,RequestQuote as QuotationIcon } from '@mui/icons-material';
import api from '../../../axios';
import useStyles from '../../admin/formStyle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { RemoveRedEye as ViewIcon } from '@mui/icons-material';
import ReusableSnackbar from '../../../Component/ReusableSnackbar';
import SaveSiteSurvey from './SaveSiteSurvey';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import SurveyBreadcrumbs from './SurveyBreadcrumbs';

const formatDate = (dateString) => {
    return format(new Date(dateString), 'dd-MMM-yyyy HH:mm');
};

const SiteSurveyTech = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [entity, setEntity] = useState({
        id: 0,
        createdDate: '',
        modifiedDate: '',
        createdId: 0,
        clientId: 0,
        surveyRef: '',
        building: '',
        surveyDateTime: '',
        siteContactPerson: '',
        notes: '',
        surveyStatus: '',
        techCompleteStatus: false,
        techCompleteDate: '',
        quotCreatedDate: '',
        quotNo: '',
        quotAmount: 0,
    });


    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get('/api/Technician/GetPendingSiteSurvey', config);
            setData(response.data || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setEntity({
            id: 0,
            createdDate: '',
            modifiedDate: '',
            createdId: 0,
            clientId: 0,
            surveyRef: '',
            building: '',
            surveyDateTime: '',
            siteContactPerson: '',
            notes: '',
            surveyStatus: '',
            techCompleteStatus: false,
            techCompleteDate: '',
            quotCreatedDate: '',
            quotNo: '',
            quotAmount: 0,
        });
        setAnchorEl(null);
    };

    const handleMenuCompleted = () => {
        navigate(`/technician/surveycompleted`);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = data.filter((row) =>
        Object.values(row).some((value) => (value !== null && value.toString().toLowerCase().includes(searchTerm.toLowerCase())))
    );


    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleToggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    useEffect(() => {
        if (!drawerOpen) {
            fetchData();
        }
    }, [drawerOpen]);

    const handleEdit = (editData) => {
        setEntity({
            id: editData.id,
            createdDate: editData.createdDate,
            modifiedDate: editData.modifiedDate,
            createdId: editData.createdId,
            clientId: editData.clientId,
            surveyRef: editData.surveyRef,
            building: editData.building,
            surveyDateTime: editData.surveyDateTime,
            siteContactPerson: editData.siteContactPerson,
            notes: editData.notes,
            surveyStatus: editData.surveyStatus,
            techCompleteStatus: editData.techCompleteStatus,
            techCompleteDate: editData.techCompleteDate,
            quotCreatedDate: editData.quotCreatedDate,
            quotNo: editData.quotNo,
            quotAmount: editData.quotAmount,
        });
        setDrawerOpen(true);
    }

    const handleSiteSurveyDetails = (id) => {
        navigate(`/technician/sitesurveydetails/${id}`);
    }

    const handleCreateQuotation = async (id) => {
        const confirmation = window.confirm('Are you sure you want to create quotation?');
        if (confirmation) {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const response = await api.post(`/api/Survey/ConvertToQuotation?surveyId=${id}`, null, config);
                if (response.status === 200) {
                    console.log(response);
                    alert(response.data);
                }
            } catch (error) {
                console.error('Error deleting data:', error);
            }
        }
    }

    const handleLoadData = () => {
        fetchData();
    };

    const handleReport = async (reportId) => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                responseType: 'blob', // Important
            };
            const response = await api.get('/api/Survey/GetCanvasData?docId=' + reportId, config);
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);

            // Open the URL in a new tab
            window.open(url, '_blank');
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleTost = (msg) => {
        setSnackbarMessage(msg);
        setOpenSnackbar(true);
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <>
            <ReusableSnackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
            <SaveSiteSurvey isOpen={drawerOpen} toggleDrawer={handleToggleDrawer} entity={entity} LoadData={handleLoadData} ShowTost={handleTost} />
            <SurveyBreadcrumbs />
            <Card>
                <CardHeader title='Site Survey' className={classes.header}
                    action={
                        <>
                            <IconButton
                                aria-label='more'
                                id='long-button'
                                aria-haspopup='true'
                                onClick={handleMenuClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={() => { handleToggleDrawer(); handleMenuClose(); }}>Add Site Survey</MenuItem>
                                <MenuItem onClick={handleMenuCompleted}>Completed</MenuItem>
                            </Menu>
                        </>
                    }
                >
                </CardHeader>

                <CardContent>
                    <TextField
                        label='Search'
                        variant='outlined'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: '10px', float: 'right' }}
                    />
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.header}>
                                    <TableCell style={{ fontWeight: 'bold' }}>id</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Created Date</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Created By</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Client</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Survey Ref</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Building</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Survey Date</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Contact Person</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Survey Status</TableCell>
                                    <TableCell style={{ float: 'right', fontWeight: 'bold' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={10}>
                                            <Skeleton animation='wave' />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    paginatedData.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{user.id}</TableCell>
                                            <TableCell>{formatDate(user.createdDate)}</TableCell>
                                            <TableCell>{user.Users.firstName} {user.Users.lastName}</TableCell>
                                            <TableCell>{user.Client.CompanyName}</TableCell>
                                            <TableCell>{user.surveyRef}</TableCell>
                                            <TableCell>{user.building}</TableCell>
                                            <TableCell>{formatDate(user.surveyDateTime)}</TableCell>
                                            <TableCell>{user.siteContactPerson}</TableCell>
                                            <TableCell>{user.surveyStatus}</TableCell>
                                            <TableCell>
                                                <Tooltip title='Edit Survey'>
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => handleEdit(user)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Download Report'>
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => handleReport(user.reportId)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <ReportIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='View Details'>
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => handleSiteSurveyDetails(user.id)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <ViewIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                {/* <Tooltip title='Create Quotation'>
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => handleCreateQuotation(user.id)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <QuotationIcon />
                                                    </IconButton>
                                                </Tooltip> */}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card>
        </>
    )
};

export default SiteSurveyTech;
