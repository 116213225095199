import React, { useState, useEffect } from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, TextField, TablePagination, Card, CardHeader, CardContent, IconButton, Skeleton } from '@mui/material';
import { GetApp as DownloadIcon } from '@mui/icons-material';
import api from '../../../../axios';
import useStyles from "../../formStyle";
import SettingBreadcrumbs from '../SettingBreadcrumbs';

const WhosOnline = () => {
    const classes = useStyles();

    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const response = await api.get('/api/Settings/GetOnlineUsers', config);
                console.log(response.data);
                setData(response.data || []); // Ensure data is not undefined or null
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = data.filter((row) =>
        Object.values(row).some((value) => (value !== null && value.toString().toLowerCase().includes(searchTerm.toLowerCase())))
    );

    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <>
            <SettingBreadcrumbs />
            <Card>
                <CardHeader title="Who's Online" className={classes.header}></CardHeader>
                <CardContent>
                    <TextField
                        label="Search"
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: '10px', float: 'right' }}
                    />
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.header}>
                                    <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Role</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Mobile</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Email Address</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Device</TableCell>
                                    <TableCell style={{ float: 'right', fontWeight: 'bold' }}>Action</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    // Placeholder Skeleton while loading
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <Skeleton animation="wave" />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    // Render actual data
                                    paginatedData.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
                                            <TableCell>{user.Roles.roleName}</TableCell>
                                            <TableCell>{user.mobile}</TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>{user.device}</TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card>
        </>
    )
};

export default WhosOnline;
