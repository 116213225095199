import React from 'react';
import ReactDOM from "react-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ThemeProvider } from "@mui/material";
import Themes from "./themes";

// ReactDOM.render(
//   <React.StrictMode>
//     <ThemeProvider theme={Themes.default}>
//       <App />
//     </ThemeProvider>
//   </React.StrictMode>,
//   document.getElementById("root"),
// );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //StrictMode in a development it useEffect run twice
  // <React.StrictMode>
  <ThemeProvider theme={Themes.default}>
    <App />
  </ThemeProvider>
  // </React.StrictMode>
);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <ThemeProvider theme={Themes.default}>
//       <AuthProvider>
//         <App />
//       </AuthProvider>
//     </ThemeProvider>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
