import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedClientRoute = ({ element }) => {
    const token = JSON.parse(localStorage.getItem('user'));

    if (token && token.user && token.user.userType === 4) {
        return <>{element}</>;
    } else {
        return <Navigate to="/" replace />;
    }
};

export default ProtectedClientRoute;