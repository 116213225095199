import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation } from 'react-router-dom';
import {
    NoteAlt as ManageSurveyIcon,
    FactCheck as CompletedSurveyIcon,
} from '@mui/icons-material';

const breadcrumbItems = [
    { title: 'Site Survey', path: '/admin/sitesurvey', icon: <ManageSurveyIcon /> },
    { title: 'Completed Site Survey', path: '/admin/SiteSurveyCompleted', icon: <CompletedSurveyIcon /> },
];

const linkStyle = {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit',
};

export default function SurveyBreadcrumbs() {
    const location = useLocation();

    // Function to get the title from breadcrumbItems based on the current path
    const getTitleFromPath = (path) => {
        const item = breadcrumbItems.find((item) => item.path === path);
        return item ? item.title : '';
    };

    return (
        <Breadcrumbs aria-label="breadcrumb" style={{ margin: 5 }}>
            {breadcrumbItems.map((item, index) => (
                <Link
                    key={index}
                    to={item.path}
                    style={linkStyle}
                >
                    {item.icon && React.cloneElement(item.icon, { sx: { mr: 0.5 } })}
                    <Typography
                        sx={{ display: 'flex', alignItems: 'center' }}
                        color="text.primary"
                    >
                        {item.title}
                    </Typography>
                </Link>
            ))}
            <Typography
                sx={{ display: 'flex', alignItems: 'center',fontWeight:'bold' }}
                color="text.primary"
            >
                {getTitleFromPath(location.pathname)}
            </Typography>
        </Breadcrumbs>
    );
}
