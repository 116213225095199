import React, { useState, useEffect } from 'react';
//import Drawer from '@mui/material/Drawer';
//import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
//import { Divider } from '@mui/material';

import { Button,Drawer, CircularProgress,Divider } from '@mui/material';

import api from "../../../../axios";
import { Lock } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const CompanyLogo = ({ isOpen, toggleDrawer, logoname }) => {
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileImage, setFileImage] = useState(null);

  useEffect(() => {
    setSelectedImage(process.env.REACT_APP_BASE_URL + logoname);
  }, [logoname]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        setFileImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async () => {
    // You can implement the image upload logic here
    if (selectedImage) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append('image', fileImage);

        const user = JSON.parse(localStorage.getItem('user'));
        const token = user.token;
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        };

        const response = await api.post('/api/Settings/UploadLogo', formData, config);
        //console.log('Image uploaded successfully:', response.data);
      } catch (error) {

      } finally {
        setLoading(false);
        toggleDrawer();
      }


    } else {
      console.log('No image selected');
    }
  };

  return (
    <div>
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        {/* Drawer content goes here */}
        <div style={{ width: '300px', paddingLeft: '10px', paddingRight: '10px' }}>
          <h2>Company Logo</h2>
          <Divider style={{ marginBottom: '25px' }} />
          <img src={selectedImage} alt="Selected" style={{ width: '100%', height: '100px' }} />
          <Button
            component="label"
            role={undefined}
            variant="contained"
            fullWidth
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            Upload logo
            <VisuallyHiddenInput type="file" onChange={handleImageChange} accept="image/*" />
          </Button>
          <Button type="submit" onClick={handleUpload} disabled={loading} fullWidth variant="contained" size="large" style={{ marginTop: '10px' }} sx={{ mt: 2 }}>
            {loading ? (
              <>
                Please wait...
                <CircularProgress size={20} style={{ marginLeft: 8 }} />
              </>
            ) : (
              'Save Logo'
            )}
          </Button>
          {/* <Button type="submit" onClick={handleUpload} fullWidth variant="contained" color="primary" size="large" style={{ marginTop: '10px' }}>
            Save Logo
          </Button> */}

        </div>
      </Drawer>
    </div>
  )
};

export default CompanyLogo;
