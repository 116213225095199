import React, { useRef, useState } from "react";
import { Button, Checkbox, FormControlLabel, TextField, CircularProgress } from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import api from "../../../axios"; // Importing your API setup

const ClientSignature = (props) => {
    const [loading, setLoading] = useState(false);
    const [clientAccepted, setClientAccepted] = useState(false);
    const [clientNotes, setClientNotes] = useState("");
    const sigCanvas = useRef({});

    const handleSubmit = async () => {
        const clientSignature = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");

        const formData = {
            jobId: props.id,
            clientAccepted,
            clientSignature,
            clientNotes,
        };

        try {
            setLoading(true);
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user?.token; // Ensure token is fetched correctly
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.post("/api/Technician/SaveClientSignature", formData, config);
            alert(response.data);
        } catch (error) {
            console.error("Error submitting data:", error);
            alert("There was an error submitting the data. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleClearSignature = () => {
        sigCanvas.current.clear();
    };

    return (
        <div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={clientAccepted}
                        onChange={(e) => setClientAccepted(e.target.checked)}
                        color="primary"
                    />
                }
                label="I accept the terms and conditions"
            />

            <SignatureCanvas
                ref={sigCanvas}
                canvasProps={{
                    width: 300,
                    height: 200,
                    style: { border: "2px solid #000", borderRadius: "8px" },
                }}
            />

            <Button variant="outlined" onClick={handleClearSignature} style={{ marginTop: 16 }}>
                Clear Signature
            </Button>

            <TextField
                label="Notes"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={clientNotes}
                onChange={(e) => setClientNotes(e.target.value)}
                style={{ marginTop: 16 }}
            />

            <Button onClick={handleSubmit} disabled={loading} variant='contained' color='primary' size='large' style={{ marginTop: 16 }}>
                {loading ? (
                    <>
                        Please wait...
                        <CircularProgress size={20} style={{ marginLeft: 8 }} />
                    </>
                ) : (
                    'Save'
                )}
            </Button>
        </div>
    );
};

export default ClientSignature;
