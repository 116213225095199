import React, { useState, useEffect } from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, TextField, TablePagination, Card, CardHeader, CardContent, IconButton, Skeleton, Menu, MenuItem, Avatar, Tooltip } from '@mui/material';
import { Edit as EditIcon, Security as PermissionIcon } from '@mui/icons-material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import api from '../../../../axios';
import useStyles from "../../formStyle";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import SaveUsers from './SaveUsers';
import UserImageModel from './UserImageModel';
import UserBreadcrumbs from '../UserBreadcrumbs';

const ManageUsers = () => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [entity, setEntity] = useState({
        id: 0,
        userType: 1,
        firstName: '',
        lastName: '',
        saIdNo: '',
        physicalAddress: '',
        tel: '',
        mobile: '',
        email: '',
        password: '',
        roleId: 1,
        isActive: true,
        notes: '',
        img: ''
    });
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [imagePath, setImagePath] = useState('');

    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get('/api/Users/GetAllUsers', config);
            //console.log(response.data);
            setData(response.data || []); // Ensure data is not undefined or null
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setEntity({
            id: 0,
            userType: 1,
            firstName: '',
            lastName: '',
            saIdNo: '',
            physicalAddress: '',
            tel: '',
            mobile: '',
            email: '',
            password: '',
            roleId: '',
            isActive: false,
            notes: '',
            img: '',
        });
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = data.filter((row) =>
        Object.values(row).some((value) => (value !== null && value.toString().toLowerCase().includes(searchTerm.toLowerCase())))
    );

    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleToggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    useEffect(() => {
        if (!drawerOpen) {
            fetchData();
        }
    }, [drawerOpen]);

    const handleEdit = (editData) => {
        setEntity({
            id: editData.id,
            userType: editData.userType,
            firstName: editData.firstName,
            lastName: editData.lastName,
            saIdNo: editData.saIdNo,
            physicalAddress: editData.physicalAddress,
            tel: editData.tel,
            mobile: editData.mobile,
            email: editData.email,
            password: editData.password,
            roleId: editData.roleId,
            isActive: editData.isActive,
            notes: editData.notes,
            img: editData.img,
        });
        setDrawerOpen(true);
    }

    const openImageModal = (path) => {
        setImagePath(path);
        setModalIsOpen(true);
    };

    const closeImageModal = () => {
        setModalIsOpen(false);
    };

    const exportToExcel = () => {
        setAnchorEl(null);
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'users.xlsx');
    };

    return (
        <>
            <SaveUsers isOpen={drawerOpen} toggleDrawer={handleToggleDrawer} entity={entity} />
            <UserImageModel isOpen={modalIsOpen} closeImageModal={closeImageModal} imagePath={imagePath} />
            <UserBreadcrumbs />
            <Card>
                <CardHeader title="Manage Users" className={classes.header}
                    action={
                        <>
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-haspopup="true"
                                onClick={handleMenuClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                {/* Add your menu items here */}
                                <MenuItem onClick={() => { handleToggleDrawer(); handleMenuClose(); }}>Add New User</MenuItem>
                                <MenuItem onClick={exportToExcel}>Export to Excel</MenuItem>
                            </Menu>
                        </>
                    }
                >

                </CardHeader>
                <CardContent>
                    <TextField
                        label="Search"
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: '10px', float: 'right' }}
                    />
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.header}>
                                    <TableCell style={{ fontWeight: 'bold' }}>Image</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Telephone</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Mobile</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Role</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                                    <TableCell style={{ float: 'right', fontWeight: 'bold' }}>Action</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    // Placeholder Skeleton while loading
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            <Skeleton animation="wave" />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    // Render actual data
                                    paginatedData.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Avatar src={process.env.REACT_APP_BASE_URL + user.img} onClick={() => { openImageModal(user.img) }} variant="square" />
                                            </TableCell>
                                            <TableCell>{user.firstName} {user.lastName}</TableCell>
                                            <TableCell>{user.tel}</TableCell>
                                            <TableCell>{user.mobile}</TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>{user.Roles.roleName}</TableCell>
                                            <TableCell>{user.isActive ? 'Active' : 'Not Active'}</TableCell>
                                            <TableCell>
                                                <Tooltip title='Edit'>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => handleEdit(user)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>

                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card>
        </>
    )
};

export default ManageUsers;
