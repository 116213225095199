import React, { useState } from "react";
import { FormControl, FormHelperText, InputLabel, OutlinedInput, InputAdornment, IconButton, Button } from '@mui/material';
import { RotateLeft, Call, Email, Send } from '@mui/icons-material';
import useStyles from "./styles";
import { CircularProgress } from '@mui/material';

import { useFormik } from "formik";
import { ForgetPasswordSchema } from "./loginSchema";
import api from "../../axios";
import ReusableSnackbar from "../../Component/ReusableSnackbar";

const initForgetPasswordValues = {
    mobile: '',
    email: '',
    message: '',
}

export default function ForgetPassword() {
    const classes = useStyles();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: initForgetPasswordValues,
        validationSchema: ForgetPasswordSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setLoading(true);
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                    },
                };
                const response = await api.post(`api/Account/AddUserRequest`, values, config);
                if (response.status === 200) {
                    resetForm();
                    setSnackbarMessage(response.data);
                    setOpenSnackbar(true);
                }
            } catch (error) {
                setSnackbarMessage(error.response.data.message);
                setOpenSnackbar(true);
            } finally {
                setLoading(false);
                setSubmitting(false); 
            }
        },
    })

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <>
            <div>
                <ReusableSnackbar
                    open={openSnackbar}
                    onClose={handleCloseSnackbar}
                    message={snackbarMessage}
                    type="error"
                />
            </div>
            <form onSubmit={formik.handleSubmit}>

                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                    <InputLabel htmlFor="mobile">Mobile</InputLabel>
                    <OutlinedInput
                        id="mobile"
                        name="mobile"
                        type='tel'
                        margin="dense"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" edge="end" tabIndex={102}>
                                    <Call />
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Mobile"
                        value={formik.values.mobile}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                    />
                    {formik.touched.mobile && formik.errors.mobile && (
                        <FormHelperText error>{formik.errors.mobile}</FormHelperText>
                    )}
                </FormControl>

                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                    <InputLabel htmlFor="email">Email</InputLabel>
                    <OutlinedInput
                        id="email"
                        name="email"
                        type='email'
                        margin="dense"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" edge="end" tabIndex={101}>
                                    <Email />
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                    />
                    {formik.touched.email && formik.errors.email && (
                        <FormHelperText error>{formik.errors.email}</FormHelperText>
                    )}
                </FormControl>

                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                    <InputLabel htmlFor="message">Message</InputLabel>
                    <OutlinedInput
                        id="message"
                        name="message"
                        type='text'
                        margin="dense"
                        multiline
                        rows={3}
                        label="Message"
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.message && Boolean(formik.errors.message)}
                    />
                    {formik.touched.message && formik.errors.message && (
                        <FormHelperText error>{formik.errors.message}</FormHelperText>
                    )}
                </FormControl>

                <div className={classes.buttonCenter}>
                    {/* <Button type="submit" variant="contained" endIcon={<Send />} sx={{ mt: 2 }}>
                    SEND
                </Button> */}
                    <Button variant="contained" type="submit" disabled={loading} sx={{ mt: 2 }}>
                        {loading ? (
                            <>
                                Please wait...
                                <CircularProgress size={20} style={{ marginLeft: 8 }} />
                            </>
                        ) : (
                            'SEND'
                        )}
                    </Button>
                    <Button onClick={formik.resetForm} variant="outlined" endIcon={<RotateLeft />} sx={{ mt: 2, marginLeft: 2 }}>
                        RESET
                    </Button>
                </div>
                {/* <button onClick={handleButtonClick}>Go to Another Page</button> */}
            </form>
        </>

    )
}