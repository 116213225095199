import React, { useState, useEffect } from 'react';
import { Drawer, Divider, Box, TextField, Button, CircularProgress, Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../../../axios';

const validationSchema = Yup.object({
  name: Yup.string().required('Please enter a name'),
  notes: Yup.string(),
  //img: Yup.mixed(),
});

const SavePlanMarker = ({ isOpen, toggleDrawer, entity, LoadData, ShowTost }) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: entity.name,
      notes: entity.notes || '',
      img: entity.img || null,
      active: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const apiUrl = '/api/Settings/SavePlanMarkers';
        const user = JSON.parse(localStorage.getItem('user'));
        const token = user.token;
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        };

        const data = new FormData();
        data.append('id', values.id);
        data.append('name', values.name);
        data.append('notes', values.notes);
        data.append('active', values.active);
        //data.append('img', values.img);
        if (values.img) {
          data.append('img', values.img);
        }

        const response = await api.post(apiUrl, data, config);

        if (response.status === 200) {
          console.log('Plan markers created successfully.');
          formik.resetForm();
          ShowTost(response.data);
        } else {
          console.error('Something went wrong.');
          ShowTost('Something went wrong.');
        }
      } catch (error) {
        console.error('Error:', error.message);
        ShowTost('Error:', error.message);
      } finally {
        setLoading(false);
        toggleDrawer();
        LoadData();
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      id: entity.id || 0,
      name: entity.name || '',
      notes: entity.notes || '',
      img: null,
      active: entity.active,
    });
  }, [entity]);

  return (
    <div>
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        {/* Drawer content goes here */}
        <div style={{ width: '300px', paddingLeft: '10px', paddingRight: '10px' }}>
          <h2>Save Plan Marker</h2>
          <Divider style={{ marginBottom: '25px' }} />

          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth margin='normal'>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </FormControl>
            <FormControl fullWidth margin='normal'>
              <TextField
                minRows={3}
                maxRows={4}
                multiline
                placeholder="Notes"
                name="notes"
                value={formik.values.notes}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </FormControl>
            <FormControl fullWidth margin='normal'>
              <input
                type="file"
                accept="image/*"
                name="img"
                onChange={(event) => formik.setFieldValue('img', event.currentTarget.files[0])}
                onBlur={formik.handleBlur}
                style={{ display: 'none' }}
                id="img-upload"
              />
              <label htmlFor="img-upload">
                <Button variant="contained" component="span">
                  Upload Image
                </Button>
              </label>
            </FormControl>

            <FormControl fullWidth margin='normal'>
              <FormControlLabel
                control={
                  <Checkbox
                    name='active'
                    checked={formik.values.active}
                    onChange={(e) => formik.setFieldValue('active', e.target.checked)}
                  />
                }
                label='Active'
              />
            </FormControl>


            <Button type="submit" disabled={loading} fullWidth variant="contained" size="large" style={{ marginTop: '10px' }} sx={{ mt: 2 }}>
              {loading ? (
                <>
                  Please wait...
                  <CircularProgress size={20} style={{ marginLeft: 8 }} />
                </>
              ) : (
                'Save'
              )}
            </Button>
            {/* <Button type="submit" variant="contained" color="primary">
                Upload
              </Button> */}
            {/* </Box> */}
          </form>

        </div>
      </Drawer >
    </div >
  )
};

export default SavePlanMarker;
