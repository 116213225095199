import React, { useState, useEffect } from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, TextField, TablePagination, Card, CardHeader, CardContent, IconButton, Skeleton, Menu, MenuItem, Chip, Tooltip } from '@mui/material';
import { Edit as EditIcon, Security as PermissionIcon } from '@mui/icons-material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import api from '../../../axios';
import useStyles from '../formStyle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SaveClient from './SaveClient';
import QBOClient from './QBOClient';

import ReusableSnackbar from '../../../Component/ReusableSnackbar';
import ClientBreadcrumbs from './ClientBreadcrumbs';

const ManageClient = () => {
    const classes = useStyles();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [entity, setEntity] = useState({
        clientId: 0,
        createdDate: '',
        modifiedDate: '',
        createdId: 0,
        Id: '',
        Title: '',
        GivenName: '',
        MiddleName: '',
        FamilyName: '',
        Suffix: '',
        FullyQualifiedName: '',
        CompanyName: '',
        DisplayName: '',
        PrintOnCheckName: '',
        Active: true,
        PrimaryPhone: '',
        Mobile: '',
        PrimaryEmailAddr: '',
        PreferredDeliveryMethod: '',
        BillAddrLine1: '',
        BillAddrLine2: '',
        BillAddrCity: '',
        BillAddrCountry: '',
        BillAddrCountrySubDivisionCode: '',
        BillAddrPostalCode: '',
        ShipAddrLine1: '',
        ShipAddrLine2: '',
        ShipAddrCity: '',
        ShipAddrCountry: '',
        ShipAddrCountrySubDivisionCode: '',
        ShipAddrPostalCode: '',
        Notes: '',
        PrimaryTaxIdentifier: '',
    });


    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get('/api/Clients/ClientGetAll', config);
            setData(response.data || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleSave = () => {
        setEntity({
            clientId: 0,
            createdDate: '',
            modifiedDate: '',
            createdId: 0,
            Id: '',
            Title: '',
            GivenName: '',
            MiddleName: '',
            FamilyName: '',
            Suffix: '',
            FullyQualifiedName: '',
            CompanyName: '',
            DisplayName: '',
            PrintOnCheckName: '',
            Active: true,
            PrimaryPhone: '',
            Mobile: '',
            PrimaryEmailAddr: '',
            PreferredDeliveryMethod: '',
            BillAddrLine1: '',
            BillAddrLine2: '',
            BillAddrCity: '',
            BillAddrCountry: '',
            BillAddrCountrySubDivisionCode: '',
            BillAddrPostalCode: '',
            ShipAddrLine1: '',
            ShipAddrLine2: '',
            ShipAddrCity: '',
            ShipAddrCountry: '',
            ShipAddrCountrySubDivisionCode: '',
            ShipAddrPostalCode: '',
            Notes: '',
            PrimaryTaxIdentifier: '',
        });
        setDrawerOpen(!drawerOpen);
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = data.filter((row) =>
        Object.values(row).some((value) => (value !== null && value.toString().toLowerCase().includes(searchTerm.toLowerCase())))
    );


    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleToggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    useEffect(() => {
        if (!drawerOpen) {
            fetchData();
        }
    }, [drawerOpen]);

    const handleEdit = (editData) => {
        setEntity({
            clientId: editData.clientId,
            createdDate: editData.createdDate,
            modifiedDate: editData.modifiedDate,
            createdId: editData.createdId,
            Id: editData.Id,
            Title: editData.Title,
            GivenName: editData.GivenName,
            MiddleName: editData.MiddleName,
            FamilyName: editData.FamilyName,
            Suffix: editData.Suffix,
            FullyQualifiedName: editData.FullyQualifiedName,
            CompanyName: editData.CompanyName,
            DisplayName: editData.DisplayName,
            PrintOnCheckName: editData.PrintOnCheckName,
            Active: editData.Active,
            PrimaryPhone: editData.PrimaryPhone,
            Mobile: editData.Mobile,
            Fax: editData.Fax,
            WebAddr: editData.WebAddr,
            PrimaryEmailAddr: editData.PrimaryEmailAddr,
            PreferredDeliveryMethod: editData.PreferredDeliveryMethod,
            BillAddrLine1: editData.BillAddrLine1,
            BillAddrLine2: editData.BillAddrLine2,
            BillAddrCity: editData.BillAddrCity,
            BillAddrCountry: editData.BillAddrCountry,
            BillAddrCountrySubDivisionCode: editData.BillAddrCountrySubDivisionCode,
            BillAddrPostalCode: editData.BillAddrPostalCode,
            ShipAddrLine1: editData.ShipAddrLine1,
            ShipAddrLine2: editData.ShipAddrLine2,
            ShipAddrCity: editData.ShipAddrCity,
            ShipAddrCountry: editData.ShipAddrCountry,
            ShipAddrCountrySubDivisionCode: editData.ShipAddrCountrySubDivisionCode,
            ShipAddrPostalCode: editData.ShipAddrPostalCode,
            Notes: editData.Notes,
            PrimaryTaxIdentifier: editData.PrimaryTaxIdentifier,
        });
        setDrawerOpen(true);
    }

    const handleAddlogin = async (editData) => {
        try {
            var answer = window.confirm("Are you sure you want to allow login?");
            if (answer) {
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const response = await api.get(`/api/Clients/AddClientLogin?clientId=${editData.clientId}`, config);
                handleTost(response.data || 'Unkown');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [qboAction, setQBOAction] = useState('');
    const openQBOModal = (qbo) => {
        setQBOAction(qbo);
        setModalIsOpen(true);
    };

    const closeQBOModal = () => {
        setModalIsOpen(false);
    };

    const handleLoadData = () => {
        fetchData();
    };

    const handleTost = (msg) => {
        setSnackbarMessage(msg);
        setOpenSnackbar(true);
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const exportToExcel = () => {
        setAnchorEl(null);
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Clients');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'clients.xlsx');
    };

    return (
        <>
            <ReusableSnackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
            <SaveClient isOpen={drawerOpen} toggleDrawer={handleToggleDrawer} entity={entity} ShowTost={handleTost} />
            <QBOClient isOpen={modalIsOpen} closeQBOModal={closeQBOModal} qboAction={qboAction} LoadData={handleLoadData} ShowTost={handleTost} />
            <ClientBreadcrumbs />
            <Card>
                <CardHeader title='Manage Client' className={classes.header}
                    action={
                        <>
                            <IconButton
                                aria-label='more'
                                id='long-button'
                                aria-haspopup='true'
                                onClick={handleMenuClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={handleSave}>Add New client</MenuItem>
                                <MenuItem onClick={() => openQBOModal('Sync To QuickBooks')} >Sync To QuickBooks</MenuItem>
                                <MenuItem onClick={() => openQBOModal('Sync From QuickBooks')}>Sync From QuickBooks</MenuItem>
                                <MenuItem onClick={exportToExcel}>Export to Excel</MenuItem>
                            </Menu>
                        </>
                    }
                >
                </CardHeader>

                <CardContent>
                    <TextField
                        label='Search'
                        variant='outlined'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: '10px', float: 'right' }}
                    />
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.header}>
                                    <TableCell style={{ fontWeight: 'bold' }}>Id</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Company Name</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Phone</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Mobile</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                                    <TableCell style={{ float: 'right', fontWeight: 'bold' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            <Skeleton animation='wave' />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    paginatedData.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{user.clientId}</TableCell>
                                            <TableCell>{user.Title} {user.GivenName} {user.MiddleName} {user.FamilyName}</TableCell>
                                            <TableCell>{user.CompanyName}</TableCell>
                                            <TableCell>{user.PrimaryPhone}</TableCell>
                                            <TableCell>{user.Mobile}</TableCell>
                                            <TableCell>{user.PrimaryEmailAddr}</TableCell>
                                            <TableCell>
                                                <Chip
                                                    label={user.Active ? 'Active' : 'Not Active'}
                                                    color={user.Active ? 'primary' : 'secondary'}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title='Edit Client'>
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => handleEdit(user)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                {user.userId == 0 && (
                                                    <Tooltip title='Add Login'>
                                                        <IconButton
                                                            color='primary'
                                                            onClick={() => handleAddlogin(user)}
                                                            style={{ float: 'right' }}
                                                        >
                                                            <PermissionIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                {/* <Tooltip title='Add Login'>
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => handleAddlogin(user)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <PermissionIcon />
                                                    </IconButton>
                                                </Tooltip> */}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card>
        </>
    )
};

export default ManageClient;
