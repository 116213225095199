import React, { useState, useEffect } from 'react';
import {
    Drawer, Card, CardHeader, CardContent, CardActions, IconButton,
    TextField, Button, CircularProgress, FormControl, InputLabel,
    Select, MenuItem, Grid, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper,
    Autocomplete,
    Avatar
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useStyles from '../../formStyle';
import api from '../../../../axios';

const SavePurchase = ({ isOpen, toggleDrawer, entity, ShowTost }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [items, setItems] = useState([]);

    const [cartList, setCartList] = useState([]);

    const [carItem, setCartItem] = useState({
        itemId: '',
        unitPrice: '',
        qty: '',
        purchaseTaxRate: '',
        vatAmount: '',
    });

    const formik = useFormik({
        initialValues: {
            id: entity.id || '',
            createdDate: entity.createdDate || '',
            modifiedDate: entity.modifiedDate || '',
            createdBy: entity.createdBy || '',
            supplierId: entity.supplierId || 0,
            invNo: entity.invNo || '',
            poNo: entity.poNo || '',
            totalAmount: entity.totalAmount || '',
            totalVatAmount: entity.totalVatAmount || '',
            notes: entity.notes || '',
            totalDiscount: entity.totalDiscount || '',
        },
        validationSchema: Yup.object({
            supplierId: Yup.string().required('Please select Supplier'),
            invNo: Yup.string().required('Please enter Invoice No'),
            totalAmount: Yup.string().required('Please enter Total Amount'),
            totalVatAmount: Yup.string().required('Please enter Total Vat Amount'),
            //totalDiscount: Yup.string().required('Please enter Total Discount'),
        }),
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const apiUrl = '/api/Transaction/SavePurchase';
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                };

                const payload = {
                    Purchase: values,
                    PurchaseItems: cartList,
                };

                const response = await api.post(apiUrl, payload, config);
                if (response.status === 200) {
                    console.log('created successfully.');
                    ShowTost(response.data);
                    formik.resetForm();
                } else {
                    ShowTost('Something went wrong.');
                    console.error('Something went wrong.');
                }
            } catch (error) {
                ShowTost(`Error: ${error.message}`);
                console.error('Error:', error.message);
            } finally {
                setLoading(false);
                toggleDrawer();
            }
        },
    });

    useEffect(() => {
        formik.setValues({
            id: entity.id || '',
            createdDate: entity.createdDate || '',
            modifiedDate: entity.modifiedDate || '',
            createdBy: entity.createdBy || '',
            supplierId: entity.supplierId || 0,
            invNo: entity.invNo || '',
            poNo: entity.poNo || '',
            totalAmount: entity.totalAmount || '',
            totalVatAmount: entity.totalVatAmount || '',
            notes: entity.notes || '',
            totalDiscount: entity.totalDiscount || '',
        });

        loadData();
    }, [entity]);

    const loadData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get('/api/Transaction/GetAllSupplierAndItems', config);
            setItemList(response.data.items || []);
            setSupplierList(response.data.suppliers || []);
            setCartList([]);
            if(entity.id>0){
                const response = await api.get('/api/Transaction/GetAllPurchaseItem?purchaseId='+entity.id, config);
                console.log(response);
                setCartList(response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCartItem({ ...carItem, [name]: value });
    };

    const handleItemChange = (event, value) => {
        if (value) {
            setCartItem({
                ...carItem,
                itemId: value.itemId,
                purchaseTaxRate: value.purchaseTaxRate,
            });
        } else {
            setCartItem({ ...carItem, itemId: '', purchaseTaxRate: '' });
        }
    };

    const handleButtonClick = () => {
        const selectedItem = itemList.find(item => item.itemId === carItem.itemId);
        if (!selectedItem) {
            // Item not found in itemList
            return;
        }

        const existingItem = cartList.find(item => item.itemId === carItem.itemId);
        const purchaseTaxRate = selectedItem?.purchaseTaxRate || 0;

        if (existingItem) {
            // Item already exists in cart, update quantity
            const updatedCartList = cartList.map(item => {
                if (item.itemId === carItem.itemId) {
                    return {
                        ...item,
                        qty: parseFloat(item.qty) + parseFloat(carItem.qty),
                    };
                }
                return item;
            });
            setCartList(updatedCartList);
        } else {
            // Item does not exist in cart, add new item
            const newItem = {
                itemId: carItem.itemId,
                unitPrice: parseFloat(carItem.unitPrice),
                qty: parseFloat(carItem.qty),
                purchaseTaxRate: purchaseTaxRate,
                vatAmount: parseFloat((carItem.unitPrice * carItem.qty * purchaseTaxRate) / 100).toFixed(2),
            };
            setCartList([...cartList, newItem]);
        }

        // Clear carItem state
        setCartItem({
            itemId: '',
            unitPrice: '',
            qty: '',
        });
    };

    const handleDeleteItem = (index) => {
        const newCartList = [...cartList];
        newCartList.splice(index, 1);
        setCartList(newCartList);
    };

    // Calculate total amount and total VAT amount
    useEffect(() => {
        let totalAmount = 0;
        let totalVatAmount = 0;

        cartList.forEach(item => {
            totalAmount += item.unitPrice * item.qty;
            totalVatAmount += parseFloat(item.vatAmount);
        });

        formik.setFieldValue('totalAmount', totalAmount.toFixed(2));
        formik.setFieldValue('totalVatAmount', totalVatAmount.toFixed(2));
    }, [cartList]);

    return (
        <Drawer
            anchor='right'
            open={isOpen}
            onClose={toggleDrawer}
            variant='temporary'
            ModalProps={{
                keepMounted: true,
            }}
            PaperProps={{
                sx: {
                    width: '100%',
                    // maxWidth: '550px', // Adjust width as needed
                },
            }}
        >
            <div style={{ height: '100vh', overflow: 'auto' }}>
                <Card>
                    <CardHeader
                        title='Save Receive Goods'
                        className={classes.header}
                        action={
                            <IconButton
                                edge='end'
                                color='inherit'
                                aria-label='close'
                                onClick={toggleDrawer}
                                style={{ marginRight: 2 }}
                            >
                                <CloseIcon />
                            </IconButton>
                        }
                    />
                    <CardContent>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <FormControl fullWidth margin='normal'>
                                        <Autocomplete
                                            id="supplierId"
                                            options={supplierList}
                                            getOptionLabel={(option) => `${option.Title} ${option.GivenName} ${option.MiddleName} ${option.FamilyName} - ${option.CompanyName}`}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Supplier"
                                                    variant="outlined"
                                                    name="supplierId"
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.supplierId && Boolean(formik.errors.supplierId)}
                                                    helperText={formik.touched.supplierId && formik.errors.supplierId}
                                                />
                                            )}
                                            onChange={(event, value) => formik.setFieldValue('supplierId', value ? value.supplierId : '')}
                                            value={supplierList.find(supplier => supplier.supplierId === formik.values.supplierId) || null}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControl fullWidth margin='normal'>
                                        <TextField
                                            name='invNo'
                                            label='Invoice No'
                                            value={formik.values.invNo}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.invNo && Boolean(formik.errors.invNo)}
                                            helperText={formik.touched.invNo && formik.errors.invNo}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControl fullWidth margin='normal'>
                                        <TextField
                                            name='poNo'
                                            label='Purchase Order No'
                                            value={formik.values.poNo}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} style={{ marginTop: 20 }}>
                                <Grid item xs={12} sm={3}>
                                    <Autocomplete
                                        id="item"
                                        options={itemList}
                                        getOptionLabel={(option) => option.Name}
                                        renderOption={(props, option) => (
                                            <li {...props}>
                                                <Avatar alt={option.Name} src={process.env.REACT_APP_BASE_URL + option.itemImg} style={{ marginRight: 10 }} />
                                                {option.Name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Item"
                                                variant="outlined"
                                                name="item"
                                            />
                                        )}
                                        onChange={handleItemChange}
                                        value={itemList.find(item => item.itemId === carItem.itemId) || null}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        name="unitPrice"
                                        label="Price"
                                        type="number"
                                        value={carItem.unitPrice}
                                        onChange={handleChange}
                                        fullWidth
                                        autoComplete="off"
                                        inputProps={{
                                            step: "0.01", // Allows two decimal places
                                            min: "0",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        name="qty"
                                        label="Quantity"
                                        type="number"
                                        value={carItem.qty}
                                        onChange={handleChange}
                                        fullWidth
                                        autoComplete="off"
                                        inputProps={{
                                            step: "0.01", // Allows two decimal places
                                            min: "0",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleButtonClick}
                                        fullWidth
                                        style={{ height: 50 }}
                                    >
                                        Add Item
                                    </Button>
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} style={{ marginTop: 20 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>Item</strong></TableCell>
                                            <TableCell><strong>Price</strong></TableCell>
                                            <TableCell><strong>Quantity</strong></TableCell>
                                            <TableCell><strong>VAT Amount</strong></TableCell>
                                            <TableCell style={{textAlign:'right'}}><strong>Actions</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {cartList.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{itemList.find(item => item.itemId === row.itemId)?.Name}</TableCell>
                                                <TableCell>{row.unitPrice}</TableCell>
                                                <TableCell>{row.qty}</TableCell>
                                                <TableCell>{row.vatAmount}</TableCell>
                                                <TableCell style={{textAlign:'right'}}>
                                                    <IconButton
                                                        edge='end'
                                                        color='inherit'
                                                        aria-label='delete'
                                                        onClick={() => handleDeleteItem(index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid container spacing={2} style={{ marginTop: 20 }}>
                                <Grid item xs={8}>
                                    <FormControl fullWidth margin='normal'>
                                        <TextField
                                            name='notes'
                                            label='Notes'
                                            minRows={3}
                                            maxRows={4}
                                            multiline
                                            value={formik.values.notes}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth margin='normal'>
                                        <TextField
                                            name='totalAmount'
                                            label='Total Amount'
                                            value={formik.values.totalAmount}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.totalAmount && Boolean(formik.errors.totalAmount)}
                                            helperText={formik.touched.totalAmount && formik.errors.totalAmount}
                                            disabled 
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin='normal'>
                                        <TextField
                                            name='totalVatAmount'
                                            label='Total VAT Amount'
                                            readOnly
                                            value={formik.values.totalVatAmount}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.totalVatAmount && Boolean(formik.errors.totalVatAmount)}
                                            helperText={formik.touched.totalVatAmount && formik.errors.totalVatAmount}
                                            disabled 
                                     />
                                    </FormControl>
                                    <FormControl fullWidth margin='normal'>
                                        <TextField
                                            name='totalDiscount'
                                            label='Total Discount'
                                            type="number"
                                            value={formik.values.totalDiscount}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <CardActions className={classes.footer}>
                                <Button
                                    type='submit'
                                    disabled={loading}
                                    variant='contained'
                                    color='primary'
                                    size='large'
                                    className={classes.largeButton}
                                >
                                    {loading ? (
                                        <>
                                            Please wait...
                                            <CircularProgress size={20} style={{ marginLeft: 8 }} />
                                        </>
                                    ) : (
                                        'Save'
                                    )}
                                </Button>
                            </CardActions>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </Drawer>
    );
};

export default SavePurchase;
