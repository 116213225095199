import './App.css';

import { BrowserRouter, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import LoginPage from './pages/login/loginpage';
import Logout from './Component/Logout';
import ChangePassword from './pages/login/changepassword';
import Profile from './pages/login/profile';
import NotFound from './pages/login/NotFound';

//-----Admin-------
//import AdminDashboard from "./areas/admin/dashboard"; 
import ProtectedAdminRoute from './Component/ProtectedAdminRoute';
import AdminLayout from './layout/admin/admin';
import AdminDashboard from './pages/admin/dashboard/dashboard';
import Company from './pages/admin/settings/company/company';
import ActivityLog from './pages/admin/settings/activitylog/ActivityLog';
import ErrorLog from './pages/admin/settings/errorlog/ErrorLog';
import WhosOnline from './pages/admin/settings/whosonline/WhosOnline';
import PlanMarkers from './pages/admin/settings/planmarkers/PlanMarkers';
import QuickBooks from './pages/admin/settings/quickbooks/QuickBooks';
import PushNotification from './pages/admin/settings/notification/PushNotification';

import UserRequest from './pages/admin/users/userrequest/UserRequest';
import Roles from './pages/admin/users/roles/Roles';
import ManageUsers from './pages/admin/users/manageusers/ManageUsers';

import ManageClient from './pages/admin/client/ManageClient';

import ManageSupplier from './pages/admin/supplier/ManageSupplier';
import ManageSupplierItem from './pages/admin/supplier/ManageSupplierItem';

import ItemsServices from './pages/admin/inventory/ItemsServices';
import Accounts from './pages/admin/inventory/Accounts';
import Taxes from './pages/admin/inventory/Taxes';
import Category from './pages/admin/inventory/Category';

import ProtectedTechnicianRoute from './Component/ProtectedTechnicianRoute';
import TechnicianLayout from './layout/technician/technician';
import TechnicianDashboard from './pages/technician/dashboard';

import ProtectedClientRoute from './Component/ProtectedClientRoute';
import ClientLayout from './layout/client/client';
import ClientDashboard from './pages/client/dashboard';

import Groups from './pages/admin/masters/Groups';

import SiteSurvey from './pages/admin/survey/SiteSurvey';
import SiteSurveyDetails from './pages/admin/survey/SiteSurveyDetails';
import SiteSurveyCompleted from './pages/admin/survey/SiteSurveyCompleted';

import Jobs from './pages/admin/job/Jobs';
import JobsDetails from './pages/admin/job/JobsDetails';
import JobCompleted from './pages/admin/job/JobCompleted';

//---Transaction-------
import ManagePurchase from './pages/admin/transaction/purchase/ManagePurchase';
import ManagePurchaseReturn from './pages/admin/transaction/purchasereturn/ManagePurchaseReturn';
import ManageSale from './pages/admin/transaction/sale/ManageSale';
import ManageSaleReturn from './pages/admin/transaction/salereturn/ManageSaleReturn';
import ManageScrap from './pages/admin/transaction/scrap/ManageScrap';
import ManageQuotation from './pages/admin/transaction/quotation/ManageQuotation';
import Manageinvoice from './pages/admin/transaction/invoice/Manageinvoice';

//------Reports--------
import Reports from './pages/admin/report/Reports';


//--------Technician Portal----------

import SiteSurveyTech from './pages/technician/Survey/SiteSurvey';
import SiteSurveyTechCompleted from './pages/technician/Survey/SiteSurveyCompleted';
import SiteSurveyTechDetails from './pages/technician/Survey/SiteSurveyDetails';

import JobsTech from './pages/technician/job/Jobs';
import JobTechCompleted from './pages/technician/job/JobCompleted';
import JobsTechDetails from './pages/technician/job/JobsDetails';

//---------Client Portal-------------

import SiteSurveyClient from './pages/client/survey/SiteSurvey';
import SiteSurveyClientCompleted from './pages/client/survey/SiteSurveyCompleted';
import JobsClient from './pages/client/job/Jobs';
import JobClientCompleted from './pages/client/job/JobCompleted';
import ChangePasswordClient from './pages/client/changepassword';
import QuotationClient from './pages/client/quotation';
import InvoiceClient from './pages/client/invoice';
import Communication from './pages/client/communication';
import ServiceRequest from './pages/admin/client/ServiceRequest';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="/logout" element={<Logout />} />
        {/* <Route path='/profile' element={<Profile />} /> */}
        <Route path='*' element={<NotFound />} />

        <Route
          path="/admin"
          element={
            <ProtectedAdminRoute
              element={
                <AdminLayout>
                  <Outlet />
                </AdminLayout>
              }
            />
          }
        >
          <Route path='*' element={<NotFound />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/admin/profile" element={<Profile />} />
          <Route path="/admin/company" element={<Company />} />
          <Route path="/admin/activitylog" element={<ActivityLog />} />
          <Route path='/admin/errorlog' element={<ErrorLog />} />
          <Route path='/admin/whosonline' element={<WhosOnline />} />
          <Route path='/admin/changepassword' element={<ChangePassword />} />
          <Route path='/admin/quickbooks' element={<QuickBooks />} />
          <Route path='/admin/planmarkers' element={<PlanMarkers />} />
          <Route path='/admin/pushnotification' element={<PushNotification />} />
          
          <Route path='/admin/userrequest' element={<UserRequest />} />
          <Route path='/admin/roles' element={<Roles />} />
          <Route path='/admin/manageusers' element={<ManageUsers />} />

          <Route path='/admin/manageclient' element={<ManageClient />} />

          <Route path='/admin/managesupplier' element={<ManageSupplier />} />
          <Route path='/admin/supplieritems' element={<ManageSupplierItem />} />
          

          <Route path='/admin/itemsservices' element={<ItemsServices />} />
          <Route path='/admin/accounts' element={<Accounts />} />
          <Route path='/admin/tax' element={<Taxes />} />
          <Route path='/admin/category' element={<Category />} />

          <Route path='/admin/groups' element={<Groups />} />

          <Route path='/admin/sitesurvey' element={<SiteSurvey />} />
          <Route path='/admin/sitesurveycompleted' element={<SiteSurveyCompleted />} />
          <Route path='/admin/sitesurveydetails/:id' element={<SiteSurveyDetails />} />

          <Route path='/admin/jobs' element={<Jobs />} />
          <Route path='/admin/jobscompleted' element={<JobCompleted />} />
          <Route path='/admin/jobdetails/:id' element={<JobsDetails />} />

          <Route path='/admin/purchase' element={<ManagePurchase />} />
          <Route path='/admin/purchasereturn' element={<ManagePurchaseReturn />} />
          <Route path='/admin/sales' element={<ManageSale />} />
          <Route path='/admin/salesreturn' element={<ManageSaleReturn />} />
          <Route path='/admin/stockscrap' element={<ManageScrap />} />
          <Route path='/admin/quotation' element={<ManageQuotation />} />
          <Route path='/admin/invoice' element={<Manageinvoice />} />

          <Route path='/admin/report' element={<Reports />} />
          <Route path='/admin/servicerequest' element={<ServiceRequest />} />
          {/* Add more admin-specific routes if needed */}
        </Route>


        <Route
          path="/technician"
          element={
            <ProtectedTechnicianRoute
              element={
                <TechnicianLayout>
                  <Outlet />
                </TechnicianLayout>
              }
            />
          }
        >
          <Route path="/technician" element={<TechnicianDashboard />} />
          <Route path="/technician/survey" element={<SiteSurveyTech />} />
          <Route path="/technician/surveycompleted" element={<SiteSurveyTechCompleted />} />
          <Route path='/technician/sitesurveydetails/:id' element={<SiteSurveyTechDetails />} />
          <Route path='/technician/changepassword' element={<ChangePassword />} />
          <Route path="/technician/profile" element={<Profile />} />
          
          <Route path="/technician/jobs" element={<JobsTech />} />
          <Route path="/technician/jobscompleted" element={<JobTechCompleted />} />
          <Route path='/technician/jobdetails/:id' element={<JobsTechDetails />} />
          
          
          {/* Add more admin-specific routes if needed */}
        </Route>


        <Route
          path="/client"
          element={
            <ProtectedClientRoute
              element={
                <ClientLayout>
                  <Outlet />
                </ClientLayout>
              }
            />
          }
        >
          <Route path="/client" element={<ClientDashboard />} />
          <Route path='/client/survey' element={<SiteSurveyClient />} />
          <Route path='/client/surveycompleted' element={<SiteSurveyClientCompleted />} />
          <Route path='/client/jobs' element={<JobsClient />} />
          <Route path='/client/jobscompleted' element={<JobClientCompleted />} />
          <Route path='/client/changepassword' element={<ChangePasswordClient />} />
          <Route path='/client/quotation' element={<QuotationClient />} />
          <Route path='/client/invoice' element={<InvoiceClient />} />
          <Route path='/client/communication' element={<Communication />} />
          {/* Add more admin-specific routes if needed */}
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;
