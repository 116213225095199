import React, { useState, useEffect } from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, TextField, TablePagination, Card, CardHeader, CardContent, IconButton, Skeleton, Menu, MenuItem, Avatar, Chip, Tooltip } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import api from '../../../../axios';
import useStyles from "../../formStyle";
import ReusableSnackbar from '../../../../Component/ReusableSnackbar';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import SavePlanMarker from './SavePlanMarker';
import PlanMarkerImageModel from './PlanMarkerImageModel';
import SettingBreadcrumbs from '../SettingBreadcrumbs';

const PlanMarkers = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [entity, setEntity] = useState({
        id: 0,
        name: "",
        notes: "",
        img: "",
        active: false,
    });
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [imagePath, setImagePath] = useState('');

    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get('/api/Settings/GetAllPlanMarkers', config);
            //console.log(response.data);
            setData(response.data || []); // Ensure data is not undefined or null
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setEntity({
            id: 0,
            name: '',
            notes: '',
            img: null,
            active: false,
        });
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = data.filter((row) =>
        Object.values(row).some((value) => (value !== null && value.toString().toLowerCase().includes(searchTerm.toLowerCase())))
    );

    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleToggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleEdit = (editData) => {
        //alert(editData.name);
        setEntity({
            id: editData.id,
            name: editData.name,
            notes: editData.notes,
            img: editData.img,
            active: editData.active,
        });
        setDrawerOpen(true);
    }

    const openImageModal = (path) => {
        setImagePath(path);
        setModalIsOpen(true);
    };

    const closeImageModal = () => {
        setModalIsOpen(false);
    };

    const handleLoadData = () => {
        fetchData();
    };

    const handleTost = (msg) => {
        setSnackbarMessage(msg);
        setOpenSnackbar(true);
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <>
            <ReusableSnackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
            <SavePlanMarker isOpen={drawerOpen} toggleDrawer={handleToggleDrawer} entity={entity} LoadData={handleLoadData} ShowTost={handleTost} />
            <PlanMarkerImageModel isOpen={modalIsOpen} closeImageModal={closeImageModal} imagePath={imagePath} />
            <SettingBreadcrumbs />
            <Card>
                <CardHeader title="Plan Markers" className={classes.header}
                    action={
                        <>
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-haspopup="true"
                                onClick={handleMenuClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                {/* Add your menu items here */}
                                <MenuItem onClick={() => { handleToggleDrawer(); handleMenuClose(); }}>Add Plan Marker</MenuItem>
                            </Menu>
                        </>
                    }
                >

                </CardHeader>
                <CardContent>
                    <TextField
                        label="Search"
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: '10px', float: 'right' }}
                    />
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.header}>
                                    <TableCell style={{ fontWeight: 'bold' }}>Image</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Notes</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                                    <TableCell style={{ float: 'right', fontWeight: 'bold' }}>Action</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    // Placeholder Skeleton while loading
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <Skeleton animation="wave" />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    // Render actual data
                                    paginatedData.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Avatar src={process.env.REACT_APP_BASE_URL + user.img} onClick={() => { openImageModal(user.img) }} variant="square" />
                                                {/* <img src={process.env.REACT_APP_BASE_URL + user.img} onClick={() => { openImageModal(user.img) }} alt="Image" style={{ width: '50px', height: '50px' }} /> */}
                                            </TableCell>
                                            <TableCell>{user.name}</TableCell>
                                            <TableCell>{user.notes}</TableCell>
                                            <TableCell>
                                                <Chip
                                                    label={user.active ? 'Active' : 'Not Active'}
                                                    color={user.active ? 'primary' : 'secondary'}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title='Edit'>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => handleEdit(user)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card>
        </>

    )
};

export default PlanMarkers;
