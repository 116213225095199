import React from "react";
import useStyles from "../admin/formStyle";
import { Typography, Grid, Card, CardHeader, CardContent,CardActions, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";

export default function ClientDashboard() {
    const classes = useStyles();
    const navigate = useNavigate();
    return (
        <Card>
            <CardHeader title='Dashboard' className={classes.header} />
            <CardContent>
                <Grid container spacing={2}>
                    {/* Tile 1 */}
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent style={{background:'#3E4095',color:'white'}}>
                                <Typography variant="h5" component="div">
                                    Site Surveys
                                </Typography>
                                <Typography variant="body2">
                                    View all site surveys.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button onClick={()=>navigate(`/client/survey`)} size="small">Explore</Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    {/* Tile 2 */}
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent style={{background:'#3E4095',color:'white'}}>
                                <Typography variant="h5" component="div">
                                    Job Cards
                                </Typography>
                                <Typography variant="body2">
                                    View all Job Cards.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button onClick={()=>navigate(`/client/jobs`)} size="small">Explore</Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    {/* Tile 3 */}
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent style={{background:'#3E4095',color:'white'}}>
                                <Typography variant="h5" component="div">
                                    Communication
                                </Typography>
                                <Typography variant="body2">
                                    Send service request.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button onClick={()=>navigate(`/client/communication`)} size="small">Explore</Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    {/* Tile 4 */}
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent style={{background:'#3E4095',color:'white'}}>
                                <Typography variant="h5" component="div">
                                    Quotations
                                </Typography>
                                <Typography variant="body2">
                                    View all Quotations.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button onClick={()=>navigate(`/client/quotation`)} size="small">Explore</Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    {/* Tile 5 */}
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent style={{background:'#3E4095',color:'white'}}>
                                <Typography variant="h5" component="div">
                                    Invoices
                                </Typography>
                                <Typography variant="body2">
                                    View all Invoices.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button onClick={()=>navigate(`/client/invoice`)} size="small">Explore</Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    {/* Tile 6 */}
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent style={{background:'#3E4095',color:'white'}}>
                                <Typography variant="h5" component="div">
                                    Change Password
                                </Typography>
                                <Typography variant="body2">
                                    Change your password.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button onClick={()=>navigate(`/client/changepassword`)} size="small">Explore</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
