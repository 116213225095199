import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

const ImageModel = ({ isOpen, closeImageModal, imagePath }) => {
    return (
        <Dialog open={isOpen} onClose={closeImageModal}>
            <DialogTitle>Image Preview</DialogTitle>
            <DialogContent>
                <img src={process.env.REACT_APP_BASE_URL + imagePath} alt="Preview" style={{ width: '100%', height: 'auto' }} />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeImageModal}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImageModel;
