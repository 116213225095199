import React, { useState, useEffect } from 'react';
import { Grid, FormControl, TextField, Button, CircularProgress, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, IconButton, Skeleton, Tooltip } from '@mui/material';
import { Download as DownloadIcon, Close as DeleteIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../../axios';
import { format } from 'date-fns';

const formatDate = (dateString) => {
    return format(new Date(dateString), 'dd-MMM-yyyy');
};

const JobsSurvey = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [entity, setEntity] = useState({
        id: 0,
        createdDate: '',
        jobId: 0,
        surveyId: 0,
        surveyRefNo: '',
    });

    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get(`/api/Technician/GetAllJobSurvey?jobId=${props.id}`, config);
            setData(response.data || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const formik = useFormik({
        initialValues: {
            id: entity.id || '',
            jobId: props.id,
            surveyRefNo: entity.surveyRefNo || '',
        },
        validationSchema: Yup.object({
            surveyRefNo: Yup.string().required('Please enter Survey Ref Number.'),
        }),
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const apiUrl = '/api/Technician/AddJobSurvey';
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const data = new FormData();
                data.append('id', values.id);
                data.append('jobId', values.jobId);
                data.append('surveyRefNo', values.surveyRefNo);
                const response = await api.post(apiUrl, data, config);
                if (response.status === 200) {
                    fetchData();
                    alert(response.data.message);
                    //console.log(response.data);
                    formik.resetForm();
                } else {
                    console.error('Something went wrong.');
                }
            } catch (error) {
                console.error('Error:', error.message);
            } finally {
                setLoading(false);
            }
        },
    });

    const handleDelete = async (id) => {
        const confirmation = window.confirm('Are you sure you want to delete?');
        if (confirmation) {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const response = await api.post(`/api/Technician/DeleteJobSurvey?id=${id}`, null, config);
                if (response.status === 200) {
                    fetchData();
                }
            } catch (error) {
                console.error('Error deleting data:', error);
            }
        }
    };

    const handleReport = async (surveyId) => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                responseType: 'blob', // Important
            };
            const response = await api.get('/api/Technician/GetCanvasData?surveyId=' + surveyId, config);
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);

            // Open the URL in a new tab
            window.open(url, '_blank');
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            {props.pending ? (

                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={1} >
                        <Grid item xs={12} sm={5}>
                            <FormControl fullWidth margin='normal'>
                                <TextField
                                    name='surveyRefNo'
                                    label='Survey Reference No'
                                    value={formik.values.surveyRefNo}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.surveyRefNo && Boolean(formik.errors.surveyRefNo)}
                                    helperText={formik.touched.surveyRefNo && formik.errors.surveyRefNo}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button
                                type='submit'
                                disabled={loading}
                                variant='contained'
                                color='primary'
                                size='large'

                                style={{ marginTop: 20 }}
                            >
                                {loading ? (
                                    <>
                                        Please wait...
                                        <CircularProgress size={20} style={{ marginLeft: 8 }} />
                                    </>
                                ) : (
                                    'Add Survey'
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </form>

            ) : (
                <span></span>
            )}

            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Created Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Created By</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Survey Ref No</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Skeleton animation='wave' />
                                </TableCell>
                            </TableRow>
                        ) : (
                            data.map((location, index) => (
                                <TableRow key={index}>
                                    <TableCell>{formatDate(location.createdDate)}</TableCell>
                                    <TableCell>{location.createdBy}</TableCell>
                                    <TableCell>{location.surveyRefNo}</TableCell>
                                    <TableCell>
                                        {props.pending ? (
                                            <>
                                                <Tooltip title='Delete'>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => handleDelete(location.id)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        ) : (
                                            <span></span>
                                        )}

                                        <Tooltip title='Download'>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleReport(location.surveyId)}
                                                style={{ float: 'right' }}
                                            >
                                                <DownloadIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    )
};

export default JobsSurvey;
