import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography, Box, Tab, Tabs, CircularProgress } from '@mui/material';
import useStyles from "./styles";
import { useNavigate } from 'react-router-dom';

import Login from './login';
import ForgetPassword from "./forgetpassword";
import Footer from "../../layout/footer";

import api from "../../axios";
import AccountService from "../../Services/AccountService";

export default function LoginPage() {
    const classes = useStyles();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true); // State to track loading state
    const [company, setCompany] = useState('');
    const [slogan, setslogan] = useState('');
    const [logo, setLogo] = useState('');
    const handleButtonClick = () => {
        // Use the push method of history to navigate to a different route
        navigate('/admin'); // Replace '/destination' with the desired route
    };

    useEffect(() => {

        const token = JSON.parse(localStorage.getItem('user'));
        const fetchData = async () => {
            setLoading(true); // Set loading to true when fetching data starts
            try {
                let key = '';
                if (token != null) {
                    key = token.token;
                }

                const response = await AccountService.GetCompany(key);
                if (response.status === 200) {
                    const userData = response.data; // Assuming user data is in response.data
                    setCompany(userData.company.companyName);
                    setslogan(userData.company.slogan);
                    setLogo(userData.company.logo);
                    setLoading(false); // Set loading to false when data is fetched
                    if (userData.IsAuthorized == true) {
                        if (token != null && token.user != null) {
                            if (token.user.userType === 1 || token.user.userType === 2) {
                                navigate('/admin');
                            } else if (token.user.userType === 3) {
                                navigate('/technician');
                            } else if (token.user.userType === 4) {
                                navigate('/client');
                            }
                        }
                    }
                }
            } catch (error) {
                console.log('Login failed', error);
                setLoading(false); // Set loading to false if there's an error
            }

        }

        fetchData();


    }, []);

    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const handleTabChange = (e, tabIndex) => {
        console.log(tabIndex);
        setCurrentTabIndex(tabIndex);
    };


    return (
        <>
            {loading ? ( // Render CircularProgress if loading is true
                <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid container className={classes.loginPage}>
                    <Grid item xs={12} md={8} className={classes.companyDetailsContainer}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={process.env.REACT_APP_BASE_URL + logo} alt="logo" style={{ maxWidth: '400px', maxHeight: '400px', mixBlendMode: 'multiply' }} />
                            {/* <img src={process.env.REACT_APP_BASE_URL + logo} alt="logo" /> */}
                            <Typography sx={{ mb: 3 }} variant="h1">{company}</Typography>
                            <Typography>{slogan}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.loginFormContainer}>
                        <Paper elevation={3}>

                            <Box sx={{ borderBottom: 1, borderColor: 'divider', height: '100%', width: '100%' }}>
                                <Tabs value={currentTabIndex} onChange={handleTabChange}>
                                    <Tab label='Login' />
                                    <Tab label='Forget Password' />
                                </Tabs>

                                {/* TAB 1 Contents */}
                                {currentTabIndex === 0 && (
                                    <Box sx={{ p: 3 }}>
                                        <Typography sx={{ m: 1, mb: 2 }} variant='h5'>Login to start using app</Typography>
                                        <Login />
                                    </Box>
                                )}

                                {/* TAB 2 Contents */}
                                {currentTabIndex === 1 && (
                                    <Box sx={{ p: 3 }}>
                                        <Typography sx={{ m: 1, mb: 2 }} variant='h5'>Request for password reset</Typography>
                                        <ForgetPassword />
                                    </Box>
                                )}

                            </Box>

                        </Paper>
                    </Grid>
                </Grid>
            )}
            <Footer />
        </>
    );
}
