import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';

const Footer = () => {
    const theme = useTheme(); 
    return (
    <Box
      component="footer"
      sx={{
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        padding: '2px',
        textAlign: 'center',
        position: 'fixed',
        bottom: 0,
        width: '100%',
      }}
    >
      <Typography variant="body2">
        © {new Date().getFullYear()} Fire Safety Engineering . All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
