import React, { useState, useEffect } from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, TextField, TablePagination, Card, CardHeader, CardContent, IconButton, Skeleton, Menu, MenuItem,Tooltip } from '@mui/material';
import { Edit as EditIcon, Security as PermissionIcon } from '@mui/icons-material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import api from '../../../../axios';
import useStyles from '../../formStyle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReusableSnackbar from '../../../../Component/ReusableSnackbar';
import SavePurchaseReturn from './SavePurchaseReturn';
import TransactionBreadcrumbs from '../TransactionBreadcrumbs';
import { format } from 'date-fns';

const formatDate = (dateString) => {
    return format(new Date(dateString), 'dd-MMM-yyyy HH:mm');
};

const ManagePurchaseReturn = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [loading, setLoading] = useState(true);
    const [entity, setEntity] = useState({
        id: 0,
        createdDate: '',
        modifiedDate: '',
        createdBy: '',
        supplierId: 0,
        invNo: '',
        poNo: '',
        totalAmount: 0,
        totalVatAmount: 0,
        notes: '',
        totalDiscount: 0,
    });

    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get('/api/Transaction/GetAllPurchaseReturn', config);
            //console.log(response.data);
            setData(response.data || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setEntity({
            id: 0,
            createdDate: '',
            modifiedDate: '',
            createdBy: '',
            supplierId: 0,
            invNo: '',
            poNo: '',
            totalAmount: 0,
            totalVatAmount: 0,
            notes: '',
            totalDiscount: 0,
        });
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = data.filter((row) =>
        Object.values(row).some((value) => value.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );


    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleToggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    useEffect(() => {
        if (!drawerOpen) {
            fetchData();
        }
    }, [drawerOpen]);

    const handleEdit = (editData) => {
        setEntity({
            id: editData.id,
            createdDate: editData.createdDate,
            modifiedDate: editData.modifiedDate,
            createdBy: editData.createdBy,
            supplierId: editData.supplierId,
            invNo: editData.invNo,
            poNo: editData.poNo,
            totalAmount: editData.totalAmount,
            totalVatAmount: editData.totalVatAmount,
            notes: editData.notes,
            totalDiscount: editData.totalDiscount,
        });
        setDrawerOpen(true);
    }

    const handleLoadData = () => {
        fetchData();
    };

    const handleTost = (msg) => {
        setSnackbarMessage(msg);
        setOpenSnackbar(true);
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const exportToExcel = () => {
        setAnchorEl(null);
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'ReturnGoods');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'returngoods.xlsx');
    };

    return (
        <>
            <ReusableSnackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
            <SavePurchaseReturn isOpen={drawerOpen} toggleDrawer={handleToggleDrawer} entity={entity} LoadData={handleLoadData} ShowTost={handleTost} />
            <TransactionBreadcrumbs />
            <Card>
                <CardHeader title='Return Goods' className={classes.header}
                    action={
                        <>
                            <IconButton
                                aria-label='more'
                                id='long-button'
                                aria-haspopup='true'
                                onClick={handleMenuClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={() => { handleToggleDrawer(); handleMenuClose(); }}>Add New</MenuItem>
                                <MenuItem onClick={exportToExcel}>Export to Excel</MenuItem>
                            </Menu>
                        </>
                    }
                >
                </CardHeader>

                <CardContent>
                    <TextField
                        label='Search'
                        variant='outlined'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: '10px', float: 'right' }}
                    />
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.header}>
                                    <TableCell style={{ fontWeight: 'bold' }}>Id</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Created Date</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Created By</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Supplier</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Inv No</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Po No</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Total Amount</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Total Vat Amount</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Total Discount</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Notes</TableCell>
                                    <TableCell style={{ float: 'right', fontWeight: 'bold' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={12}>
                                            <Skeleton animation='wave' />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    paginatedData.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{user.id}</TableCell>
                                            <TableCell>{formatDate(user.createdDate)}</TableCell>
                                            <TableCell>{user.createdBy}</TableCell>
                                            <TableCell>{user.supplier.Title} {user.supplier.GivenName} {user.supplier.MiddleName} {user.supplier.FamilyName} - {user.supplier.CompanyName}</TableCell>
                                            <TableCell>{user.invNo}</TableCell>
                                            <TableCell>{user.poNo}</TableCell>
                                            <TableCell>{user.totalAmount}</TableCell>
                                            <TableCell>{user.totalVatAmount}</TableCell>
                                            <TableCell>{user.totalDiscount}</TableCell>
                                            <TableCell>{user.notes}</TableCell>
                                            <TableCell>
                                                <Tooltip title='Edit Return Goods'>
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => handleEdit(user)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card>
        </>
    )
};

export default ManagePurchaseReturn;
