import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, CardActions, TextField, Button, CircularProgress, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Grid, Paper } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../../../axios';
import useStyles from '../../formStyle';
import SettingBreadcrumbs from '../SettingBreadcrumbs';

const PushNotification = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [entity, setEntity] = useState({
        Title: '',
        Message: '',
    });

    const formik = useFormik({
        initialValues: {
            Title: entity.Title || '',
            Message: entity.Message || '',
        },
        validationSchema: Yup.object({
            Title: Yup.string().required('Please enter Title'),
            Message: Yup.string().required('Please enter Message'),
        }),
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const apiUrl = '/api/Settings/SendNotification';
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const data = new FormData();
                data.append('Title', values.Title);
                data.append('Message', values.Message);
                const response = await api.post(apiUrl, data, config);
                if (response.status === 200) {
                    alert('Notification send successfully.');
                    //ShowTost(response.data);
                    formik.resetForm();
                } else {
                    //ShowTost('Something went wrong.');
                    console.error('Something went wrong.');
                }
            } catch (error) {
                //ShowTost('Error:', error.message);
                console.error('Error:', error.message);
            } finally {
                setLoading(false);
            }
        },
    });
    useEffect(() => {
        formik.setValues({
            Title: entity.Title || '',
            Message: entity.Message || '',
        });
    }, [entity]);

    return (
        <>
            <div style={{ height: '100vh', overflow: 'auto' }}>
            <SettingBreadcrumbs/>
                    <Card>
                        <CardHeader title='Send Push Notification' className={classes.header}
                            >
                        </CardHeader>
                        <CardContent>
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={1} >
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                name='Title'
                                                label='Title'
                                                value={formik.values.Title}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.Title && Boolean(formik.errors.Title)}
                                                helperText={formik.touched.Title && formik.errors.Title}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                name='Message'
                                                label='Message'
                                                value={formik.values.Message}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.Message && Boolean(formik.errors.Message)}
                                                helperText={formik.touched.Message && formik.errors.Message}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <CardActions className={classes.footer}>
                                    <Button type='submit' disabled={loading} variant='contained' color='primary' size='large' className={classes.largeButton}>
                                        {loading ? (
                                            <>
                                                Please wait...
                                                <CircularProgress size={20} style={{ marginLeft: 8 }} />
                                            </>
                                        ) : (
                                            'Send Notification'
                                        )}
                                    </Button>
                                </CardActions>
                            </form>
                        </CardContent>
                    </Card>
                </div>
        </>
    )
};
export default PushNotification;
