import React, { useState, useEffect } from 'react';
import { AccessAlarm as TimeIcon, WbSunny } from '@mui/icons-material';

const Tile1 = () => {
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());
  const [greeting, setGreeting] = useState('');

  useEffect(() => {
    const updateGreeting = () => {
      const hours = new Date().getHours();
      let newGreeting;

      if (hours < 12) {
        newGreeting = 'Good Morning';
      } else if (hours < 18) {
        newGreeting = 'Good Afternoon';
      } else {
        newGreeting = 'Good Evening';
      }

      setGreeting(newGreeting);
    };

    updateGreeting();

    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
      updateGreeting(); // Update greeting every time the timer ticks
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.contentContainer}>
        <span style={styles.timeContainer}>
          <TimeIcon style={styles.timeIcon} /> {currentTime}
        </span>
        <div style={styles.greetingContainer}>
          <WbSunny style={styles.sunIcon} /> {greeting}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    height: '80px',
    backgroundColor: '#3E4095',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '10px',
    fontFamily: 'Arial, sans-serif'
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  timeContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    color: '#fff',
    backgroundColor: '#000000aa',
    padding: '10px 20px',
    borderRadius: '50px',
    marginRight: '15px',
  },
  timeIcon: {
    marginRight: '10px',
    color: '#FFD700'
  },
  greetingContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    color: '#fff',
    backgroundColor: '#000000aa',
    padding: '10px 20px',
    borderRadius: '50px'
  },
  sunIcon: {
    marginRight: '10px',
    color: '#FFD700'
  }
};

export default Tile1;
