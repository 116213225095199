import React, { useState, useEffect } from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, TextField, TablePagination, Card, CardHeader, CardContent, IconButton, Skeleton, Menu, MenuItem, Avatar, Tooltip } from '@mui/material';
import { Edit as EditIcon, Security as PermissionIcon } from '@mui/icons-material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import api from '../../../axios';
import ReusableSnackbar from '../../../Component/ReusableSnackbar';
import useStyles from '../formStyle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SaveItems from './SaveItems';
import SaveService from './SaveService';
import ImageModel from './ImageModel';
import QBOItemService from './QBOItemService';
import InventoryBreadcrumbs from './InventoryBreadcrumbs';

const ItemsServices = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [imagePath, setImagePath] = useState('');
    const [entity, setEntity] = useState({
        itemId: 0,
        createdDate: '',
        modifiedDate: '',
        createdId: 0,
        itemImg: '',
        Id: '',
        Name: '',
        Description: '',
        Active: true,
        ParentRefvalue: '',
        ParentRefname: '',
        FullyQualifiedName: '',
        UnitPrice: 0,
        Type: '',
        IncomeAccountRefvalue: '',
        IncomeAccountRefname: '',
        PurchaseDesc: '',
        PurchaseCost: 0,
        ExpenseAccountRefvalue: '',
        ExpenseAccountRefname: '',
        AssetAccountRefvalue: '',
        AssetAccountRefname: '',
        PrefVendorRefvalue: '',
        PrefVendorRefname: '',
        TrackQtyOnHand: true,
        QtyOnHand: 0,
        ReorderPoint: 0,
        InvStartDate: '',

        salesTaxInclusive: false,
        salesTaxId: 0,
        salesTaxName: '',
        salesTaxRate: 0,
        purchaseTaxInclusive: false,
        purchaseTaxId: 0,
        purchaseTaxName: '',
        purchaseTaxRate: 0,

    });

    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get('/api/Inventory/ItemsGetAll', config);
            setData(response.data || []);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (type) => {
        setEntity({
            itemId: 0,
            createdDate: '',
            modifiedDate: '',
            createdId: 0,
            itemImg: '',
            Id: '',
            Name: '',
            Description: '',
            Active: true,
            ParentRefvalue: '',
            ParentRefname: '',
            FullyQualifiedName: '',
            UnitPrice: 0,
            Type: '',
            IncomeAccountRefvalue: '',
            IncomeAccountRefname: '',
            PurchaseDesc: '',
            PurchaseCost: 0,
            ExpenseAccountRefvalue: '',
            ExpenseAccountRefname: '',
            AssetAccountRefvalue: '',
            AssetAccountRefname: '',
            PrefVendorRefvalue: '',
            PrefVendorRefname: '',
            TrackQtyOnHand: true,
            QtyOnHand: 0,
            ReorderPoint: 0,
            InvStartDate: '',

            salesTaxInclusive: false,
            salesTaxId: 0,
            salesTaxName: '',
            salesTaxRate: 0,
            purchaseTaxInclusive: false,
            purchaseTaxId: 0,
            purchaseTaxName: '',
            purchaseTaxRate: 0,
        });
        if (type == 'Inventory') {
            setDrawerOpen(!drawerOpen);
        } else if (type == 'Service') {
            setDrawer2Open(!drawer2Open);
        }
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = data.filter((row) =>
        Object.values(row).some((value) => value.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );


    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleToggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    useEffect(() => {
        if (!drawerOpen) {
            fetchData();
        }
    }, [drawerOpen]);

    //----drawer2 is for service-----

    const [drawer2Open, setDrawer2Open] = useState(false);
    const handleToggleDrawer2 = () => {
        setDrawer2Open(!drawer2Open);
    };

    useEffect(() => {
        if (!drawer2Open) {
            fetchData();
        }
    }, [drawer2Open]);

    const handleEdit = (editData) => {
        setEntity({
            itemId: editData.itemId,
            createdDate: editData.createdDate,
            modifiedDate: editData.modifiedDate,
            createdId: editData.createdId,
            itemImg: editData.itemImg,
            Id: editData.Id,
            Name: editData.Name,
            Description: editData.Description,
            Active: editData.Active,

            ParentRef: editData.ParentRefvalue,
            ParentRefvalue: editData.ParentRefvalue,
            ParentRefname: editData.ParentRefname,
            FullyQualifiedName: editData.FullyQualifiedName,
            UnitPrice: editData.UnitPrice,
            Type: editData.Type,
            IncomeAccountRefvalue: editData.IncomeAccountRefvalue,
            IncomeAccountRefname: editData.IncomeAccountRefname,
            PurchaseDesc: editData.PurchaseDesc,
            PurchaseCost: editData.PurchaseCost,
            ExpenseAccountRefvalue: editData.ExpenseAccountRefvalue,
            ExpenseAccountRefname: editData.ExpenseAccountRefname,
            AssetAccountRefvalue: editData.AssetAccountRefvalue,
            AssetAccountRefname: editData.AssetAccountRefname,
            PrefVendorRefvalue: editData.PrefVendorRefvalue,
            PrefVendorRefname: editData.PrefVendorRefname,
            TrackQtyOnHand: editData.TrackQtyOnHand,
            QtyOnHand: editData.QtyOnHand,
            ReorderPoint: editData.ReorderPoint,
            InvStartDate: editData.InvStartDate,

            salesTaxInclusive: editData.salesTaxInclusive,
            salesTaxId: editData.salesTaxId,
            salesTaxName: editData.salesTaxName,
            salesTaxRate: editData.salesTaxRate || 0,

            purchaseTaxInclusive: editData.purchaseTaxInclusive,
            purchaseTaxId: editData.purchaseTaxId,
            purchaseTaxName: editData.purchaseTaxName,
            purchaseTaxRate: editData.purchaseTaxRate,
        });
        if (editData.Type == 'Inventory') {
            setDrawerOpen(true);
        } else if (editData.Type == 'Service') {
            setDrawer2Open(true);
        }
    }

    const handleLoadData = () => {
        fetchData();
    };

    const handleTost = (msg) => {
        setSnackbarMessage(msg);
        setOpenSnackbar(true);
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const openImageModal = (path) => {
        setImagePath(path);
        setModalIsOpen(true);
    };

    const closeImageModal = () => {
        setModalIsOpen(false);
    };

    const [modalQBOIsOpen, setQBOModalIsOpen] = useState(false);
    const [qboAction, setQBOAction] = useState('');
    const openQBOModal = (qbo) => {
        setQBOAction(qbo);
        setQBOModalIsOpen(true);
    };

    const closeQBOModal = () => {
        setQBOModalIsOpen(false);
    };

    const exportToExcel = () => {
        setAnchorEl(null);
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Items');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'items.xlsx');
    };

    return (
        <>
            <ReusableSnackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
            <SaveItems isOpen={drawerOpen} toggleDrawer={handleToggleDrawer} entity={entity} ShowTost={handleTost} />
            <SaveService isOpen={drawer2Open} toggleDrawer={handleToggleDrawer2} entity={entity} ShowTost={handleTost} />
            <ImageModel isOpen={modalIsOpen} closeImageModal={closeImageModal} imagePath={imagePath} />
            <QBOItemService isOpen={modalQBOIsOpen} closeQBOModal={closeQBOModal} qboAction={qboAction} LoadData={handleLoadData} ShowTost={handleTost} />
            <InventoryBreadcrumbs />
            <Card>
                <CardHeader title='Items Services' className={classes.header}
                    action={
                        <>
                            <IconButton
                                aria-label='more'
                                id='long-button'
                                aria-haspopup='true'
                                onClick={handleMenuClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={() => handleMenuClose('Service')}>Add Service</MenuItem>
                                <MenuItem onClick={() => handleMenuClose('Inventory')}>Add Inventory</MenuItem>
                                <MenuItem onClick={() => openQBOModal('Sync To QuickBooks')} >Sync To QuickBooks</MenuItem>
                                <MenuItem onClick={() => openQBOModal('Sync From QuickBooks')}>Sync From QuickBooks</MenuItem>
                                <MenuItem onClick={exportToExcel}>Export to Excel</MenuItem>
                            </Menu>
                        </>
                    }
                >
                </CardHeader>

                <CardContent>
                    <TextField
                        label='Search'
                        variant='outlined'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: '10px', float: 'right' }}
                    />
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.header}>
                                    <TableCell style={{ fontWeight: 'bold' }}>Image</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Category</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Type</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Sales Description</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Sales Price</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Cost</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>QTY On Hand</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Reorder Point</TableCell>
                                    <TableCell style={{ float: 'right', fontWeight: 'bold' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={10}>
                                            <Skeleton animation='wave' />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    paginatedData.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Avatar src={process.env.REACT_APP_BASE_URL + user.itemImg} onClick={() => { openImageModal(user.itemImg) }} variant="square" />
                                            </TableCell>
                                            <TableCell>{user.Name}</TableCell>
                                            <TableCell>{user.ParentRefname}</TableCell>
                                            <TableCell>{user.Type}</TableCell>
                                            <TableCell>{user.Description}</TableCell>
                                            <TableCell>{user.UnitPrice}</TableCell>
                                            <TableCell>{user.PurchaseCost}</TableCell>
                                            <TableCell>{user.QtyOnHand}</TableCell>
                                            <TableCell>{user.ReorderPoint}</TableCell>

                                            <TableCell>
                                                <Tooltip title='Edit'>
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => handleEdit(user)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card>
        </>
    )
};

export default ItemsServices;
