import React, { useState, useEffect } from 'react';
import { Grid, Card, CardHeader, CardContent, TextField, Button, FormControl, FormHelperText, CardActions, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useStyles from "../../formStyle";
import api from "../../../../axios";
import ReusableSnackbar from "../../../../Component/ReusableSnackbar";
import { CircularProgress } from '@mui/material';
import SettingBreadcrumbs from '../SettingBreadcrumbs';

const QuickBooks = () => {
    const classes = useStyles();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [CompanyInfo, setcompanyInfo] = useState('');
    const [TokenEexpire, setTokenEexpire] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const response = await api.get(`/api/Settings/GetQboSetting`, config);
                //console.log(response);
                console.log("a");
                if (response.status === 200) {
                    const initialValues = response.data; // Update this based on the actual response structure
                    formik.setValues(initialValues);

                    const response2 = await api.get(`/api/Settings/GetQboCompany`, config);
                    if (response2.status === 200) {
                        console.log(response2.data);
                        setcompanyInfo(response2.data.CompanyInfo.CompanyInfo);
                        setTokenEexpire(response2.data.Eexpire);
                    }

                } else {
                    // Handle error response appropriately
                    console.log(`Error: ${response.status} - ${response.statusText}`);
                }
            } catch (error) {
                // Handle unexpected errors
                console.log(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const formik = useFormik({
        initialValues: {
            clientId: '',
            clientSecret: '',
            refreshToken: '',
            realmId: '',
        },
        validationSchema: Yup.object({
            clientId: Yup.string().required('Client id is required'),
            clientSecret: Yup.string().required('Client secret is required'),
            refreshToken: Yup.string().required('Refresh token is required'),
            realmId: Yup.string().required('Realm id is required'),
        }),
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const response = await api.post(`/api/Settings/UpdateQboSetting`, values, config);
                console.log(response);
                if (response.status === 200) {
                    const userData = response.data;
                    setSnackbarMessage(userData);
                    setOpenSnackbar(true);
                } else {
                    const userData = response.data;
                    setSnackbarMessage(userData);
                    setOpenSnackbar(true);
                }
            } catch (error) {
                setSnackbarMessage(error.message);
                setOpenSnackbar(true);
                console.log(error);
            } finally {
                setLoading(false);
            }
        },
    });

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <>
            <div>
                <ReusableSnackbar
                    open={openSnackbar}
                    onClose={handleCloseSnackbar}
                    message={snackbarMessage}
                    type="error"
                />
            </div>
            <SettingBreadcrumbs/>
            <Card>
                <CardHeader title="Quick Books API Setting" className={classes.header}></CardHeader>
                <form onSubmit={formik.handleSubmit}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={8}>

                                <FormControl fullWidth>
                                    <TextField
                                        label="Client Id"
                                        name="clientId"
                                        margin="normal"
                                        value={formik.values.clientId}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.clientId && Boolean(formik.errors.clientId)}
                                    />
                                    {formik.touched.clientId && formik.errors.clientId && (
                                        <FormHelperText error>{formik.errors.clientId}</FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl fullWidth>
                                    <TextField
                                        label="Client Secret"
                                        name="clientSecret"
                                        margin="normal"
                                        value={formik.values.clientSecret}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.clientSecret && Boolean(formik.errors.clientSecret)}
                                    />
                                    {formik.touched.clientSecret && formik.errors.clientSecret && (
                                        <FormHelperText error>{formik.errors.clientSecret}</FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl fullWidth>
                                    <TextField
                                        label="Refresh Token"
                                        name="refreshToken"
                                        margin="normal"
                                        value={formik.values.refreshToken}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.refreshToken && Boolean(formik.errors.refreshToken)}
                                    />
                                    {formik.touched.refreshToken && formik.errors.refreshToken && (
                                        <FormHelperText error>{formik.errors.refreshToken}</FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl fullWidth>
                                    <TextField
                                        label="Realm Id"
                                        name="realmId"
                                        margin="normal"
                                        value={formik.values.realmId}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.realmId && Boolean(formik.errors.realmId)}
                                    />
                                    {formik.touched.realmId && formik.errors.realmId && (
                                        <FormHelperText error>{formik.errors.realmId}</FormHelperText>
                                    )}
                                </FormControl>

                            </Grid>
                            <Grid item xs={12} sm={3.9} style={{ border: '1px solid #D3D3D3', marginLeft: '4px' }}>
                                <h1>Company Info </h1>
                                <Typography>Company Name : {CompanyInfo.CompanyName}</Typography>
                                <Typography>Legal Name : {CompanyInfo.LegalName}</Typography>
                                <Typography>Company Start Date : {CompanyInfo.CompanyStartDate}</Typography>
                                <Typography>Fiscal Year Start Month : {CompanyInfo.FiscalYearStartMonth}</Typography>
                                <Typography>Country : {CompanyInfo.Country}</Typography>
                                <Typography>Email: {CompanyInfo && CompanyInfo.Email && CompanyInfo.Email.Address ? CompanyInfo.Email.Address : ' '}</Typography>
                                <Typography>Create Time: {CompanyInfo && CompanyInfo.MetaData && CompanyInfo.MetaData.CreateTime ? CompanyInfo.MetaData.CreateTime : ' '}</Typography>
                                <Typography>
                                    Last Updated Time: {CompanyInfo && CompanyInfo.MetaData && CompanyInfo.MetaData.LastUpdatedTime ? CompanyInfo.MetaData.LastUpdatedTime : ' '}
                                </Typography>
                                <a target='_blank' href='https://service.fsequip.co.za/QBO/index'>Click here to renew refresh token - current token expires in {TokenEexpire} Days</a>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions className={classes.footer}>

                        <Button type="submit" disabled={loading} variant="contained" color="primary" size="large" className={classes.largeButton}>
                            {loading ? (
                                <>
                                    Please wait...
                                    <CircularProgress size={20} style={{ marginLeft: 8 }} />
                                </>
                            ) : (
                                'Save Setting'
                            )}
                        </Button>
                    </CardActions>
                </form>
            </Card>
        </>

    )
};

export default QuickBooks;
