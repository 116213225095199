import React, { useState, useEffect } from 'react';
import { Drawer, Card, CardHeader, CardContent, CardActions, Divider, Box, TextField, Avatar, Button, CircularProgress, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Grid, Paper, IconButton, Accordion, AccordionActions, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../../axios';

import useStyles from '../formStyle';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SaveService = ({ isOpen, toggleDrawer, entity, ShowTost }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [taxes, setTaxes] = useState([]);
    const [imgFile, setImageFile] = useState(null);

    const formik = useFormik({
        initialValues: {
            itemId: entity.itemId || 0,
            itemImg: entity.itemImg || null,
            Name: entity.Name || '',
            Description: entity.Description || '',
            Active: entity.Active || 0,

            ParentRef: entity.ParentRef | '',
            ParentRefvalue: entity.ParentRefvalue || '',
            ParentRefname: entity.ParentRefname || '',
            UnitPrice: entity.UnitPrice || '',

            PurchaseDesc: entity.PurchaseDesc || '',
            PurchaseCost: entity.PurchaseCost || 0,

            ExpenseAccountRefvalue: entity.ExpenseAccountRefvalue || 0,
            ExpenseAccountRefname: entity.ExpenseAccountRefname || '',
            ExpenseAccountRef: entity.ExpenseAccountRefvalue || 0,

            AssetAccountRefvalue: entity.AssetAccountRefvalue || 0,
            AssetAccountRefname: entity.AssetAccountRefname || '',
            AssetAccountRef: entity.AssetAccountRefvalue || 0,

            PrefVendorRefvalue: entity.PrefVendorRefvalue || 0,
            PrefVendorRefname: entity.PrefVendorRefname || '',
            PrefVendorRefname: entity.PrefVendorRefvalue || 0,

            salesTaxRef: entity.salesTaxRef || '',
            salesTaxInclusive: entity.salesTaxInclusive || false,
            salesTaxId: entity.salesTaxId || 0,
            salesTaxName: entity.salesTaxName || '',
            salesTaxRate: entity.salesTaxRate || 0,

            purchaseTaxRef: entity.salesTaxRef || '',
            purchaseTaxInclusive: entity.purchaseTaxInclusive || false,
            purchaseTaxId: entity.purchaseTaxId || 0,
            purchaseTaxName: entity.purchaseTaxName || '',
            purchaseTaxRate: entity.purchaseTaxRate || 0,

            IncomeAccountRefvalue:entity.IncomeAccountRefvalue||0,
            IncomeAccountRefname:entity.IncomeAccountRefname||'',
            IncomeAccountRef: entity.IncomeAccountRefvalue || 0,
        },
        validationSchema: Yup.object({
            Name: Yup.string().required('Add a product name'),
            UnitPrice: Yup.string().required('Please enter Unit Price'),
            IncomeAccountRefname: Yup.string().required('Please select'),
            salesTaxName: Yup.string().required('Please select'),
            ExpenseAccountRefname: Yup.string().required('Please select'),
            purchaseTaxName:Yup.string().required('Please select'),
        }),
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const apiUrl = '/api/Inventory/SaveService';
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const data = new FormData();
                data.append('itemId', values.itemId);
                data.append('itemImg', values.itemImg);
                data.append('Name', values.Name);
                data.append('Description', values.Description);
                data.append('Active', values.Active);
                data.append('ParentRefvalue', values.ParentRefvalue);
                data.append('ParentRefname', values.ParentRefname);
                data.append('UnitPrice', values.UnitPrice);
                data.append('PurchaseDesc', values.PurchaseDesc);
                data.append('PurchaseCost', values.PurchaseCost);
                data.append('ExpenseAccountRefvalue', values.ExpenseAccountRefvalue);
                data.append('ExpenseAccountRefname', values.ExpenseAccountRefname);
                data.append('AssetAccountRefvalue', values.AssetAccountRefvalue);
                data.append('AssetAccountRefname', values.AssetAccountRefname);
                data.append('PrefVendorRefvalue', values.PrefVendorRefvalue);
                data.append('PrefVendorRefname', values.PrefVendorRefname);

                data.append('salesTaxInclusive', values.salesTaxInclusive);
                data.append('salesTaxId', values.salesTaxId);
                data.append('salesTaxName', values.salesTaxName);
                data.append('salesTaxRate', values.salesTaxRate);
                data.append('purchaseTaxInclusive', values.purchaseTaxInclusive);
                data.append('purchaseTaxId', values.purchaseTaxId);
                data.append('purchaseTaxName', values.purchaseTaxName);
                data.append('purchaseTaxRate', values.purchaseTaxRate);

                data.append('IncomeAccountRefvalue',values.IncomeAccountRefvalue);
                data.append('IncomeAccountRefname',values.IncomeAccountRefname);
                if (imgFile) {
                    data.append('itemImg', imgFile);
                }

                const response = await api.post(apiUrl, data, config);
                if (response.status === 200) {
                    console.log('created successfully.');
                    ShowTost(response.data);
                    formik.resetForm();
                } else {
                    ShowTost('Something went wrong.');
                    console.error('Something went wrong.');
                }
            } catch (error) {
                ShowTost('Error:', error.message);
                console.error('Error:', error.message);
            } finally {
                setLoading(false);
                toggleDrawer();
            }
        },
    });

    useEffect(() => {
        fetchAccountAndCategory();
        formik.setValues({
            itemId: entity.itemId || 0,
            //itemImg: entity.itemImg.length > 0 ? process.env.REACT_APP_BASE_URL + entity.itemImg : '',
            itemImg: entity.itemImg ? process.env.REACT_APP_BASE_URL + entity.itemImg : '',
            Id: entity.Id || '',
            Name: entity.Name || '',
            Description: entity.Description || '',
            Active: entity.Active || 0,

            ParentRef: entity.ParentRef || '',
            ParentRefvalue: entity.ParentRefvalue || '',
            ParentRefname: entity.ParentRefname || '',
            UnitPrice: entity.UnitPrice || '',

            IncomeAccountRefvalue: entity.IncomeAccountRefvalue || 0,
            IncomeAccountRefname: entity.IncomeAccountRefname || '',
            IncomeAccountRef: entity.IncomeAccountRefvalue || 0,

            PurchaseDesc: entity.PurchaseDesc || '',
            PurchaseCost: entity.PurchaseCost || '',

            ExpenseAccountRefvalue: entity.ExpenseAccountRefvalue || 0,
            ExpenseAccountRefname: entity.ExpenseAccountRefname || '',
            ExpenseAccountRef: entity.ExpenseAccountRefvalue || 0,

            AssetAccountRefvalue: entity.AssetAccountRefvalue || 0,
            AssetAccountRefname: entity.AssetAccountRefname || '',
            AssetAccountRef: entity.AssetAccountRefvalue || 0,

            PrefVendorRefvalue: entity.PrefVendorRefvalue || '',
            PrefVendorRefname: entity.PrefVendorRefname || 0,
            //TrackQtyOnHand: entity.TrackQtyOnHand || '',
            //QtyOnHand: entity.QtyOnHand || 0,
            //ReorderPoint: entity.ReorderPoint || 0,

            //InvStartDate: entity.InvStartDate || '',
            //InvStartDate:format(new Date(entity.InvStartDate),'yyyy-MM-dd'),
            //InvStartDate: entity.InvStartDate ? format(new Date(entity.InvStartDate), 'yyyy-MM-dd') : '',

            salesTaxRef: entity.salesTaxId || 0,
            salesTaxInclusive: entity.salesTaxInclusive || false,
            salesTaxId: entity.salesTaxId || 0,
            salesTaxName: entity.salesTaxName || '',
            salesTaxRate: entity.salesTaxRate || 0,

            purchaseTaxRef: entity.purchaseTaxId || 0,
            purchaseTaxInclusive: entity.purchaseTaxInclusive || false,
            purchaseTaxId: entity.purchaseTaxId || 0,
            purchaseTaxName: entity.purchaseTaxName || '',
            purchaseTaxRate: entity.purchaseTaxRate || 0,

        });
        //alert(entity.Description);
        //console.log(entity);
    }, [entity]);

    const fetchAccountAndCategory = async () => {
        const apiUrl = '/api/Inventory/GetAccountAndCategory';
        const user = JSON.parse(localStorage.getItem('user'));
        const token = user.token;
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        try {
            const response = await api.get(apiUrl, config);
            setAccounts(response.data.Accounts || []);
            setCategories(response.data.Categories || []);
            setSuppliers(response.data.Suppliers || []);
            setTaxes(response.data.Taxes || []);
            //console.log(response.data.Accounts.filter(acc => acc.invAssetAcc));
        } catch (error) {
            // Handle error
            console.error('Error fetching account and category:', error);
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImageFile(file);
            const imageUrl = URL.createObjectURL(file);
            formik.setValues({
                ...formik.values,
                itemImg: imageUrl
            });
        }
    };

    return (
        <Drawer anchor='right'
            open={isOpen}
            onClose={toggleDrawer}
            variant='temporary'
            ModalProps={{
                keepMounted: true,
            }}
            PaperProps={{
                sx: {
                    width: '100%',
                    maxWidth: '700px', // Adjust width as needed
                },
            }} >

            <div style={{ height: '100vh', overflow: 'auto' }}>
                <Card>
                    <CardHeader title="Save Service" className={classes.header}
                        action={
                            <>
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    aria-label="close"
                                    onClick={toggleDrawer}
                                    style={{ marginRight: 2 }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </>
                        }>
                    </CardHeader>
                    <CardContent>
                        <form onSubmit={formik.handleSubmit}>

                            <Accordion defaultExpanded>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                                    Basic Info
                                </AccordionSummary>
                                <AccordionDetails>

                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ width: '75%' }}>
                                            <Grid container spacing={1} >
                                                <Grid item xs={12} sm={12}>
                                                    <FormControl fullWidth margin='normal'>
                                                        <TextField
                                                            label='Name*'
                                                            name='Name'
                                                            value={formik.values.Name}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            error={formik.touched.Name && Boolean(formik.errors.Name)}
                                                            helpertext={formik.touched.Name && formik.errors.Name}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <FormControl fullWidth margin='normal'>
                                                        <InputLabel id="category-label">Category</InputLabel>
                                                        <Select
                                                            name='ParentRef'
                                                            label="category"
                                                            labelId="category-label"
                                                            value={formik.values.ParentRef}
                                                            onChange={event => {
                                                                formik.handleChange(event);
                                                                const selectedCategory = categories.find(cat => cat.Id === event.target.value);
                                                                formik.setFieldValue('ParentRefvalue', event.target.value);
                                                                formik.setFieldValue('ParentRefname', selectedCategory ? selectedCategory.Name : '');
                                                                formik.setFieldValue('ParentRef', event.target.value);
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                        >
                                                            {categories.map(cat => (
                                                                <MenuItem key={cat.Id} value={cat.Id}>
                                                                    {cat.Name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div style={{ width: '25%' }}>
                                            <div style={{ border: '2px dashed #ccc', marginLeft: '4px', padding: '10px', textAlign: 'center' }}>
                                                <Box mt={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Avatar
                                                        //src={formik.values.img ? URL.createObjectURL(formik.values.img) : formik.values.img}
                                                        src={formik.values.itemImg}
                                                        sx={{ minWidth: '80px', minHeight: '80px' }} // Adjust width to fill the available space
                                                        variant="square"
                                                    />
                                                </Box>

                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    name="img"
                                                    //onChange={(event) => formik.setFieldValue('img', event.currentTarget.files[0])}
                                                    onChange={handleImageChange}
                                                    onBlur={formik.handleBlur}
                                                    style={{ display: 'none' }}
                                                    id="img-upload2"
                                                />
                                                <label htmlFor="img-upload2">
                                                    <Button variant="contained" component="span">
                                                        Image
                                                    </Button>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                </AccordionDetails>
                            </Accordion>

                            <Accordion defaultExpanded>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
                                    Sales
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={1}>

                                        <Grid item xs={12} sm={12}>
                                            <FormControl fullWidth margin='normal'>
                                                <TextField
                                                    label='Description'
                                                    name='Description'
                                                    value={formik.values.Description}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth margin='normal'>
                                                <TextField
                                                    label='Price/rate'
                                                    name='UnitPrice'
                                                    value={formik.values.UnitPrice}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.UnitPrice && Boolean(formik.errors.UnitPrice)}
                                                    helpertext={formik.touched.UnitPrice && formik.errors.UnitPrice}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth margin='normal'>
                                                <InputLabel id="Income-account-label">Income account*</InputLabel>
                                                <Select
                                                    name='IncomeAccountRefname'
                                                    label="Income account*"
                                                    labelId="Income-account-label"
                                                    value={formik.values.IncomeAccountRef}
                                                    onChange={event => {
                                                        formik.handleChange(event);
                                                        const selectedAccount = accounts.find(account => account.Id === event.target.value);
                                                        console.log(selectedAccount);
                                                        formik.setFieldValue('IncomeAccountRefvalue', event.target.value);
                                                        formik.setFieldValue('IncomeAccountRefname', selectedAccount ? selectedAccount.Name : '');
                                                        formik.setFieldValue('IncomeAccountRef', event.target.value);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.IncomeAccountRefname && Boolean(formik.errors.IncomeAccountRefname)}
                                                    helpertext={formik.touched.IncomeAccountRefname && formik.errors.IncomeAccountRefname}
                                                >
                                                    {accounts.filter(acc => acc.incAcc).map(acc => (
                                                        <MenuItem key={acc.Id} value={acc.Id}>
                                                            {acc.Name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth margin='normal'>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name='salesTaxInclusive'
                                                            checked={formik.values.salesTaxInclusive}
                                                            onChange={formik.handleChange}
                                                        />
                                                    }
                                                    label='Inclusive of tax'
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth margin='normal'>
                                                <InputLabel id="Tax-label">Tax</InputLabel>
                                                <Select
                                                    name='salesTaxName'
                                                    label="Tax"
                                                    labelId="Tax-label"
                                                    value={formik.values.salesTaxRef}
                                                    onChange={event => {
                                                        formik.handleChange(event);
                                                        const selectedTax = taxes.find(tax => tax.Id === event.target.value);
                                                        formik.setFieldValue('salesTaxId', event.target.value);
                                                        formik.setFieldValue('salesTaxName', selectedTax ? selectedTax.Name : '');
                                                        formik.setFieldValue('salesTaxRate', selectedTax ? selectedTax.salesTaxRate : 0);
                                                        formik.setFieldValue('salesTaxRef', event.target.value);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.salesTaxName && Boolean(formik.errors.salesTaxName)}
                                                    helpertext={formik.touched.salesTaxName && formik.errors.salesTaxName}
                                                >
                                                    {taxes.map(tax => (
                                                        <MenuItem key={tax.Id} value={tax.Id}>
                                                            {tax.Name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content" id="panel4-header">
                                    Purchasing
                                </AccordionSummary>
                                <AccordionDetails>

                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12}>
                                            <FormControl fullWidth margin='normal'>
                                                <TextField
                                                    label='Purchase Desccription'
                                                    name='PurchaseDesc'
                                                    value={formik.values.PurchaseDesc}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth margin='normal'>
                                                <TextField
                                                    label='Purchase Cost'
                                                    name='PurchaseCost'
                                                    value={formik.values.PurchaseCost}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth margin='normal'>
                                                <InputLabel id="Expense-account-label">Expense account*</InputLabel>
                                                <Select
                                                    name='ExpenseAccountRefname'
                                                    label="Expense account"
                                                    labelId="Expense-account-label"
                                                    value={formik.values.ExpenseAccountRef}
                                                    onChange={event => {
                                                        formik.handleChange(event);
                                                        const selectedAccount = accounts.find(account => account.Id === event.target.value);
                                                        formik.setFieldValue('ExpenseAccountRefvalue', event.target.value);
                                                        formik.setFieldValue('ExpenseAccountRefname', selectedAccount ? selectedAccount.Name : '');
                                                        formik.setFieldValue('ExpenseAccountRef', event.target.value);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.ExpenseAccountRefname && Boolean(formik.errors.ExpenseAccountRefname)}
                                                    helpertext={formik.touched.ExpenseAccountRefname && formik.errors.ExpenseAccountRefname}
                                                >
                                                    {accounts.filter(acc => acc.expAcc).map(acc => (
                                                        <MenuItem key={acc.Id} value={acc.Id}>
                                                            {acc.Name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth margin='normal'>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name='purchaseTaxInclusive'
                                                            checked={formik.values.purchaseTaxInclusive}
                                                            onChange={formik.handleChange}
                                                        />
                                                    }
                                                    label='Inclusive of purchase tax'
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth margin='normal'>
                                                <InputLabel id="PurchaseTax-label">Purchase Tax</InputLabel>
                                                <Select
                                                    name='purchaseTaxName'
                                                    label="Tax"
                                                    labelId="PurchaseTax-label"
                                                    value={formik.values.purchaseTaxRef}
                                                    onChange={event => {
                                                        formik.handleChange(event);
                                                        const selectedTax = taxes.find(tax => tax.Id === event.target.value);
                                                        formik.setFieldValue('purchaseTaxId', event.target.value);
                                                        formik.setFieldValue('purchaseTaxName', selectedTax ? selectedTax.Name : '');
                                                        formik.setFieldValue('purchaseTaxRate', selectedTax ? selectedTax.purchaseTaxRate : 0);
                                                        formik.setFieldValue('purchaseTaxRef', event.target.value);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.purchaseTaxName && Boolean(formik.errors.purchaseTaxName)}
                                                    helpertext={formik.touched.purchaseTaxName && formik.errors.purchaseTaxName}
                                                >
                                                    {taxes.map(tax => (
                                                        <MenuItem key={tax.Id} value={tax.Id}>
                                                            {tax.Name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth margin='normal'>
                                                <InputLabel id="Preferred-Vendor-label">Preferred Vendor</InputLabel>
                                                <Select
                                                    name='PrefVendorRefname'
                                                    label="Preferred Vendor*"
                                                    labelId="Preferred-Vendor-label"
                                                    value={formik.values.PrefVendorRefvalue}
                                                    onChange={event => {
                                                        formik.handleChange(event);
                                                        const selectedSupplier = suppliers.find(supp => supp.supplierId === event.target.value);
                                                        formik.setFieldValue('PrefVendorRefvalue', event.target.value);
                                                        formik.setFieldValue('PrefVendorRefname', selectedSupplier ? `${selectedSupplier.Title || ''}${selectedSupplier.Title ? ' ' : ''}${selectedSupplier.GivenName || ''}${(selectedSupplier.Title && selectedSupplier.GivenName) ? ' ' : ''}${selectedSupplier.MiddleName || ''}${(selectedSupplier.GivenName && selectedSupplier.MiddleName) ? ' ' : ''}${selectedSupplier.FamilyName || ''}` : '');
                                                        formik.setFieldValue('PrefVendorRef', event.target.value);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                >
                                                    {suppliers.map(supp => (
                                                        <MenuItem key={supp.supplierId} value={supp.supplierId}>
                                                            {supp.GivenName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                    </Grid>

                                </AccordionDetails>
                            </Accordion>

                            <Grid item xs={12} sm={12}>
                                <FormControl fullWidth margin='normal'>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='Active'
                                                checked={formik.values.Active}
                                                onChange={formik.handleChange}
                                            />
                                        }
                                        label='Active'
                                    />
                                </FormControl>
                            </Grid>

                            <CardActions className={classes.footer}>

                                <Button type="submit" disabled={loading} variant="contained" color="primary" size="large" className={classes.largeButton}>
                                    {loading ? (
                                        <>
                                            Please wait...
                                            <CircularProgress size={20} style={{ marginLeft: 8 }} />
                                        </>
                                    ) : (
                                        'Save'
                                    )}
                                </Button>
                            </CardActions>



                        </form>
                    </CardContent>
                </Card>
            </div>
        </Drawer>
    )
};

export default SaveService;
