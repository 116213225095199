import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedAdminRoute = ({ element }) => {
    const token = JSON.parse(localStorage.getItem('user'));

    if (token != null && token.user != null) {
        if (token.user.userType === 1 || token.user.userType === 2) {
            return <>{element}</>;
        } else {
            return <Navigate to="/" replace />;
        }
    }else{
        return <Navigate to="/" replace />;
    }
};

export default ProtectedAdminRoute;