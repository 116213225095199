import React, { useState, useEffect } from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, TextField, TablePagination, Card, CardHeader, CardContent, IconButton, Skeleton, Menu, MenuItem, Tooltip } from '@mui/material';
import { Edit as EditIcon, CloudUpload as QBPushIcon, Download as DownloadIcon } from '@mui/icons-material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import api from '../../axios';
import useStyles from '../../layout/admin/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReusableSnackbar from '../../Component/ReusableSnackbar';
import { format } from 'date-fns';

const formatDate = (dateString) => {
    return format(new Date(dateString), 'dd-MMM-yyyy HH:mm');
};

const formatDate2 = (dateString) => {
    return format(new Date(dateString), 'dd-MMM-yyyy');
};

const InvoiceClient = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [loading, setLoading] = useState(true);
    const [entity, setEntity] = useState({
        invId: 0,
        createdDate: '',
        modifiedDate: '',
        createdBy: '',
        Id: '',
        ClientID: 0,
        invNo: '',
        invDate: '',
        dueDate: '',
        customField1: '',
        customField2: '',
        customField3: '',
        notes: '',
        privateNotes: '',
        taxType: '',
        subtotalAmount: '',
        vatAmount: '',
        totalAmount: '',
    });

    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get('/api/ClientPortal/GetAllInvoice', config);
            //console.log(response.data);
            setData(response.data || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setEntity({
            invId: 0,
            createdDate: '',
            modifiedDate: '',
            createdBy: '',
            Id: '',
            ClientID: 0,
            invNo: '',
            invDate: '',
            dueDate: '',
            customField1: '',
            customField2: '',
            customField3: '',
            notes: '',
            privateNotes: '',
            taxType: '',
            subtotalAmount: '',
            vatAmount: '',
            totalAmount: '',
        });
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = data.filter((row) =>
        Object.values(row).some((value) => value.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );


    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleToggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    useEffect(() => {
        if (!drawerOpen) {
            fetchData();
        }
    }, [drawerOpen]);

    const handleEdit = (editData) => {
        setEntity({
            invId: editData.invId,
            createdDate: editData.createdDate,
            modifiedDate: editData.modifiedDate,
            createdBy: editData.createdBy,
            Id: editData.Id,

            ClientID: editData.ClientID,
            invNo: editData.invNo,
            invDate: editData.invDate,
            dueDate: editData.dueDate,
            customField1: editData.customField1,
            customField2: editData.customField2,
            customField3: editData.customField3,
            notes: editData.notes,
            privateNotes: editData.privateNotes,
            taxType: editData.taxType,
            subtotalAmount: editData.subtotalAmount,
            vatAmount: editData.vatAmount,
            totalAmount: editData.totalAmount,

        });
        setDrawerOpen(true);
    }

    const handleLoadData = () => {
        fetchData();
    };

    const handleTost = (msg) => {
        setSnackbarMessage(msg);
        setOpenSnackbar(true);
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const exportToExcel = () => {
        setAnchorEl(null);
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Invoice');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'invoice.xlsx');
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const openQBOModal = () => {
        setModalIsOpen(true);
    };

    const closeQBOModal = () => {
        setModalIsOpen(false);
    };

    const handlePushQB = (editData) => {
        setEntity(editData);
        setModalIsOpen(true);
    }

    const handleDownloadInvoice = async (editData) => {
        try {
            setLoading(true); // Start loading
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                responseType: 'blob', // Important for downloading files
            };

            const response = await api.get('/api/Transaction/InvoiceReport?invId=' + editData.invId, config);
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Generate a file name for the PDF
            const pdfFileName = `${editData.invNo}_Invoice_${new Date().toISOString()}.pdf`;

            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);

            // Create a link element
            const link = document.createElement('a');
            link.href = url;
            link.download = pdfFileName;

            // Append the link to the body
            document.body.appendChild(link);

            // Simulate a click event on the link
            link.click();

            // Remove the link from the body
            document.body.removeChild(link);

            // Revoke the blob URL to free up memory
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    }

    return (
        <>
            <ReusableSnackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
            <Card>
                <CardHeader title='Invoice' className={classes.header}

                >
                </CardHeader>

                <CardContent>
                    <TextField
                        label='Search'
                        variant='outlined'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: '10px', float: 'right' }}
                    />
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.header}>
                                    <TableCell style={{ fontWeight: 'bold' }}>Id</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Created Date</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Created By</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Client</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Invoice No</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Invoice Date</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Total Amount</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Total Vat Amount</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Grand Total</TableCell>
                                    <TableCell style={{ float: 'right', fontWeight: 'bold' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={12}>
                                            <Skeleton animation='wave' />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    paginatedData.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{user.invId}</TableCell>
                                            <TableCell>{formatDate(user.createdDate)}</TableCell>
                                            <TableCell>{user.createdBy}</TableCell>
                                            <TableCell>{user.Client.Title} {user.Client.GivenName} {user.Client.MiddleName} {user.Client.FamilyName} - {user.Client.CompanyName}</TableCell>
                                            <TableCell>{user.invNo}</TableCell>
                                            <TableCell>{formatDate2(user.invDate)}</TableCell>
                                            <TableCell>{user.totalAmount}</TableCell>
                                            <TableCell>{user.vatAmount}</TableCell>
                                            <TableCell>{user.totalAmount+user.vatAmount}</TableCell>
                                            <TableCell>
                                                <Tooltip title='Download Invoice'>
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => handleDownloadInvoice(user)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <DownloadIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card>
        </>
    )
};

export default InvoiceClient;
