import React, { useState, useEffect } from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, TextField, TablePagination, Card, CardHeader, CardContent, IconButton, Skeleton, Menu, MenuItem, Checkbox } from '@mui/material';
import api from '../../axios';
import useStyles from '../admin/formStyle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReusableSnackbar from '../../Component/ReusableSnackbar';
import SaveServiceRequest from './saveservicerequest';

const Communication = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [entity, setEntity] = useState({
        contactPerson: '',
        contactPersonMobile: '',
        serviceReqDesc: '',
        priority: '',
    });

    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get('/api/ClientPortal/GetAllServiceRequest', config);
            //console.log(response.data);
            setData(response.data || []);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (type) => {
        setAnchorEl(null);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = data.filter((row) =>
        Object.values(row).some((value) => value.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );


    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleToggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    useEffect(() => {
        if (!drawerOpen) {
            fetchData();
        }
    }, [drawerOpen]);

    const handleTost = (msg) => {
        setSnackbarMessage(msg);
        setOpenSnackbar(true);
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const openQBOModal = () => {
        setModalIsOpen(true);
    };

    const closeQBOModal = () => {
        setModalIsOpen(false);
    };

    const handleLoadData = () => {
        fetchData();
    };

    return (
        <>
            <ReusableSnackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
            <SaveServiceRequest isOpen={drawerOpen} toggleDrawer={handleToggleDrawer} closeQBOModal={closeQBOModal} entity={entity} LoadData={handleLoadData} ShowTost={handleTost} />
            <Card>
                <CardHeader title='Communication' className={classes.header}
                    action={
                        <>
                            <IconButton
                                aria-label='more'
                                id='long-button'
                                aria-haspopup='true'
                                onClick={handleMenuClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={() => { handleToggleDrawer(); handleMenuClose(); }}>Add Service Request</MenuItem>
                                
                            </Menu>
                        </>
                    }
                >
                </CardHeader>

                <CardContent>
                    <TextField
                        label='Search'
                        variant='outlined'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: '10px', float: 'right' }}
                    />
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.header}>
                                    <TableCell style={{ fontWeight: 'bold' }}>Created Date</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Contact Person</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Mobile</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Description</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Priority</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <Skeleton animation='wave' />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    paginatedData.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{user.createdDate}</TableCell>
                                            <TableCell>{user.contactPerson}</TableCell>
                                            <TableCell>{user.contactPersonMobile}</TableCell>
                                            <TableCell>{user.serviceReqDesc}</TableCell>
                                            <TableCell>{user.priority}</TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card>
        </>
    )
};

export default Communication;
