import React, { useState, useEffect } from 'react';
import { Drawer, Card, CardHeader, CardContent, CardActions, IconButton, TextField, Button, CircularProgress, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Grid, Paper } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../../axios';
import useStyles from '../formStyle';
import CloseIcon from '@mui/icons-material/Close';

const SaveServiceRequest = ({ isOpen, ShowTost, toggleDrawer, entity }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            Id: entity.Id || '',
            serviceStatus: entity.serviceStatus || 0,
            notes: entity.notes || '',
            contactedBy: entity.contactedBy || '',
            contactDate: entity.contactDate || '',
            resolution: entity.resolution || '',
        },
        validationSchema: Yup.object({
        }),
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const apiUrl = '/api/Clients/UpdateServiceRequest';
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const data = new FormData();
                data.append('Id', values.Id);
                data.append('serviceStatus', values.serviceStatus);
                data.append('notes', values.notes);
                data.append('contactedBy', values.contactedBy);
                data.append('contactDate', values.contactDate);
                data.append('resolution', values.resolution);
                const response = await api.post(apiUrl, data, config);
                if (response.status === 200) {
                    console.log('created successfully.');
                    ShowTost(response.data);
                    formik.resetForm();
                } else {
                    ShowTost('Something went wrong.');
                    console.error('Something went wrong.');
                }
            } catch (error) {
                ShowTost('Error:', error.message);
                console.error('Error:', error.message);
            } finally {
                setLoading(false);
                toggleDrawer();
            }
        },
    });
    useEffect(() => {
        formik.setValues({
            Id: entity.Id || '',
            serviceStatus: entity.serviceStatus || 0,
            notes: entity.notes || '',
            contactedBy: entity.contactedBy || '',
            //contactDate: entity.contactDate || '',
            contactDate : entity.contactDate ? formatDate(entity.contactDate) : '',
            resolution: entity.resolution || '',
        });
    }, [entity]);

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return (
        <>
            <Drawer anchor='right'
                open={isOpen}
                onClose={toggleDrawer}
                variant='temporary'
                ModalProps={{
                    keepMounted: true,
                }}
                PaperProps={{
                    sx: {
                        width: '100%',
                        maxWidth: '550px', // Adjust width as needed
                    },
                }} >
                <div style={{ height: '100vh', overflow: 'auto' }}>
                    <Card>
                        <CardHeader title='Save Service Request' className={classes.header}
                            action={
                                <>
                                    <IconButton
                                        edge='end'
                                        color='inherit'
                                        aria-label='close'
                                        onClick={toggleDrawer}
                                        style={{ marginRight: 2 }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </>
                            }>
                        </CardHeader>
                        <CardContent>
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={1} >
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <InputLabel id='serviceStatus-label'>Service Status</InputLabel>
                                            <Select
                                                name='serviceStatus'
                                                label='Service Status'
                                                labelId='serviceStatus-label'
                                                value={formik.values.serviceStatus}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            >
                                                <MenuItem key='1' value='Pending'>Pending</MenuItem>
                                                <MenuItem key='2' value='Completed'>Completed</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                name='notes'
                                                label='Notes'
                                                value={formik.values.notes}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                name='contactedBy'
                                                label='Contacted By'
                                                value={formik.values.contactedBy}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                type='date'
                                                name='contactDate'
                                                label='Contact Date'
                                                value={formik.values.contactDate}
                                                onChange={(e) => formik.setFieldValue('contactDate', e.target.value)}
                                                onBlur={formik.handleBlur}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                name='resolution'
                                                label='Resolution'
                                                value={formik.values.resolution}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                   
                                </Grid>
                                <CardActions className={classes.footer}>
                                    <Button type='submit' disabled={loading} variant='contained' color='primary' size='large' className={classes.largeButton}>
                                        {loading ? (
                                            <>
                                                Please wait...
                                                <CircularProgress size={20} style={{ marginLeft: 8 }} />
                                            </>
                                        ) : (
                                            'Save'
                                        )}
                                    </Button>
                                </CardActions>
                            </form>
                        </CardContent>
                    </Card>
                </div>
            </Drawer >
        </>
    )
};
export default SaveServiceRequest;
