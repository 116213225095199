import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import api from "../../../axios";

const QBOItemService = ({ isOpen, closeQBOModal, qboAction,LoadData,ShowTost }) => {
    const [loading, setLoading] = useState(false);

    const handleStartSync = () => {
        setLoading(true);

        const user = JSON.parse(localStorage.getItem('user'));
        const token = user.token;
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };

        if (qboAction === 'Sync To QuickBooks') {

            const apiUrl = '/api/Inventory/QBOPushItem';
            api.post(apiUrl,null,config)
            .then(response => {
                console.log(response.data);
                setLoading(false);
                ShowTost(response.data);
            })
            .catch(error => {
                console.error('Error:', error);
                setLoading(false);
                ShowTost('Error:', error);
            }).finally(()=>{
                closeQBOModal();
            });

        } else if (qboAction === 'Sync From QuickBooks') {

            const apiUrl = '/api/Inventory/QBOPullItem';
            api.post(apiUrl,null, config)
            .then(response => {
                // Handle successful response
                console.log(response.data);
                setLoading(false);
                ShowTost(response.data);
                LoadData();
            })
            .catch(error => {
                // Handle error
                console.error('Error:', error);
                setLoading(false);
                ShowTost('Error:', error);
            }).finally(()=>{
                closeQBOModal();
            });

        }

        
    };

    return (
        <Dialog open={isOpen} onClose={closeQBOModal}>
            <DialogTitle>{qboAction}</DialogTitle>
            <DialogContent>
                <Button type='button' disabled={loading} variant='contained' size='large' fullWidth margin='normal' onClick={handleStartSync}>
                    {loading ? (
                        <>
                            Please wait...
                            <CircularProgress size={20} style={{ marginLeft: 8 }} />
                        </>
                    ) : (
                        'Start Synchronization'
                    )}
                </Button>
            </DialogContent>
        </Dialog>
    )
};

export default QBOItemService;
