import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({


  loginPage: {
    height: '100vh',
  },
  companyDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    //backgroundColor: theme.palette.primary.main,
    background: `linear-gradient(0deg, ${theme.palette.secondary.dark}, ${theme.palette.primary.main})`,
    color: theme.palette.common.white,
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: 'none', // hide on small screens
    },
  },
  loginFormContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  buttonCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginInline: theme.spacing(2), // Adjust the spacing as needed
  },
  card: {
    maxWidth: 345,
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    backgroundColor: '#f9f9f9',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center', // Use 'center' to horizontally center
    padding: theme.spacing(2),
  },
  largeButton: {
    width: '350px',
    fontSize: theme.typography.fontSize * 1.5, // Adjust the multiplier as needed
  },
  formContainer: {
    width: '100%',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },


}));

export default useStyles;
