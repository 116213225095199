import React, { useState, useEffect } from 'react';
import {
    Drawer, Card, CardHeader, CardContent, CardActions, IconButton,
    TextField, Button, CircularProgress, FormControl, InputLabel,
    Select, MenuItem, Grid, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper,
    Autocomplete,
    Avatar,Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useStyles from '../../formStyle';
import api from '../../../../axios';

const SaveQuotation = ({ isOpen, toggleDrawer, entity, ShowTost }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    //const [items, setItems] = useState([]);
    const [taxes, setTaxes] = useState([]);

    const [cartList, setCartList] = useState([]);

    const [carItem, setCartItem] = useState({
        idField: '',
        itemId: '',
        qty: '',
        rate: '',
        amount: '',
        vatId: '',
        vatRate: '',
        vatAmount: '',
        description: '',
    });

    const [selectVat, setSelectVat] = useState({
        taxId: 0,
        Id: "",
        Name: "",
        salesTaxRate: 0,
        purchaseTaxRate: 0
    });

    const formik = useFormik({
        initialValues: {
            quoteId: entity.quoteId || 0,
            createdDate: entity.createdDate || '',
            modifiedDate: entity.modifiedDate || '',
            createdBy: entity.createdBy || '',
            ClientID: entity.ClientID || '',
            quoteNo: entity.quoteNo || '',
            quoteDate: entity.quoteDate || '',
            expDate: entity.expDate || '',
            customField1: entity.customField1 || '',
            customField2: entity.customField2 || '',
            customField3: entity.customField3 || '',
            notes: entity.notes || '',
            privateNotes: entity.privateNotes || '',
            taxType: 'OutofScope',		//(Inclusive,Exclusive,OutofScope)
            subtotalAmount: entity.subtotalAmount || '',
            vatAmount: entity.vatAmount || '',
            totalAmount: entity.totalAmount || '',
            grandTotal: entity.grandTotal || '0.00',
        },
        validationSchema: Yup.object({
            ClientID: Yup.string().required('Please select Customer'),
        }),
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const apiUrl = '/api/Transaction/SaveQuotation';
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                };

                const payload = {
                    Quotation: values,
                    QuotationItem: cartList,
                };

                //console.log(payload);

                const response = await api.post(apiUrl, payload, config);
                if (response.status === 200) {
                    console.log('created successfully.');
                    ShowTost(response.data);
                    formik.resetForm();
                } else {
                    ShowTost('Something went wrong.');
                    console.error('Something went wrong.');
                }
            } catch (error) {
                ShowTost(`Error: ${error.message}`);
                console.error('Error:', error.message);
            } finally {
                setLoading(false);
                toggleDrawer();
            }
        },
    });

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        console.log(entity);
        formik.setValues({
            quoteId: entity.quoteId || 0,
            createdDate: entity.createdDate || '',
            modifiedDate: entity.modifiedDate || '',
            createdBy: entity.createdBy || '',
            ClientID: entity.ClientID || '',
            quoteNo: entity.quoteNo || '',
            //quoteDate : entity.quoteDate ? new Date(entity.quoteDate).toISOString().split('T')[0] : '',
            //expDate : entity.expDate ? new Date(entity.expDate).toISOString().split('T')[0] : '',

            quoteDate: entity.quoteDate ? formatDate(entity.quoteDate) : '',
            expDate: entity.expDate ? formatDate(entity.expDate) : '',

            customField1: entity.customField1 || '',
            customField2: entity.customField2 || '',
            customField3: entity.customField3 || '',
            notes: entity.notes || '',
            privateNotes: entity.privateNotes || '',
            taxType: entity.taxType || 'OutofScope',		//(Inclusive,Exclusive,OutofScope)
            subtotalAmount: entity.subtotalAmount || '',
            vatAmount: entity.vatAmount || '',
            totalAmount: entity.totalAmount || '',
        });

        loadData();
    }, [entity]);

    const loadData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get('/api/Transaction/GetAllClientAndItemsAndTax', config);
            setItemList(response.data.items || []);
            setCustomerList(response.data.clients || []);
            setTaxes(response.data.taxes || []);
            setCartList([]);
            if (entity.quoteId > 0) {
                const response = await api.get('/api/Transaction/GetAllQuotationItem?quoteId=' + entity.quoteId, config);
                //console.log(response);
                setCartList(response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCartItem({ ...carItem, [name]: value });
    };

    const handleItemChange = (event, value) => {
        console.log(value);
        if (value.itemId) {
            // setCartItem((prevCartItem) => ({
            //     ...prevCartItem,
            //     itemId: value.itemId,
            //     rate: value.UnitPrice,
            //     qty: 1,
            //   }));
            setCartItem({
                ...carItem,
                itemId: value.itemId,
                description: value.Description,
                rate: value.UnitPrice,
                qty: 1,
            });
            //console.log(carItem);
        } else {
            setCartItem({ ...carItem, itemId: '', description: '', rate: '', qty: '' });
        }
    };

    const handleVatChange = (event, value) => {
        setSelectVat(value);
        //console.log(value);
        // if (value.taxId) {
        //     setCartItem({
        //         ...carItem,
        //         vatId: value.taxId,
        //         vatRate: value.salesTaxRate,
        //     });
        // } else {
        //     setCartItem({ ...carItem, itemId: '', rate: '' });
        // }

        // console.log(carItem);
    };

    const handleButtonClick = () => {
        const selectedItem = itemList.find(item => item.itemId === carItem.itemId);
        if (!selectedItem) {
            // Item not found in itemList
            return;
        }

        //console.log(cartList);
        const existingItem = cartList.find(item => item.itemId === carItem.itemId);
        //console.log(existingItem);

        if (existingItem) {
            // Item already exists in cart, update quantity
            const updatedCartList = cartList.map(item => {
                if (item.itemId === carItem.itemId) {
                    if (formik.values.taxType == 'OutofScope') {
                        return {
                            ...item,
                            qty: parseFloat(item.qty) + parseFloat(carItem.qty),
                            vatId: 0,
                            vatRate: 0,
                            vatAmount: 0,
                            description: '',
                        };
                    } else {
                        alert('a');
                        return {
                            ...item,
                            qty: parseFloat(item.qty) + parseFloat(carItem.qty),
                            vatId: selectVat.Id,
                            vatRate: selectVat.salesTaxRate,
                            vatAmount: parseFloat((carItem.rate * carItem.qty * selectVat.salesTaxRate) / 100).toFixed(2),
                            description: carItem.description,
                        };
                    }
                }
                return item;
            });
            setCartList(updatedCartList);
        } else {
            // Item does not exist in cart, add new item

            if (formik.values.taxType == 'OutofScope') {
                const newItem = {
                    itemId: carItem.itemId,
                    rate: parseFloat(carItem.rate),
                    qty: parseFloat(carItem.qty),
                    vatId: 0,
                    vatRate: 0,
                    vatAmount: 0,
                    description: carItem.description,
                };
                setCartList([...cartList, newItem]);
            } else {
                const newItem = {
                    itemId: carItem.itemId,
                    rate: parseFloat(carItem.rate),
                    qty: parseFloat(carItem.qty),
                    vatId: selectVat.Id,
                    vatRate: selectVat.salesTaxRate,
                    vatAmount: parseFloat((carItem.rate * carItem.qty * selectVat.salesTaxRate) / 100).toFixed(2),
                    description: carItem.description,
                };
                setCartList([...cartList, newItem]);
            }

        }

        // Clear carItem state
        setCartItem({
            itemId: '',
            description: '',
            rate: '',
            qty: '',
        });
    };

    const handleDeleteItem = (index) => {
        const newCartList = [...cartList];
        newCartList.splice(index, 1);
        setCartList(newCartList);
    };

    // Calculate total amount and total VAT amount
    useEffect(() => {
        let totalAmount = 0;
        let totalVatAmount = 0;

        cartList.forEach(item => {
            totalAmount += item.rate * item.qty;
            totalVatAmount += parseFloat(item.vatAmount);
        });

        formik.setFieldValue('totalAmount', totalAmount.toFixed(2));
        formik.setFieldValue('vatAmount', totalVatAmount.toFixed(2));
        const grandTotal = parseFloat((totalAmount + totalVatAmount).toFixed(2));
        formik.setFieldValue('grandTotal', grandTotal.toFixed(2));

    }, [cartList]);

    return (
        <Drawer
            anchor='right'
            open={isOpen}
            onClose={toggleDrawer}
            variant='temporary'
            ModalProps={{
                keepMounted: true,
            }}
            PaperProps={{
                sx: {
                    width: '100%',
                    // maxWidth: '550px', // Adjust width as needed
                },
            }}
        >
            <div style={{ height: '100vh', overflow: 'auto' }}>
                <Card>
                    <CardHeader
                        title='Save Quotation'
                        className={classes.header}
                        action={
                            <IconButton
                                edge='end'
                                color='inherit'
                                aria-label='close'
                                onClick={toggleDrawer}
                                style={{ marginRight: 2 }}
                            >
                                <CloseIcon />
                            </IconButton>
                        }
                    />
                    <CardContent>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth margin='normal'>
                                        <Autocomplete
                                            id="ClientID"
                                            options={customerList}
                                            //getOptionLabel={(option) => `${option.Title} ${option.GivenName} ${option.MiddleName} ${option.FamilyName} - ${option.CompanyName}`}
                                            getOptionLabel={(option) => `${option.Title ?? ''} ${option.GivenName ?? ''} ${option.MiddleName ?? ''} ${option.FamilyName ?? ''} - ${option.CompanyName ?? ''}`.replace(/\s+/g, ' ').trim()}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Customer"
                                                    variant="outlined"
                                                    name="ClientID"
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.ClientID && Boolean(formik.errors.ClientID)}
                                                    helperText={formik.touched.ClientID && formik.errors.ClientID}
                                                />
                                            )}
                                            onChange={(event, value) => formik.setFieldValue('ClientID', value ? value.clientId : '')}
                                            value={customerList.find(client => client.clientId == formik.values.ClientID) || null}
                                        />

                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <FormControl fullWidth margin='normal'>
                                        <TextField
                                            name='quoteNo'
                                            label='Quote No'
                                            value={formik.values.quoteNo}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.quoteNo && Boolean(formik.errors.quoteNo)}
                                            helperText={formik.touched.quoteNo && formik.errors.quoteNo}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <FormControl fullWidth margin='normal'>
                                        <TextField
                                            type='date'
                                            name='quoteDate'
                                            label='Quote Date'
                                            value={formik.values.quoteDate}
                                            onChange={(e) => formik.setFieldValue('quoteDate', e.target.value)}
                                            onBlur={formik.handleBlur}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <FormControl fullWidth margin='normal'>
                                        <TextField
                                            type='date'
                                            name='expDate'
                                            label='Expiration Date'
                                            value={formik.values.expDate}
                                            onChange={(e) => formik.setFieldValue('expDate', e.target.value)}
                                            onBlur={formik.handleBlur}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={2}>
                                    <TextField
                                        name="customField1"
                                        label="Contract Name"
                                        value={formik.values.customField1}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        //value={carItem.customField1}
                                        //onChange={handleChange}
                                        fullWidth
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <TextField
                                        name="customField2"
                                        label="Code/Ref"
                                        value={formik.values.customField2}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        //value={carItem.customField2}
                                        //onChange={handleChange}
                                        fullWidth
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <TextField
                                        name="customField3"
                                        label="REQ/WO/Job No"
                                        value={formik.values.customField3}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        //value={carItem.customField3}
                                        //onChange={handleChange}
                                        fullWidth
                                        autoComplete="off"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} style={{ marginTop: 20 }}>
                                <Grid item xs={12} sm={3}>
                                    <Autocomplete
                                        id="item"
                                        options={itemList}
                                        getOptionLabel={(option) => option.Name}
                                        renderOption={(props, option) => (
                                            <li {...props}>
                                                <Avatar alt={option.Name} src={process.env.REACT_APP_BASE_URL + option.itemImg} style={{ marginRight: 10 }} />
                                                {option.Name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Item"
                                                variant="outlined"
                                                name="item"
                                            />
                                        )}
                                        onChange={handleItemChange}
                                        value={itemList.find(item => item.itemId === carItem.itemId) || null}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <TextField
                                        name="description"
                                        label="Description"
                                        value={carItem.description}
                                        onChange={handleChange}
                                        fullWidth
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <TextField
                                        name="rate"
                                        label="Price"
                                        type="number"
                                        value={carItem.rate}
                                        onChange={handleChange}
                                        fullWidth
                                        autoComplete="off"
                                        inputProps={{
                                            step: "0.01", // Allows two decimal places
                                            min: "0",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <TextField
                                        name="qty"
                                        label="Quantity"
                                        type="number"
                                        value={carItem.qty}
                                        onChange={handleChange}
                                        fullWidth
                                        autoComplete="off"
                                        inputProps={{
                                            step: "0.01", // Allows two decimal places
                                            min: "0",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Autocomplete
                                        name='vatId'
                                        options={taxes}
                                        getOptionLabel={(option) => `${option.Name} - ${option.salesTaxRate}%`}
                                        value={taxes.find((option) => option.taxId === carItem.vatId) || null}
                                        onChange={handleVatChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label='VAT'
                                                variant='outlined'
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={1}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleButtonClick}
                                        fullWidth
                                        style={{ height: 50 }}
                                    >
                                        Add Item
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="taxType-label">Amounts are</InputLabel>
                                    <Select
                                        name="taxType"
                                        label="Amounts are"
                                        labelId="taxType-label"
                                        value={formik.values.taxType}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                    >
                                        <MenuItem key='Inclusive' value='Inclusive'>
                                            Inclusive of Tax
                                        </MenuItem>
                                        <MenuItem key='Exclusive' value='Exclusive'>
                                            Exclusive of Tax
                                        </MenuItem>
                                        <MenuItem key='OutofScope' value='OutofScope'>
                                            Out of Scope
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <TableContainer component={Paper} style={{ marginTop: 20 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>Item</strong></TableCell>
                                            <TableCell><strong>Description</strong></TableCell>
                                            <TableCell><strong>Price</strong></TableCell>
                                            <TableCell><strong>Quantity</strong></TableCell>
                                            <TableCell><strong>VAT Amount</strong></TableCell>
                                            <TableCell style={{ textAlign: 'right' }}><strong>Actions</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {cartList.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{itemList.find(item => item.itemId == row.itemId)?.Name}</TableCell>
                                                <TableCell>{row.description}</TableCell>
                                                <TableCell>{row.rate}</TableCell>
                                                <TableCell>{row.qty}</TableCell>
                                                <TableCell>{row.vatAmount}</TableCell>
                                                <TableCell style={{ textAlign: 'right' }}>
                                                    <IconButton
                                                        edge='end'
                                                        color='inherit'
                                                        aria-label='delete'
                                                        onClick={() => handleDeleteItem(index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid container spacing={2} style={{ marginTop: 20 }}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth margin='normal'>
                                        <TextField
                                            name='notes'
                                            label='Message displayed on quote'
                                            minRows={3}
                                            maxRows={4}
                                            multiline
                                            value={formik.values.notes}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth margin='normal'>
                                        <TextField
                                            name='privateNotes'
                                            label='Message displayed on statement'
                                            minRows={3}
                                            maxRows={4}
                                            multiline
                                            value={formik.values.privateNotes}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth margin='normal'>
                                        <TextField
                                            name='totalAmount'
                                            label='Total Amount'
                                            value={formik.values.totalAmount}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.totalAmount && Boolean(formik.errors.totalAmount)}
                                            helperText={formik.touched.totalAmount && formik.errors.totalAmount}
                                            disabled
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin='normal'>
                                        <TextField
                                            name='vatAmount'
                                            label='Total VAT Amount'
                                            readOnly
                                            value={formik.values.vatAmount}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.vatAmount && Boolean(formik.errors.vatAmount)}
                                            helperText={formik.touched.vatAmount && formik.errors.vatAmount}
                                            disabled
                                        />
                                    </FormControl>
                                    {/* <FormControl fullWidth margin='normal'>
                                        <Typography variant="body1">
                                            <strong>Grand Total:</strong> {formik.values.grandTotal}
                                        </Typography>
                                    </FormControl> */}
                                    <FormControl fullWidth margin='normal'>
                                        <TextField
                                            name='grandTotal'
                                            label='Grand Total'
                                            readOnly
                                            value={formik.values.grandTotal}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.grandTotal && Boolean(formik.errors.grandTotal)}
                                            helperText={formik.touched.grandTotal && formik.errors.grandTotal}
                                            disabled
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <CardActions className={classes.footer}>
                                <Button
                                    type='submit'
                                    disabled={loading}
                                    variant='contained'
                                    color='primary'
                                    size='large'
                                    className={classes.largeButton}
                                >
                                    {loading ? (
                                        <>
                                            Please wait...
                                            <CircularProgress size={20} style={{ marginLeft: 8 }} />
                                        </>
                                    ) : (
                                        'Save'
                                    )}
                                </Button>
                            </CardActions>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </Drawer>
    );
};

export default SaveQuotation;
