import React from 'react';
import { Snackbar, SnackbarContent, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.success.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  closeButton: {
    color: theme.palette.common.white,
  },
}));

const ReusableSnackbar = ({ open, onClose, message, type = 'success', duration = 6000 }) => {
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  return (
    <Snackbar open={open} autoHideDuration={duration} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <SnackbarContent
        className={classes[type]}
        message={<span className={classes.message}>{message}</span>}
        action={[
          <IconButton key="close" aria-label="close" onClick={handleClose}>
            <CloseIcon className={classes.closeButton} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default ReusableSnackbar;
