import React from "react";
import useStyles from "../admin/formStyle";
import { Typography, Grid, Card, CardHeader, CardContent, CardActions, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";

export default function TechnicianDashboard() {
    const classes = useStyles();
    const navigate = useNavigate();
    return (
        <Card>
            <CardHeader title='Dashboard' className={classes.header} />
            <CardContent>
                <Grid container spacing={2}>
                    {/* Tile 1 */}
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent style={{ background: '#3E4095', color: 'white' }}>
                                <Typography variant="h5" component="div">
                                    Site Surveys
                                </Typography>
                                <Typography variant="body2">
                                    View all site surveys.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button onClick={() => navigate(`/technician/survey`)} size="small">Explore</Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    {/* Tile 2 */}
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent style={{ background: '#3E4095', color: 'white' }}>
                                <Typography variant="h5" component="div">
                                    Site Surveys Completed
                                </Typography>
                                <Typography variant="body2">
                                    View all completed site surveys.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button onClick={() => navigate(`/technician/surveycompleted`)} size="small">Explore</Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    {/* Tile 3 */}
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent style={{ background: '#3E4095', color: 'white' }}>
                                <Typography variant="h5" component="div">
                                    Profile
                                </Typography>
                                <Typography variant="body2">
                                    View your profile.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button onClick={() => navigate(`/technician/profile`)} size="small">Explore</Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    {/* Tile 4 */}
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent style={{ background: '#3E4095', color: 'white' }}>
                                <Typography variant="h5" component="div">
                                    Job Cards
                                </Typography>
                                <Typography variant="body2">
                                    View all Job Cards.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button onClick={() => navigate(`/technician/jobs`)} size="small">Explore</Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    {/* Tile 5 */}
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent style={{ background: '#3E4095', color: 'white' }}>
                                <Typography variant="h5" component="div">
                                    Job Cards Completed
                                </Typography>
                                <Typography variant="body2">
                                    View all completed Job Cards.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button onClick={() => navigate(`/technician/jobscompleted`)} size="small">Explore</Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    {/* Tile 6 */}
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent style={{ background: '#3E4095', color: 'white' }}>
                                <Typography variant="h5" component="div">
                                    Change Password
                                </Typography>
                                <Typography variant="body2">
                                    Change your password.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button onClick={() => navigate(`/technician/changepassword`)} size="small">Explore</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}