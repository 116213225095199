import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({

    card: {
        maxWidth: 345,
        margin: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        backgroundColor: '#f9f9f9',
    },
    footer: {
        display: 'flex',
        justifyContent: 'center', // Use 'center' to horizontally center
        padding: theme.spacing(2),
    },
    largeButton: {
        width: '350px',
        fontSize: theme.typography.fontSize * 1.5, // Adjust the multiplier as needed
    },
    formContainer: {
        width: '100%',
        padding: theme.spacing(2),
        boxSizing: 'border-box',
    },
    formControl: {
        marginBottom: theme.spacing(2),
    },
    tabsContainer: {
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto', // Enable horizontal scrolling
    },
    verticalScrollContainer: {
        flexDirection: 'column', // Change direction to vertical for small devices
        height: '100%', // Set height to make tabs vertically scrollable on small devices
        overflowY: 'auto', // Enable vertical scrolling
    },
}))

export default useStyles;