import React from "react";

const Reports = () => {
  return (
    <div>
      <h1>Not Implemented</h1>
    </div>
  )
};

export default Reports;
