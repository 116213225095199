import React, { useState, useEffect } from 'react';
import { Drawer, Card, CardHeader, CardContent, CardActions, IconButton, TextField, Button, CircularProgress, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Grid, Paper } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../../axios';
import useStyles from '../../admin/formStyle';
import CloseIcon from '@mui/icons-material/Close';

const SaveSiteSurvey = ({ isOpen, toggleDrawer, entity, LoadData, ShowTost }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            id: entity.id || '',
            createdDate: entity.createdDate || '',
            modifiedDate: entity.modifiedDate || '',
            createdId: entity.createdId || '',
            clientId: entity.clientId || 0,
            surveyRef: entity.surveyRef || '',
            building: entity.building || '',
            surveyDateTime: entity.surveyDateTime || '',
            siteContactPerson: entity.siteContactPerson || '',
            notes: entity.notes || '',
            surveyStatus: entity.surveyStatus || '',
            techCompleteStatus: entity.techCompleteStatus || '',
            techCompleteDate: entity.techCompleteDate || '',
            invCreatedDate: entity.invCreatedDate || '',
            invNo: entity.invNo || '',
            invAmount: entity.invAmount || '',
            quotCreatedDate: entity.quotCreatedDate || '',
            quotNo: entity.quotNo || '',
            quotAmount: entity.quotAmount || '',
        },
        validationSchema: Yup.object({
            clientId: Yup.string().required('Please select Client'),
            building: Yup.string().required('Please enter Building'),
            surveyDateTime: Yup.string().required('Please enter Survey Date Time'),
            siteContactPerson: Yup.string().required('Please enter Site Contact Person'),
            surveyStatus: Yup.string().required('Please select survey status'),
        }),
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const apiUrl = '/api/Technician/SaveSiteSurvey';
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const data = new FormData();
                //console.log(values);
                data.append('id', values.id);

                data.append('clientId', values.clientId);
                data.append('surveyRef', values.surveyRef);
                data.append('building', values.building);
                data.append('surveyDateTime', values.surveyDateTime);
                data.append('siteContactPerson', values.siteContactPerson);
                data.append('notes', values.notes);
                data.append('surveyStatus', values.surveyStatus);
                data.append('techCompleteStatus', values.techCompleteStatus);
                data.append('techCompleteDate', values.techCompleteDate);
                data.append('invCreatedDate', values.invCreatedDate);
                data.append('invNo', values.invNo);
                data.append('invAmount', values.invAmount);
                data.append('quotCreatedDate', values.quotCreatedDate);
                data.append('quotNo', values.quotNo);
                data.append('quotAmount', values.quotAmount);
                const response = await api.post(apiUrl, data, config);
                if (response.status === 200) {
                    console.log('created successfully.');
                    ShowTost("Saved successfully");
                    formik.resetForm();
                } else {
                    ShowTost('Something went wrong.');
                    console.error('Something went wrong.');
                }
            } catch (error) {
                ShowTost('Error:', error.message);
                console.error('Error:', error.message);
            } finally {
                setLoading(false);
                toggleDrawer();
                LoadData();
            }
        },
    });

    useEffect(() => {
        formik.setValues({
            id: entity.id || '',
            createdDate: entity.createdDate || '',
            modifiedDate: entity.modifiedDate || '',
            createdId: entity.createdId || '',
            clientId: entity.clientId || 0,
            surveyRef: entity.surveyRef || '',
            building: entity.building || '',
            surveyDateTime: entity.surveyDateTime || '',
            siteContactPerson: entity.siteContactPerson || '',
            notes: entity.notes || '',
            surveyStatus: entity.surveyStatus || '',
            techCompleteStatus: entity.techCompleteStatus || '',
            techCompleteDate: entity.techCompleteDate || '',
            invCreatedDate: entity.invCreatedDate || '',
            invNo: entity.invNo || '',
            invAmount: entity.invAmount || '',
            quotCreatedDate: entity.quotCreatedDate || '',
            quotNo: entity.quotNo || '',
            quotAmount: entity.quotAmount || '',
        });
    }, [entity]);

    const [clients, setclients] = useState([]);
    useEffect(() => {
        const apiUrl = '/api/Technician/GetAllClient';
        const user = JSON.parse(localStorage.getItem('user'));
        const token = user.token;
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        api.get(apiUrl, config)
            .then(response => {
                setclients(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);


    return (
        <>
            <Drawer anchor='right'
                open={isOpen}
                onClose={toggleDrawer}
                variant='temporary'
                ModalProps={{
                    keepMounted: true,
                }}
                PaperProps={{
                    sx: {
                        width: '100%',
                        maxWidth: '550px', // Adjust width as needed
                    },
                }} >
                <div style={{ height: '100vh', overflow: 'auto' }}>
                    <Card>
                        <CardHeader title='Save Site Survey' className={classes.header}
                            action={
                                <>
                                    <IconButton
                                        edge='end'
                                        color='inherit'
                                        aria-label='close'
                                        onClick={toggleDrawer}
                                        style={{ marginRight: 2 }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </>
                            }>
                        </CardHeader>
                        <CardContent>
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={1} >

                                    <Grid item xs={12} sm={12}>
                                        <FormControl fullWidth margin='normal'>
                                            <InputLabel id='clientId-label'>Client Id</InputLabel>
                                            <Select
                                                name='clientId'
                                                label='Client'
                                                labelId='clientId-label'
                                                value={formik.values.clientId}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.clientId && Boolean(formik.errors.clientId)}
                                                helpertext={formik.touched.clientId && formik.errors.clientId}
                                            >
                                                {clients.map(data => (
                                                    <MenuItem key={data.clientId} value={data.clientId}>
                                                        {data.Title} {data.GivenName} {data.FamilyName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                name='building'
                                                label='Building'
                                                value={formik.values.building}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.building && Boolean(formik.errors.building)}
                                                helpertext={formik.touched.building && formik.errors.building}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                type='datetime-local'
                                                name='surveyDateTime'
                                                label='Survey Date Time'
                                                value={formik.values.surveyDateTime}
                                                onChange={(e) => formik.setFieldValue('surveyDateTime', e.target.value)}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.surveyDateTime && Boolean(formik.errors.surveyDateTime)}
                                                helpertext={formik.touched.surveyDateTime && formik.errors.surveyDateTime}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                name='siteContactPerson'
                                                label='Site Contact Person'
                                                value={formik.values.siteContactPerson}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.siteContactPerson && Boolean(formik.errors.siteContactPerson)}
                                                helpertext={formik.touched.siteContactPerson && formik.errors.siteContactPerson}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <InputLabel id='surveyStatus-label'>Survey Status</InputLabel>
                                            <Select
                                                name='surveyStatus'
                                                label='Survey Status'
                                                labelId='surveyStatus-label'
                                                value={formik.values.surveyStatus}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.surveyStatus && Boolean(formik.errors.surveyStatus)}
                                                helpertext={formik.touched.surveyStatus && formik.errors.surveyStatus}
                                            >
                                                <MenuItem value='Pending'>Pending</MenuItem>
                                                <MenuItem value='Completed'>Completed</MenuItem>
                                                <MenuItem value='Cancelled'>Cancelled</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                name='notes'
                                                label='Notes'
                                                minRows={3}
                                                maxRows={4}
                                                multiline
                                                value={formik.values.notes}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>

                                </Grid>
                                <CardActions className={classes.footer}>
                                    <Button type='submit' disabled={loading} variant='contained' color='primary' size='large' className={classes.largeButton}>
                                        {loading ? (
                                            <>
                                                Please wait...
                                                <CircularProgress size={20} style={{ marginLeft: 8 }} />
                                            </>
                                        ) : (
                                            'Save'
                                        )}
                                    </Button>
                                </CardActions>
                            </form>
                        </CardContent>
                    </Card>
                </div>
            </Drawer >
        </>
    )
};
export default SaveSiteSurvey;
