import React, { useState, useEffect } from 'react';
import { Drawer, Card, CardHeader, CardContent, CardActions, IconButton, TextField, Button, CircularProgress, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Grid, Paper } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../../axios';
import useStyles from '../formStyle';
import CloseIcon from '@mui/icons-material/Close';

const SaveGroups = ({ isOpen, toggleDrawer, entity,LoadData, ShowTost }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            groupId: entity.groupId || 0,
            name: entity.name || '',
            notes: entity.notes || '',
            active: entity.active || true,
            colorCode:entity.colorCode||'#3E4095',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter name'),
            colorCode:Yup.string().required('Please select color'),
        }),
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const apiUrl = '/api/Masters/SaveGroups';
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const data = new FormData();
                data.append('groupId', values.groupId);
                data.append('name', values.name);
                data.append('notes', values.notes);
                data.append('active', values.active);
                data.append('colorCode',values.colorCode);

                const response = await api.post(apiUrl, data, config);
                if (response.status === 200) {
                    console.log('created successfully.');
                    ShowTost(response.data);
                    formik.resetForm();
                } else {
                    ShowTost('Something went wrong.');
                    console.error('Something went wrong.');
                }
            } catch (error) {
                ShowTost('Error:', error.message);
                console.error('Error:', error.message);
            } finally {
                setLoading(false);
                toggleDrawer();
                LoadData();
            }
        },
    });
    useEffect(() => {
        formik.setValues({
            groupId: entity.groupId || 0,
            name: entity.name || '',
            notes: entity.notes || '',
            active: entity.active || 0,
            colorCode:entity.colorCode||'#3E4095',
        });
    }, [entity]);

    return (
        <>
            <Drawer anchor='right'
                open={isOpen}
                onClose={toggleDrawer}
                variant='temporary'
                ModalProps={{
                    keepMounted: true,
                }}
                PaperProps={{
                    sx: {
                        width: '100%',
                        maxWidth: '450px', // Adjust width as needed
                    },
                }} >
                <div style={{ height: '100vh', overflow: 'auto' }}>
                    <Card>
                        <CardHeader title="Save Group" className={classes.header}
                            action={
                                <>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        aria-label="close"
                                        onClick={toggleDrawer}
                                        style={{ marginRight: 2 }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </>
                            }>
                        </CardHeader>
                        <CardContent>
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={1} >
                                    <Grid item xs={12} sm={12}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                label='name'
                                                name='name'
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.name && Boolean(formik.errors.name)}
                                                helperText={formik.touched.name && formik.errors.name}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                label='notes'
                                                name='notes'
                                                value={formik.values.notes}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                label='color'
                                                name='colorCode'
                                                type='color'
                                                value={formik.values.colorCode}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.colorCode && Boolean(formik.errors.colorCode)}
                                                helperText={formik.touched.colorCode && formik.errors.colorCode}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FormControl fullWidth margin='normal'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name='active'
                                                        checked={formik.values.active}
                                                        onChange={(e) => formik.setFieldValue('active', e.target.checked)}
                                                    />
                                                }
                                                label='Active'
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <CardActions className={classes.footer}>

                                    <Button type="submit" disabled={loading} variant="contained" color="primary" size="large" className={classes.largeButton}>
                                        {loading ? (
                                            <>
                                                Please wait...
                                                <CircularProgress size={20} style={{ marginLeft: 8 }} />
                                            </>
                                        ) : (
                                            'Save'
                                        )}
                                    </Button>
                                </CardActions>
                            </form>
                        </CardContent>
                    </Card>
                </div>
            </Drawer >
        </>
    )
};
export default SaveGroups;
