import React, { useState, useEffect } from 'react';
import { Grid, FormControl, TextField, Button, CircularProgress, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, IconButton, Skeleton, Tooltip } from '@mui/material';
import { Edit as EditIcon, Close as DeleteIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../../axios';


const SurveyLocation = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [entity, setEntity] = useState({
        id: 0,
        surveyId: props.id,
        floor: '',
        department: '',
        notes: '',
    });

    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get(`/api/Technician/GetAllSiteSurveyLocation?surveyId=${props.id}`, config);
            setData(response.data || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const formik = useFormik({
        initialValues: {
            id: entity.id || '',
            surveyId: props.id,
            floor: entity.floor || '',
            department: entity.department || '',
            notes: entity.notes || '',
        },
        validationSchema: Yup.object({
            floor: Yup.string().required('Please enter Floor'),
            department: Yup.string().required('Please enter Department'),
        }),
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const apiUrl = '/api/Technician/SaveSiteSurveyLocation';
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const data = new FormData();
                data.append('id', values.id);
                data.append('surveyId', values.surveyId);
                data.append('floor', values.floor);
                data.append('department', values.department);
                data.append('notes', values.notes);
                const response = await api.post(apiUrl, data, config);
                if (response.status === 200) {
                    fetchData();
                    console.log('created successfully.');
                    formik.resetForm();
                } else {
                    console.error('Something went wrong.');
                }
            } catch (error) {
                console.error('Error:', error.message);
            } finally {
                setLoading(false);
            }
        },
    });


    const handleEdit = (editData) => {
        formik.setValues({
            id: editData.id,
            surveyId: props.id,
            floor: editData.floor,
            department: editData.department,
            notes: editData.notes
        });
    }

    // const handleDelete = async (id) => {
    //     const confirmation = window.confirm('Are you sure you want to delete?');
    //     if (confirmation) {
    //         try {
    //             const user = JSON.parse(localStorage.getItem('user'));
    //             const token = user.token;
    //             const config = {
    //                 headers: {
    //                     'Authorization': `Bearer ${token}`,
    //                 },
    //             };
    //             const response = await api.post(`/api/Survey/DeleteSiteSurveyLocation?id=${id}`, null, config);
    //             if (response.status === 200) {
    //                 fetchData();
    //             }
    //         } catch (error) {
    //             console.error('Error deleting data:', error);
    //         }
    //     }
    // };


    return (
        <>

            {props.pending ? (

                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={1} >
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth margin='normal'>
                                <TextField
                                    name='floor'
                                    label='Floor'
                                    value={formik.values.floor}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.floor && Boolean(formik.errors.floor)}
                                    helperText={formik.touched.floor && formik.errors.floor}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth margin='normal'>
                                <TextField
                                    name='department'
                                    label='Department'
                                    value={formik.values.department}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.department && Boolean(formik.errors.department)}
                                    helperText={formik.touched.department && formik.errors.department}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth margin='normal'>
                                <TextField
                                    name='notes'
                                    label='Notes'
                                    value={formik.values.notes}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button
                                type='submit'
                                disabled={loading}
                                variant='contained'
                                color='primary'
                                size='large'

                                style={{ marginTop: 20 }}
                            >
                                {loading ? (
                                    <>
                                        Please wait...
                                        <CircularProgress size={20} style={{ marginLeft: 8 }} />
                                    </>
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </form>

            ) : (
                <span></span>
            )}


            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Floor</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Department</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Notes</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Skeleton animation='wave' />
                                </TableCell>
                            </TableRow>
                        ) : (
                            data.map((location, index) => (
                                <TableRow key={index}>
                                    <TableCell>{location.floor}</TableCell>
                                    <TableCell>{location.department}</TableCell>
                                    <TableCell>{location.notes}</TableCell>
                                    <TableCell>
                                        {props.pending ? (
                                            <>
                                                <Tooltip title='Edit'>
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => handleEdit(location)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                {/* <Tooltip title='Delete'>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => handleDelete(location.id)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip> */}
                                            </>
                                        ) : (
                                            <span></span>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default SurveyLocation;
