import React, { useState, useEffect } from 'react';
import { Typography, List, ListItem, ListItemText, Divider, ListItemIcon, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import {
    Dashboard as DashboardIcon,
    Key as MastersIcon,
    Warehouse as InventoryIcon,
    PointOfSale as TransactionsIcon,
    Person4 as ClientIcon,
    Factory as SupplierIcon,
    Group as UsersIcon,
    Assignment as SurveyIcon,
    BarChart as ReportIcon,
    SettingsApplications as SettingIcon,

    LocalFireDepartment as NestedIcon,
    //---Masters---

    //---Masters---
    //---Inventory---
    VpnKey as OptionMasterIcon,
    
    AccountTree as AccountIcon,
    Calculate as TaxIcon,
    Category as CategoryIcon,
    ShoppingCart as ItemServiceIcon,
    //---Inventory---
    //---Client---
    Person4 as ManageClientIcon,
    SupportAgent as ServiceRequestIcon,
    //---Client---
    //---Supplier---
    Factory as ManageSupplierIcon,
    Widgets as SupplierItemIcon,
    //---Supplier---
    //---Users---
    AccountCircle as ManageUserIcon,
    VerifiedUser as RolesIcon,
    SupportAgent as UserRequestIcon,
    //---Users---
    //---Survey---
    NoteAlt as ManageSurveyIcon,
    Pageview as ViewSurveyIcon,
    //---Survey---
    //---Jobs-----
    Engineering as JobsIcon,
    Construction as ManageJobs,
    //---Jobs-----
    //---Settings---
    Business as CompanyIcon,
    VpnKey as ChangePasswordIcon,
    Api as QuickBooksIcon,
    Notifications as PushNotificationIcon,
    SelectAll as PlanMarkerIcon,
    Error as ErrorLogIcon,
    WorkHistory as ActivityLogIcon,
    CoPresent as WhosOnlineIcon,
    //---Settings---

    //---Transaction---
    CallReceived as ReceiveGoodsIcon,
    CallMade as ReturnGoodsIcon,
    ShoppingCartCheckout as StockIssueIcon,
    ProductionQuantityLimits as StockReturnIcon,
    RemoveShoppingCart as StockScrapIcon,
    RequestQuote as QuotationIcon,
    Receipt as InvoiceIcon,
    //---Transaction---


} from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';


const SidebarAdmin = ({ handleDrawerClose }) => {
    const [openIndex, setOpenIndex] = useState(() => {
        // Load openIndex state from local storage or default to -1 (none open)
        return JSON.parse(localStorage.getItem('openIndex')) || -1;
    });

    const [itemsList, setItemsList] = useState([
        { title: 'Dashboard', hasNested: false, isAuth: true, icon: <DashboardIcon style={{ color: '#2b5797' }} />, path: '/admin' },
        { title: 'Masters', hasNested: true, isAuth: true, icon: <MastersIcon style={{ color: '#ffc40d' }} />, nestedItems: [{ title: 'Options Master', path: '/admin/groups', icon: <OptionMasterIcon style={{ color: '#ffc40d' }} /> },] },
        { title: 'Inventory', hasNested: true, isAuth: true, icon: <InventoryIcon style={{ color: '#00aba9' }} />, nestedItems: [{ title: 'Accounts', path: '/admin/accounts', icon: <AccountIcon style={{ color: '#00aba9'}} /> }, { title: 'Tax', path: '/admin/tax', icon: <TaxIcon style={{ color: '#00aba9'}} /> }, { title: 'Category', path: '/admin/category', icon: <CategoryIcon style={{ color: '#00aba9'}} /> }, { title: 'Items & Services', path: '/admin/itemsservices', icon: <ItemServiceIcon style={{ color: '#00aba9'}} /> }] },
        { title: 'Transactions', hasNested: true, isAuth: true, icon: <TransactionsIcon style={{ color: '#ff0097' }} />, nestedItems: [{ title: 'Receive Goods', path: '/admin/purchase', icon: <ReceiveGoodsIcon style={{ color: '#ff0097' }} /> }, { title: 'Return Goods', path: '/admin/purchasereturn', icon: <ReturnGoodsIcon style={{ color: '#ff0097' }} /> }, { title: 'Stock Issue', path: '/admin/sales', icon: <StockIssueIcon style={{ color: '#ff0097' }} /> }, { title: 'Stock Return', path: '/admin/salesreturn', icon: <StockReturnIcon style={{ color: '#ff0097' }} /> }, { title: 'Stock Scrap', path: '/admin/stockscrap', icon: <StockScrapIcon style={{ color: '#ff0097' }} /> }, { title: 'Quotation', path: '/admin/quotation', icon: <QuotationIcon style={{ color: '#ff0097' }} /> }, { title: 'Invoice', path: '/admin/invoice', icon: <InvoiceIcon style={{ color: '#ff0097' }} /> }] },
        { title: 'Client', hasNested: true, isAuth: true, icon: <ClientIcon style={{ color: '#603cba' }} />, nestedItems: [{ title: 'Manage Client', path: '/admin/manageclient', icon: <ManageClientIcon style={{ color: '#603cba' }} /> }, { title: 'Service Request', path: '/admin/servicerequest', icon: <ServiceRequestIcon style={{ color: '#603cba' }} /> }] },
        { title: 'Suppliers', hasNested: true, isAuth: true, icon: <SupplierIcon style={{ color: '#da532c' }} />, nestedItems: [{ title: 'Manage Supplier', path: '/admin/managesupplier', icon: <ManageSupplierIcon style={{ color: '#da532c' }} /> }, { title: 'Supplier Item', path: '/admin/supplieritems', icon: <SupplierItemIcon style={{ color: '#da532c' }} /> }] },
        { title: 'Users', hasNested: true, isAuth: true, icon: <UsersIcon style={{ color: '#00a300' }} />, nestedItems: [{ title: 'Manage Users', path: '/admin/manageusers', icon: <ManageUserIcon style={{ color: '#00a300' }} /> }, { title: 'Roles', path: '/admin/roles', icon: <RolesIcon style={{ color: '#00a300' }} /> }, { title: 'User Request', path: '/admin/userrequest', icon: <UserRequestIcon style={{ color: '#00a300' }}/> }] },
        { title: 'Survey', hasNested: true, isAuth: true, icon: <SurveyIcon style={{ color: '#9f00a7' }} />, nestedItems: [{ title: 'Manage Survey', path: '/admin/sitesurvey', icon: <ManageSurveyIcon style={{ color: '#9f00a7' }} /> },] },
        { title: 'Jobs', hasNested: true, isAuth: true, icon: <JobsIcon style={{ color: '#00aba9' }} />, nestedItems: [{ title: 'Manage Jobs', path: '/admin/jobs', icon: <ManageJobs style={{ color: '#00aba9' }} /> },] },
        { title: 'Reports', hasNested: true, isAuth: true, icon: <ReportIcon style={{ color: '#2d89ef' }} />, path: '/admin' },
        { title: 'Settings', hasNested: true, isAuth: true, icon: <SettingIcon style={{ color: '#da532c' }} />, nestedItems: [{ title: 'Company', path: '/admin/company', icon: <CompanyIcon style={{ color: '#da532c' }} /> }, { title: 'Change Password', path: '/admin/changepassword', icon: <ChangePasswordIcon style={{ color: '#da532c' }} /> }, { title: 'QuickBooks API Settings', path: '/admin/quickbooks', icon: <QuickBooksIcon style={{ color: '#da532c' }} /> }, { title: 'Push Notification', path: '/admin/pushnotification', icon: <PushNotificationIcon style={{ color: '#da532c' }} /> }, { title: 'Plan Markers', path: '/admin/planmarkers', icon: <PlanMarkerIcon style={{ color: '#da532c' }} /> }, { title: 'Error Log', path: '/admin/errorlog', icon: <ErrorLogIcon style={{ color: '#da532c' }} /> }, { title: 'Activity Log', path: '/admin/activitylog', icon: <ActivityLogIcon style={{ color: '#da532c' }} /> }, { title: 'Whos Online', path: '/admin/whosonline', icon: <WhosOnlineIcon style={{ color: '#da532c' }} /> }] },
    ]);

    const navigate = useNavigate();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('user'));
        if (token != null) {

            const modules = JSON.parse(token.module.modules);
            const updatedItemsList = itemsList.map(item => {
                // Find the corresponding module in the parsed JSON

                const module = modules.find(mod => mod.moduleName === item.title);
                // If the module exists and isAuth is true, update the item's isAuth property
                if (module) {
                    return { ...item, isAuth: module.isAuth };
                } else {
                    return item;
                }
            });
            setItemsList(updatedItemsList);
        }
    }, [openIndex]);

    const handleClick = (index, path) => {
        //alert(path);
        setOpenIndex((prevOpenIndex) => {
            // Toggle the open index
            const newIndex = prevOpenIndex === index ? -1 : index;

            // Save openIndex state to local storage

            //alert(path);
            if (path === undefined || path === null) {
                localStorage.setItem('openIndex', JSON.stringify(newIndex));
            }
            return newIndex;
        });

        // Navigate to the specified path
        navigate(path);
    };

    const handleNestedItemClick = () => {
        handleDrawerClose();
    };

    const handleNonNestedClick = (hasNested) => {
        if (!hasNested) {
            localStorage.setItem('openIndex', JSON.stringify(openIndex));
            handleDrawerClose();
        }
    };

    const openUrlInNewTab = () => {
        window.open('https://www.i-future.co.za/', '_blank');
    };

    return (
        <List style={{ marginBottom: '60px' }}>
            {itemsList.filter(item => item.isAuth).map((item, index) => (
                <React.Fragment key={index}>
                    <ListItem button onClick={() => { handleClick(index, item.path); handleNonNestedClick(item.hasNested) }}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={<Typography variant="body1" style={{fontWeight:'bold'}}>{item.title}</Typography>} />
                        {item.nestedItems && item.nestedItems.length > 0 && (openIndex === index ? <ExpandLess /> : <ExpandMore />)}
                    </ListItem>
                    {item.nestedItems && item.nestedItems.length > 0 && (
                        <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {item.nestedItems.map((nestedItem, nestedIndex) => (
                                    <ListItem button key={nestedIndex} onClick={() => { handleClick(nestedIndex, nestedItem.path); handleNestedItemClick(); }}>
                                        {/* <ListItemIcon sx={{ marginLeft: '15px' }}><NestedIcon /></ListItemIcon> */}
                                        <ListItemIcon sx={{ marginLeft: '15px' }}>{nestedItem.icon}</ListItemIcon>
                                        <ListItemText primary={nestedItem.title} />
                                    </ListItem>
                                ))}
                            </List>
                        </Collapse>
                    )}
                </React.Fragment>
            ))}
            <ListItem>
                <div onClick={openUrlInNewTab} style={{ maxHeight: 'overflow:scroll', bottom: '0px', marginLeft: '85px', marginRight: '85px', paddingBottom: "50px" }}>
                    <img src="/images/ifs.png" alt="i-future" style={{ width: '40px', height: '40px' }} />
                </div>
            </ListItem>
        </List>
    );
};

export default SidebarAdmin;
