import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Grid, Card, CardMedia, CardActions } from '@mui/material';
import api from '../../../axios';

const JobResolutionImageModel = ({ isOpen, closeImageModal, resolution }) => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const response = await api.get(`/api/Technician/GetAllJobResolutionImages?resId=${resolution.id}`, config);
                console.log(response.data);
                setImages(response.data || []);
            } catch (error) {
                console.error('Error fetching images:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchImages();
    }, [resolution]);

    const handleDeleteImage = async (imageId) => {
        try {
            setLoading(true);
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            };
            console.log(imageId);
            await api.post(`/api/Technician/DeleteJobResolutionImage?id=${imageId}`,null, config);
            // Remove the deleted image from the state
            setImages(images.filter(image => image.id !== imageId));
        } catch (error) {
            console.error('Error deleting image:', error);
        }finally{
            setLoading(false);
        }
    };

    return (
        <Dialog open={isOpen} onClose={closeImageModal} maxWidth="md">
            <DialogTitle>{resolution.resolutionDescription}</DialogTitle>
            <DialogContent>
                {loading ? (
                    <Typography>Loading...</Typography>
                ) : (
                    <Grid container spacing={2}>
                        {images.map((image) => (
                            <Grid item xs={12} sm={6} md={4} key={image.id}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        style={{ height: 140, width: 200 }}
                                        image={process.env.REACT_APP_BASE_URL + image.imgName}
                                        alt="Job Resolution Image"
                                    />
                                    <CardActions>
                                        <Button size="small" color="error" onClick={() => handleDeleteImage(image.id)}>
                                            Delete
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeImageModal}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default JobResolutionImageModel;
