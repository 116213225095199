import React, { useState, useEffect } from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, TextField, TablePagination, Card, CardHeader, CardContent, IconButton, Skeleton, Menu, MenuItem, Tooltip } from '@mui/material';
import { Edit as EditIcon, Download as ReportIcon } from '@mui/icons-material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import api from '../../../axios';
import useStyles from '../formStyle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { RemoveRedEye as ViewIcon } from '@mui/icons-material';
import ReusableSnackbar from '../../../Component/ReusableSnackbar';
import SaveJobs from './SaveJobs';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import JobBreadcrumbs from './JobBreadcrumbs';

const formatDate = (dateString) => {
    return format(new Date(dateString), 'dd-MMM-yyyy HH:mm');
};

const Jobs = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [entity, setEntity] = useState({
        id: 0,
        createdDate: '',
        modifiedDate: '',
        createdId: 0,
        clientId: 0,
        jobRef: '',
        building: '',
        jobDateTime: '',
        jobHeading: '',
        jobDescription: '',
        notes: '',
        siteContactPerson: '',
        jobStatus: '',
        techCompleteStatus: true,
        techCompleteDate: '',
        invCreatedDate: '',
        invNo: '',
        invAmount: 0,
        clientNotes: '',
        clientApproved: true,
        clientSignature: '',
        clientSignatureDate: '',
    });

    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get('/api/Job/GetPendingJobs', config);
            setData(response.data || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setEntity({
            id: 0,
            createdDate: '',
            modifiedDate: '',
            createdId: 0,
            clientId: 0,
            jobRef: '',
            building: '',
            jobDateTime: '',
            jobHeading: '',
            jobDescription: '',
            notes: '',
            siteContactPerson: '',
            jobStatus: '',
            techCompleteStatus: true,
            techCompleteDate: '',
            invCreatedDate: '',
            invNo: '',
            invAmount: 0,
            clientNotes: '',
            clientApproved: true,
            clientSignature: '',
            clientSignatureDate: '',
        });
        setAnchorEl(null);
    };

    const handleMenuCompleted = () => {
        navigate(`/admin/jobscompleted`);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = data.filter((row) =>
        Object.values(row).some((value) => (value !== null && value.toString().toLowerCase().includes(searchTerm.toLowerCase())))
    );


    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleToggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    useEffect(() => {
        if (!drawerOpen) {
            fetchData();
        }
    }, [drawerOpen]);

    const handleEdit = (editData) => {
        setEntity({
            id: editData.id,
            createdDate: editData.createdDate,
            modifiedDate: editData.modifiedDate,
            createdId: editData.createdId,
            clientId: editData.clientId,
            jobRef: editData.jobRef,
            building: editData.building,
            jobDateTime: editData.jobDateTime,
            jobHeading: editData.jobHeading,
            jobDescription: editData.jobDescription,
            notes: editData.notes,
            siteContactPerson: editData.siteContactPerson,
            jobStatus: editData.jobStatus,
            techCompleteStatus: editData.techCompleteStatus,
            techCompleteDate: editData.techCompleteDate,
            invCreatedDate: editData.invCreatedDate,
            invNo: editData.invNo,
            invAmount: editData.invAmount,
            clientNotes: editData.clientNotes,
            clientApproved: editData.clientApproved,
            clientSignature: editData.clientSignature,
            clientSignatureDate: editData.clientSignatureDate,
        });
        setDrawerOpen(true);
    }

    const handleJobDetails = (id) => {
        navigate(`/admin/jobdetails/${id}`);
    }

    const handleLoadData = () => {
        fetchData();
    };

    const handleTost = (msg) => {
        setSnackbarMessage(msg);
        setOpenSnackbar(true);
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const exportToExcel = () => {
        setAnchorEl(null);
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Jobs');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'jobs.xlsx');
    };

    const handleReport = async (reportId) => {
        try {
            setLoading(true);
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                responseType: 'blob', // Important
            };
            const response = await api.get('/api/Job/JobCardReport?jobId=' + reportId, config);
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);

            // Open the URL in a new tab
            window.open(url, '_blank');
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <ReusableSnackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
            <SaveJobs isOpen={drawerOpen} toggleDrawer={handleToggleDrawer} entity={entity} LoadData={handleLoadData} ShowTost={handleTost} />
            <JobBreadcrumbs />
            <Card>
                <CardHeader title='Jobs' className={classes.header}
                    action={
                        <>
                            <IconButton
                                aria-label='more'
                                id='long-button'
                                aria-haspopup='true'
                                onClick={handleMenuClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={() => { handleToggleDrawer(); handleMenuClose(); }}>Add New Job</MenuItem>
                                <MenuItem onClick={handleMenuCompleted}>Completed</MenuItem>
                                <MenuItem onClick={exportToExcel}>Export to Excel</MenuItem>
                            </Menu>
                        </>
                    }
                >
                </CardHeader>

                <CardContent>
                    <TextField
                        label='Search'
                        variant='outlined'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: '10px', float: 'right' }}
                    />
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow className={classes.header}>
                                    <TableCell style={{ fontWeight: 'bold' }}>Id</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Created Date</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Created By</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Client</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Job Ref</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Building</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Job Date</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Job Heading</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Site Contact Person</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Job Status</TableCell>
                                    <TableCell style={{ float: 'right', fontWeight: 'bold' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={11}>
                                            <Skeleton animation='wave' />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    paginatedData.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{user.id}</TableCell>
                                            <TableCell>{formatDate(user.createdDate)}</TableCell>
                                            <TableCell>{user.Users.firstName} {user.Users.lastName}</TableCell>
                                            <TableCell>{user.Client.CompanyName}</TableCell>
                                            <TableCell>{user.jobRef}</TableCell>
                                            <TableCell>{user.building}</TableCell>
                                            <TableCell>{formatDate(user.jobDateTime)}</TableCell>
                                            <TableCell>{user.jobHeading}</TableCell>
                                            <TableCell>{user.siteContactPerson}</TableCell>
                                            <TableCell>{user.jobStatus}</TableCell>
                                            <TableCell>
                                                <Tooltip title='Edit Job'>
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => handleEdit(user)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Download Job Card'>
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => handleReport(user.id)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <ReportIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Job Details'>
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => handleJobDetails(user.id)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <ViewIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card>
        </>
    )
};

export default Jobs;
