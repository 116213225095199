import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Drawer, Divider, Box, Tooltip, Avatar, Menu, MenuItem,Paper } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// import '../App.css';
import SidebarAdmin from "./sidebar";

import useStyles from './styles';

import Footer from '../footer';

import { useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import { Close } from '@mui/icons-material';

import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import { LockReset } from '@mui/icons-material';


const TechnicianLayout = ({ children }) => {

    const classes = useStyles();
    const navigate = useNavigate();
    const [userName, setUserName] = useState('Admin');
    const [userImage, setuserImage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorEl(null);
    };

    const openUrlInNewTab = () => {
        window.open('https://www.i-future.co.za/', '_blank');
    };

    useEffect(() => {

        const token = JSON.parse(localStorage.getItem('user'));
        if (token != null) {
            setSnackbarMessage(token.message);
            setUserName(token.user.firstName + ' ' + token.user.lastName)
            setuserImage(token.user.img);
            setOpenSnackbar(true);
        }

        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const handleKeyPress = (event) => {
        if (event.key === 'Escape') {
            // Handle the Escape key press
            //alert(openDrawer);
            handleDrawerOpen();
        }
    };

    const handleMenuItemClick = (path) => {
        navigate(path);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };


    return (

        <>
            <div>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={5000}
                    onClose={handleClose}
                    message={snackbarMessage}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    action={
                        <React.Fragment>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                sx={{ p: 0.5 }}
                                onClick={handleClose}
                            >
                                <Close />
                            </IconButton>
                        </React.Fragment>
                    }
                />
            </div>

            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleDrawerOpen}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography onClick={()=>navigate(`/technician`)} style={{cursor: 'pointer'}} variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Safe-T
                    </Typography>

                    <Box sx={{ flexGrow: 0 }}>

                        <Tooltip title="Account settings">
                            <IconButton
                                onClick={handleOpenUserMenu}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <Typography style={{ color: 'white', paddingRight: '5px', textTransform: 'uppercase' }}>{userName}</Typography>
                                <Avatar src={process.env.REACT_APP_BASE_URL + userImage} sx={{ width: 32, height: 32 }}>M</Avatar>
                            </IconButton>
                        </Tooltip>


                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleCloseUserMenu}
                            onClick={handleCloseUserMenu}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&::before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={() => handleMenuItemClick('/technician/profile')}>
                                <Avatar /> Profile
                            </MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick('/technician/changepassword')}>
                                <Avatar><LockReset /></Avatar> Change Password
                            </MenuItem>
                            <Divider />

                            <MenuItem onClick={() => handleMenuItemClick('/logout')}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
                    </Box>

                </Toolbar>
            </AppBar>
            <Drawer
                PaperProps={{
                    sx: {
                        width: { xs: '90%', sm: '260px' }, // Adjust the width for different screen sizes
                    },
                }}
                anchor="left"
                open={openDrawer}
                onClose={handleDrawerClose}

            >
                <Typography variant="h6" sx={{ padding: '16px' }} fontWeight='bold'>Technician</Typography>
                <Divider />

                <div className={classes.sidebarScroll}>
                    <SidebarAdmin handleDrawerClose={handleDrawerClose} />


                    <div onClick={openUrlInNewTab} style={{ maxHeight: 'overflow:scroll', position: 'fixed', bottom: '0px', padding: '85px', paddingBottom: "70px" }}>
                        <img src="/images/ifs.png" alt="i-future" style={{ position: 'fixed' }} />
                    </div>
                </div>

            </Drawer >

            {/* <div style={{ marginTop: '20px' }}>
                {children}
            </div> */}

            <div style={{ backgroundColor: 'gray' }}>
                {/* <Paper elevation={3} sx={{ mt: '80px',padding:'2px' }}>
                    {children}
                </Paper> */}

                <Box
                    display="flex"
                    flexDirection="column"
                    minHeight="90vh"
                    bgcolor="#f0f0f0"
                    paddingTop="65px"
                    paddingBottom="10px"
                >

                    {/* Content Box */}
                    <Box flex="1" p={2}>
                        <Paper elevation={3} p={3}>
                            {children}
                        </Paper>
                    </Box>

                </Box>


            </div>

            <Footer />

        </>


        // <>
        //     <SidebarAdmin />
        //     <Outlet />
        // </>
    )
}

export default TechnicianLayout;