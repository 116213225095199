import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedTechnicianRoute = ({ element }) => {
    const token = JSON.parse(localStorage.getItem('user'));

    if (token && token.user && token.user.userType === 3) {
        return <>{element}</>;
    } else {
        return <Navigate to="/" replace />;
    }
};

export default ProtectedTechnicianRoute;