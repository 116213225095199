import React, { useState, useEffect } from 'react';
import { Grid, FormControl, TextField, Button, CircularProgress, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, IconButton, Skeleton, Tooltip } from '@mui/material';
import { Download as DownloadIcon, Close as DeleteIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../../axios';

const JobsDocument = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [entity, setEntity] = useState({
    id: 0,
    surveyId: props.id,
    docName: '',
    docExtension: '',
    docGuild: '',
  });

  const fetchData = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const token = user.token;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      };
      const response = await api.get(`/api/Technician/GetAllJobDocuments?jobId=${props.id}`, config);
      setData(response.data || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: {
      id: entity.id || '',
      jobId: props.id,
      docName: entity.docName || '',
      file: null,
    },
    validationSchema: Yup.object({
      docName: Yup.string().required('Please enter Document Name'),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const apiUrl = '/api/Technician/SaveJobDocuments';
        const user = JSON.parse(localStorage.getItem('user'));
        const token = user.token;
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        };
        const data = new FormData();
        data.append('id', values.id);
        data.append('jobId', values.jobId);
        data.append('docName', values.docName);
        if (values.file) {
          data.append('file', values.file);
        }
        const response = await api.post(apiUrl, data, config);
        if (response.status === 200) {
          console.log('created successfully.');
          formik.resetForm();
          fetchData();
        } else {
          console.error('Something went wrong.');
        }
      } catch (error) {
        console.error('Error:', error.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleDelete = async (id) => {
    const confirmation = window.confirm('Are you sure you want to delete?');
    if (confirmation) {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        const token = user.token;
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await api.post(`/api/Technician/DeleteJobDocuments?id=${id}`, null, config);
        if (response.status === 200) {
          fetchData();
        }
      } catch (error) {
        console.error('Error deleting data:', error);
      }
    }
  };

  const downloadFile = async (docId, docName, ext) => {
    try {
      // Retrieve the user token from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      const token = user.token;

      // Set up the request configuration with the authorization header
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        responseType: 'blob', // Ensure response is treated as a blob (binary data)
      };

      // Make the authenticated GET request to download the file
      const response = await api.get(`/api/Technician/DownloadJobDocument/${docId}`, config);

      // Create a URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', docName + '.' + ext); // Set the desired file name here

      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      // Handle errors
      console.error('Error downloading file:', error);
    }
  };

  return (
    <>

      {props.pending ? (

        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1} >
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth margin='normal'>
                <TextField
                  name='docName'
                  label='Doc Name'
                  value={formik.values.docName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.docName && Boolean(formik.errors.docName)}
                  helperText={formik.touched.docName && formik.errors.docName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={1}>
              <FormControl fullWidth margin='normal'>
                <input
                  type="file"
                  //accept="image/*"
                  name="file"
                  onChange={(event) => formik.setFieldValue('file', event.currentTarget.files[0])}
                  onBlur={formik.handleBlur}
                  style={{ display: 'none' }}
                  id="img-upload"
                  size='large'
                />
                <label htmlFor="img-upload">
                  <Button variant="contained" component="span" size='large' >
                    Select
                  </Button>
                </label>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth margin='normal'>
                <Button
                  type='submit'
                  disabled={loading}
                  variant='contained'
                  color='primary'
                  size='large'
                >
                  {loading ? (
                    <>
                      Please wait...
                      <CircularProgress size={20} style={{ marginLeft: 8 }} />
                    </>
                  ) : (
                    'Save Document'
                  )}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </form>

      ) : (
        <span></span>
      )}



      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Created Date</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Document Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Extension</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <Skeleton animation='wave' />
                </TableCell>
              </TableRow>
            ) : (
              data.map((location, index) => (
                <TableRow key={index}>
                  <TableCell>{location.createdDate}</TableCell>
                  <TableCell>{location.docName}</TableCell>
                  <TableCell>{location.docExtension}</TableCell>
                  <TableCell>

                    {props.pending ? (
                      <Tooltip title='Delete'>
                        <IconButton
                          color="primary"
                          onClick={() => handleDelete(location.id)}
                          style={{ float: 'right' }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <span></span>
                    )}

                    <Tooltip title='Download'>
                      <IconButton
                        color="primary"
                        onClick={() => downloadFile(location.id, location.docName, location.docExtension)}
                        style={{ float: 'right' }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  )
};

export default JobsDocument;
