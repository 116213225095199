import React, { useState, useEffect } from 'react';
import { Grid, FormControl, TextField, Button, CircularProgress, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, IconButton, Skeleton, Tooltip } from '@mui/material';
import { Edit as EditIcon, ImageOutlined as ImageIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../../axios';
import { format } from 'date-fns';
import JobResolutionImageModel from './JobResolutionImageModel';

const formatDate = (dateString) => {
  return format(new Date(dateString), 'dd-MMM-yyyy');
};
const JobsResolution = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [entity, setEntity] = useState({
    id: 0,
    createdDate: '',
    createdId: 0,
    jobId: 0,
    resolutionDescription: '',
    resolutionDate: '',
  });

  const fetchData = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const token = user.token;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      };
      const response = await api.get(`/api/Job/GetAllJobResolution?jobId=${props.id}`, config);
      setData(response.data || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: {
      id: entity.id || '',
      jobId: props.id,
      resolutionDescription: entity.resolutionDescription || '',
      resolutionDate: entity.resolutionDate || '',
    },
    validationSchema: Yup.object({
      resolutionDescription: Yup.string().required('Please enter Resolution Description'),
      resolutionDate: Yup.string().required('Please enter Resolution Date'),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const apiUrl = '/api/Job/SaveJobResolution';
        const user = JSON.parse(localStorage.getItem('user'));
        const token = user.token;
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        const data = new FormData();
        data.append('id', values.id);
        data.append('jobId', values.jobId);
        data.append('resolutionDescription', values.resolutionDescription);
        data.append('resolutionDate', values.resolutionDate);
        const response = await api.post(apiUrl, data, config);
        if (response.status === 200) {
          fetchData();
          console.log('created successfully.');
          formik.resetForm();
        } else {
          console.error('Something went wrong.');
        }
      } catch (error) {
        console.error('Error:', error.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleEdit = (editData) => {
    const resolutionDateString = editData.resolutionDate
      ? format(new Date(editData.resolutionDate), 'yyyy-MM-dd') // Format the date as 'yyyy-MM-dd'
      : '';
    formik.setFieldValue('id', editData.id);
    formik.setFieldValue('jobId', props.id);
    formik.setFieldValue('resolutionDescription', editData.resolutionDescription);
    formik.setFieldValue('resolutionDate', resolutionDateString);
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [JobResolution, setJobResolution] = useState([]);
  const handleResolutionImage = (data) => {
    setJobResolution(data);
    setModalIsOpen(true);
  }

  const closeImageModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <JobResolutionImageModel isOpen={modalIsOpen} closeImageModal={closeImageModal} resolution={JobResolution} />
      {props.pending ? (

        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1} >
            <Grid item xs={12} sm={5}>
              <FormControl fullWidth margin='normal'>
                <TextField
                  name='resolutionDescription'
                  label='Resolution Description'
                  value={formik.values.resolutionDescription}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.resolutionDescription && Boolean(formik.errors.resolutionDescription)}
                  helperText={formik.touched.resolutionDescription && formik.errors.resolutionDescription}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth margin='normal'>
                <TextField
                  type='date'
                  name='resolutionDate'
                  label='Resolution Date'
                  value={formik.values.resolutionDate}
                  onChange={(e) => formik.setFieldValue('resolutionDate', e.target.value)}
                  onBlur={formik.handleBlur}
                  error={formik.touched.resolutionDate && Boolean(formik.errors.resolutionDate)}
                  helperText={formik.touched.resolutionDate && formik.errors.resolutionDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                type='submit'
                disabled={loading}
                variant='contained'
                color='primary'
                size='large'

                style={{ marginTop: 20 }}
              >
                {loading ? (
                  <>
                    Please wait...
                    <CircularProgress size={20} style={{ marginLeft: 8 }} />
                  </>
                ) : (
                  'Save Resolution'
                )}
              </Button>
            </Grid>
          </Grid>
        </form>

      ) : (
        <span></span>
      )}


      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Description</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Date</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={3}>
                  <Skeleton animation='wave' />
                </TableCell>
              </TableRow>
            ) : (
              data.map((location, index) => (
                <TableRow key={index}>
                  <TableCell>{location.resolutionDescription}</TableCell>
                  <TableCell>{formatDate(location.resolutionDate)}</TableCell>
                  <TableCell>
                    {props.pending ? (
                      <>
                        <Tooltip title='Edit'>
                          <IconButton
                            color="primary"
                            onClick={() => handleEdit(location)}
                            style={{ float: 'right' }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Resolution Image'>
                          <IconButton
                            color="primary"
                            onClick={() => handleResolutionImage(location)}
                            style={{ float: 'right' }}>
                            <ImageIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : (
                      <span></span>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  )
};

export default JobsResolution;
