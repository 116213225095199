import React, { useState, useEffect } from "react";
import { Paper, Typography, Avatar, Grid } from '@mui/material';

const Profile = () => {
    const [userProfile, setUserProfile] = useState(null);

    useEffect(() => {
        // Assume user data is stored in local storage for demonstration purposes
        const storedUser = JSON.parse(localStorage.getItem('user'));

        if (storedUser) {
            console.log(storedUser.user);
            setUserProfile(storedUser.user);
        }
    }, []);

    if (!userProfile) {
        return <div>Loading...</div>;
    }

    const {
        firstName,
        lastName,
        saIdNo,
        physicalAddress,
        tel,
        mobile,
        email,
        isActive,
        notes,
        img,
        Roles,
    } = userProfile;

    const roleName = Roles?.roleName || 'Unknown Role';

    return (
        <Paper elevation={3} sx={{ padding: 3, maxWidth: 400, margin: 'auto', marginTop: 2 }}>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item>
                    <Avatar src={process.env.REACT_APP_BASE_URL + img} alt={`${firstName} ${lastName}`} sx={{ width: 80, height: 80 }} />
                </Grid>
                <Grid item>
                    <Typography variant="h5">{`${firstName} ${lastName}`}</Typography>
                    <Typography variant="subtitle1">{email}</Typography>
                </Grid>
            </Grid>

            <Typography variant="h6" sx={{ marginTop: 2 }}>
                User Details
            </Typography>
            <Typography>
                <strong>SA ID No:</strong> {saIdNo || 'N/A'}
            </Typography>
            <Typography>
                <strong>Physical Address:</strong> {physicalAddress || 'N/A'}
            </Typography>
            <Typography>
                <strong>Tel:</strong> {tel || 'N/A'}
            </Typography>
            <Typography>
                <strong>Mobile:</strong> {mobile || 'N/A'}
            </Typography>
            <Typography>
                <strong>Active:</strong> {isActive ? 'Yes' : 'No'}
            </Typography>
            <Typography>
                <strong>Notes:</strong> {notes || 'N/A'}
            </Typography>
            <Typography>
                <strong>Role:</strong> {roleName}
            </Typography>
        </Paper>
    );
};

export default Profile;
