import api from "../axios";

const AccountService = {

    GetCompany: async (authToken) => {
        const response = await api.get(`/api/Account/GetCompany?authToken=${authToken}`);
        return response;
    },

    GetAuthToken: async (loginId, password) => {
        const response = await api.post(`/api/Account/GetAuthToken?loginId=${loginId}&password=${password}`);
        return response;
    }

}

export default AccountService;