import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation } from 'react-router-dom';
import {
    CallReceived as ReceiveGoodsIcon,
    CallMade as ReturnGoodsIcon,
    ShoppingCartCheckout as StockIssueIcon,
    ProductionQuantityLimits as StockReturnIcon,
    RemoveShoppingCart as StockScrapIcon,
    RequestQuote as QuotationIcon,
    Receipt as InvoiceIcon,
} from '@mui/icons-material';

const breadcrumbItems = [
    { title: 'Receive Goods', path: '/admin/purchase', icon: <ReceiveGoodsIcon /> },
    { title: 'Return Goods', path: '/admin/purchasereturn', icon: <ReturnGoodsIcon /> },
    { title: 'Stock Issue', path: '/admin/sales', icon: <StockIssueIcon /> },
    { title: 'Stock Return', path: '/admin/salesreturn', icon: <StockReturnIcon /> },
    { title: 'Stock Scrap', path: '/admin/stockscrap', icon: <StockScrapIcon /> },
    { title: 'Quotation', path: '/admin/quotation', icon: <QuotationIcon /> },
    { title: 'Invoice', path: '/admin/invoice', icon: <InvoiceIcon /> },
   
];

const linkStyle = {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit',
};

export default function TransactionBreadcrumbs() {
    const location = useLocation();

    // Function to get the title from breadcrumbItems based on the current path
    const getTitleFromPath = (path) => {
        const item = breadcrumbItems.find((item) => item.path === path);
        return item ? item.title : '';
    };

    return (
        <Breadcrumbs aria-label="breadcrumb" style={{ margin: 5 }}>
            {breadcrumbItems.map((item, index) => (
                <Link
                    key={index}
                    to={item.path}
                    style={linkStyle}
                >
                    {item.icon && React.cloneElement(item.icon, { sx: { mr: 0.5 } })}
                    <Typography
                        sx={{ display: 'flex', alignItems: 'center' }}
                        color="text.primary"
                    >
                        {item.title}
                    </Typography>
                </Link>
            ))}
            <Typography
                sx={{ display: 'flex', alignItems: 'center',fontWeight:'bold' }}
                color="text.primary"
            >
                {getTitleFromPath(location.pathname)}
            </Typography>
        </Breadcrumbs>
    );
}
