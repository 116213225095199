import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({

    sidebarScroll: {
        overflowY: "auto",
        '&::-webkit-scrollbar': {
            width: '7px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'lightgray',
            borderRadius: '5px',
        },
        '&::-webkit-scrollbar-track': {
            background: 'lightgray',
        },
    },

}))

export default useStyles;