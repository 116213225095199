import React, { useState, useEffect } from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, IconButton, Skeleton, Tooltip } from '@mui/material';
import { Download as DownloadIcon, Close as DeleteIcon } from '@mui/icons-material';
import api from '../../../axios';

const SiteSurveyCanvas = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get(`/api/Survey/GetAllSiteSurveyLocationCanvas?surveyId=${props.id}`, config);
            setData(response.data || []);
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const downloadFile = async (reportId) => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                responseType: 'blob', // Important
            };
            const response = await api.get('/api/Survey/GetCanvasData?docId=' + reportId, config);
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);

            // Open the URL in a new tab
            window.open(url, '_blank');
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Created Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Modified Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Created By</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Modified By</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Floor</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Department</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={8}>
                                    <Skeleton animation='wave' />
                                </TableCell>
                            </TableRow>
                        ) : (
                            data.map((location, index) => (
                                <TableRow key={index}>
                                    <TableCell>{location.createdDate}</TableCell>
                                    <TableCell>{location.modifiedDate}</TableCell>
                                    <TableCell>{location.createdBy}</TableCell>
                                    <TableCell>{location.modifiedBy}</TableCell>
                                    <TableCell>{location.SiteSurveyLocation.floor}</TableCell>
                                    <TableCell>{location.SiteSurveyLocation.department}</TableCell>
                                    <TableCell>{location.reportDone ? 'Done' : 'Pending'}</TableCell>
                                    <TableCell>

                                        {/* {props.pending ? (
                                            <IconButton
                                                color="primary"
                                                //onClick={() => handleDelete(location.id)}
                                                style={{ float: 'right' }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        ) : (
                                            <span></span>
                                        )} */}
                                        <Tooltip title='Download'>
                                            <IconButton
                                                color="primary"
                                                onClick={() => downloadFile(location.reportId)}
                                                style={{ float: 'right' }}
                                            >
                                                <DownloadIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
};

export default SiteSurveyCanvas;
