import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress for loading indicator
import api from '../../../axios';
import { useNavigate } from 'react-router-dom';

const Tile3 = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(false);
    const [msg, setMsg] = useState('QuickBooks');
    const [alertType, setAlertType] = useState(''); // For managing alert type

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const response = await api.get(`/api/Dashboard/GetQboCompany`, config);
                if (response.status === 200) {
                    setStatus(response.data.status);
                    setMsg(response.data.message);
                    setAlertType(response.data.status ? 'success' : 'error'); // Set alert type based on status
                } else {
                    // Handle error response appropriately
                    setAlertType('error');
                    setMsg(`Error: ${response.status} - ${response.statusText}`);
                }
            } catch (error) {
                // Handle unexpected errors
                setAlertType('error');
                setMsg('An unexpected error occurred.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleNavigate = ()=>{
        navigate(`/admin/quickbooks`);
    }

    return (
        <div style={styles.container}>
            {loading ? (
                <CircularProgress style={{color:'white'}} /> // Show loading indicator
            ) : (
                alertType && <Alert onClick={() => handleNavigate()} severity={alertType}>{msg}</Alert> // Show alert based on type
            )}
        </div>
    );
};

const styles = {
    container: {
        height: '80px',
        backgroundColor: '#3E4095',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '10px',
        fontFamily: 'Arial, sans-serif'
    },
};

export default Tile3;
