import React, { useState, useEffect } from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, TextField, TablePagination, Card, CardHeader, CardContent, IconButton, Skeleton, Menu, MenuItem, Tooltip } from '@mui/material';
import { Edit as EditIcon, Security as PermissionIcon } from '@mui/icons-material';
import api from '../../../../axios';
import useStyles from "../../formStyle";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import SaveRole from './SaveRole';
import SavePermission from './SavePermission';
import UserBreadcrumbs from '../UserBreadcrumbs';

const Roles = () => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [entity, setEntity] = useState({
        id: 0,
        roleName: "",
    });
    const [roleId, setRoleId] = useState(0);

    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get('/api/Users/GetAllRole', config);
            //console.log(response.data);
            setData(response.data || []); // Ensure data is not undefined or null
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setEntity({
            id: 0,
            roleName: '',
        });
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = data.filter((row) =>
        Object.values(row).some((value) => (value !== null && value.toString().toLowerCase().includes(searchTerm.toLowerCase())))
    );

    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerOpenPermission, setDrawerOpenPermission] = useState(false);
    const handleToggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    useEffect(() => {
        if (!drawerOpen) {
            fetchData();
        }
    }, [drawerOpen]);

    const handleEdit = (editData) => {
        setEntity({
            id: editData.id,
            roleName: editData.roleName,
        });
        setDrawerOpen(true);
    }

    const handlePermission = (id) => {
        setRoleId(id);
        setDrawerOpenPermission(true);
    }

    const handleToggleDrawerPermission = () => {
        setDrawerOpenPermission(!drawerOpenPermission);
    };

    return (
        <>
            <SaveRole isOpen={drawerOpen} toggleDrawer={handleToggleDrawer} entity={entity} />
            <SavePermission isOpen={drawerOpenPermission} toggleDrawerPermission={handleToggleDrawerPermission} roleId={roleId} />
            <UserBreadcrumbs />
            <Card>
                <CardHeader title="Roles" className={classes.header}
                    action={
                        <>
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-haspopup="true"
                                onClick={handleMenuClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                {/* Add your menu items here */}
                                <MenuItem onClick={() => { handleToggleDrawer(); handleMenuClose(); }}>Add New Role</MenuItem>
                            </Menu>
                        </>
                    }
                >

                </CardHeader>
                <CardContent>
                    <TextField
                        label="Search"
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: '10px', float: 'right' }}
                    />
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.header}>
                                    <TableCell style={{ fontWeight: 'bold' }}>Role Name</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Default</TableCell>
                                    <TableCell style={{ float: 'right', fontWeight: 'bold' }}>Action</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    // Placeholder Skeleton while loading
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            <Skeleton animation="wave" />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    // Render actual data
                                    paginatedData.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{user.roleName}</TableCell>
                                            <TableCell>{user.isDefault ? 'Yes' : 'No'}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => handlePermission(user.id)} style={{ float: 'right' }}>
                                                    <PermissionIcon />
                                                </IconButton>
                                                {user.isDefault === false && (
                                                    <Tooltip title='Edit'>
                                                        <IconButton
                                                            onClick={() => handleEdit(user)}
                                                            style={{ float: 'right' }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}

                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card>
        </>

    )
};

export default Roles;
