import React, { useState, useEffect } from 'react';
import { Drawer, Card, CardHeader, CardContent, CardActions, IconButton, TextField, Button, CircularProgress, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Grid, Paper } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../axios';
import useStyles from '../admin/formStyle';
import CloseIcon from '@mui/icons-material/Close';

const SaveServiceRequest = ({ isOpen, toggleDrawer, entity,ShowTost }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            contactPerson: entity.contactPerson || '',
            contactPersonMobile: entity.contactPersonMobile || '',
            serviceReqDesc: entity.serviceReqDesc || '',
            priority: 'High',
        },
        validationSchema: Yup.object({
            contactPerson: Yup.string().required('Please enter contact person'),
            contactPersonMobile: Yup.string().required('Please enter mobile'),
        }),
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const apiUrl = '/api/ClientPortal/SaveServiceRequest';
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const data = new FormData();
                data.append('contactPerson', values.contactPerson);
                data.append('contactPersonMobile', values.contactPersonMobile);
                data.append('serviceReqDesc', values.serviceReqDesc);
                data.append('priority', values.priority);
                const response = await api.post(apiUrl, data, config);
                if (response.status === 200) {
                    console.log('created successfully.');
                    ShowTost(response.data);
                    formik.resetForm();
                } else {
                    ShowTost('Something went wrong.');
                    console.error('Something went wrong.');
                }
            } catch (error) {
                ShowTost('Error:', error.message);
                console.error('Error:', error.message);
            } finally {
                setLoading(false);
                toggleDrawer();
            }
        },
    });
    useEffect(() => {
        formik.setValues({
            contactPerson: entity.contactPerson || '',
            contactPersonMobile: entity.contactPersonMobile || '',
            serviceReqDesc: entity.serviceReqDesc || '',
            priority: entity.priority || 0,
        });
    }, [entity]);

    return (
        <>
            <Drawer anchor='right'
                open={isOpen}
                onClose={toggleDrawer}
                variant='temporary'
                ModalProps={{
                    keepMounted: true,
                }}
                PaperProps={{
                    sx: {
                        width: '100%',
                        maxWidth: '550px', // Adjust width as needed
                    },
                }} >
                <div style={{ height: '100vh', overflow: 'auto' }}>
                    <Card>
                        <CardHeader title='Create Service Request' className={classes.header}
                            action={
                                <>
                                    <IconButton
                                        edge='end'
                                        color='inherit'
                                        aria-label='close'
                                        onClick={toggleDrawer}
                                        style={{ marginRight: 2 }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </>
                            }>
                        </CardHeader>
                        <CardContent>
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={1} >
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                name='contactPerson'
                                                label='Contact Person'
                                                value={formik.values.contactPerson}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                name='contactPersonMobile'
                                                label='Contact Person Mobile'
                                                value={formik.values.contactPersonMobile}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <TextField
                                                name='serviceReqDesc'
                                                label='Description'
                                                value={formik.values.serviceReqDesc}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth margin='normal'>
                                            <InputLabel id='priority-label'>Priority</InputLabel>
                                            <Select
                                                name='priority'
                                                label='Priority'
                                                labelId='priority-label'
                                                value={formik.values.priority}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            >
                                                <MenuItem key='1' value='High'>High</MenuItem>
                                                <MenuItem key='2' value='Medium'>Medium</MenuItem>
                                                <MenuItem key='3' value='Low'>Low</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <CardActions className={classes.footer}>
                                    <Button type='submit' disabled={loading} variant='contained' color='primary' size='large' className={classes.largeButton}>
                                        {loading ? (
                                            <>
                                                Please wait...
                                                <CircularProgress size={20} style={{ marginLeft: 8 }} />
                                            </>
                                        ) : (
                                            'Submit'
                                        )}
                                    </Button>
                                </CardActions>
                            </form>
                        </CardContent>
                    </Card>
                </div>
            </Drawer >
        </>
    )
};
export default SaveServiceRequest;
