import React, { useState, useEffect } from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, TextField, TablePagination, Card, CardHeader, CardContent, IconButton, Skeleton, Tooltip } from '@mui/material';
import { GetApp as DownloadIcon, Close as DeleteIcon } from '@mui/icons-material';
import api from '../../../../axios';
import useStyles from "../../formStyle";
import SettingBreadcrumbs from '../SettingBreadcrumbs';

const ErrorLog = () => {
    const classes = useStyles();

    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get('/api/Settings/GetAllExceptionLog', config);
            setData(response.data || []); // Ensure data is not undefined or null
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDownload = (fileName) => {
        // Implement your download logic here, using the file name
        //console.log(`Downloading: ${fileName}`);
        var url = process.env.REACT_APP_BASE_URL + '/ErrorLog/' + fileName;
        var win = window.open(url, '_blank');
        win.focus();
    };

    const handleDelete = async (fileName) => {
        const confirmation = window.confirm('Are you sure you want to delete?');
        if (confirmation) {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const response = await api.get(`/api/Settings/DeleteLog?fileName=${fileName}`, config);
                if (response.status === 200) {
                    fetchData();
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }

    const filteredData = data.filter((row) =>
        Object.values(row).some((value) => (value !== null && value.toString().toLowerCase().includes(searchTerm.toLowerCase())))
    );

    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <>
            <SettingBreadcrumbs />
            <Card>
                <CardHeader title="Error Log" className={classes.header}></CardHeader>
                <CardContent>
                    <TextField
                        label="Search"
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: '10px', float: 'right' }}
                    />
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.header}>
                                    <TableCell style={{ fontWeight: 'bold' }}>File</TableCell>
                                    <TableCell style={{ float: 'right', fontWeight: 'bold' }}>Download</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    // Placeholder Skeleton while loading
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <Skeleton animation="wave" />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    // Render actual data
                                    paginatedData.map((row, index) => (
                                        <TableRow key={index}>
                                            {Object.values(row).map((value, index) => (
                                                <TableCell key={index}>{value}</TableCell>
                                            ))}
                                            <TableCell>
                                                <Tooltip title='Download'>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => handleDownload(row.FileName)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <DownloadIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Delete'>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => handleDelete(row.FileName)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card>
        </>
    )


};

export default ErrorLog;
