import React, { useState, useEffect } from 'react';
import { Grid, FormControl, CircularProgress, Select, Button, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, TextField, TablePagination, Card, CardHeader, CardContent, IconButton, Skeleton, Menu, MenuItem, Popover, Tooltip } from '@mui/material';
import { Edit as EditIcon, CloudUpload as QBPushIcon, ArrowDropUp, Receipt as ConvertInvoiceIcon, Download as DownloadIcon } from '@mui/icons-material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import api from '../../../../axios';
import useStyles from '../../formStyle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReusableSnackbar from '../../../../Component/ReusableSnackbar';
import SaveQuotation from './SaveQuotation';
import TransactionBreadcrumbs from '../TransactionBreadcrumbs';
import QBOQuotation from './QBOQuotation';
import { format } from 'date-fns';
import { useFormik } from 'formik';

const formatDate = (dateString) => {
    return format(new Date(dateString), 'dd-MMM-yyyy HH:mm');
};

const formatDate2 = (dateString) => {
    return format(new Date(dateString), 'dd-MMM-yyyy');
};

const ManageQuotation = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [loading, setLoading] = useState(true);
    const [entity, setEntity] = useState({
        quoteId: 0,
        createdDate: '',
        modifiedDate: '',
        createdBy: '',
        Id: '',
        ClientID: 0,
        quoteNo: '',
        quoteDate: '',
        expDate: '',
        customField1: '',
        customField2: '',
        customField3: '',
        notes: '',
        privateNotes: '',
        taxType: '',
        subtotalAmount: '',
        vatAmount: '',
        totalAmount: '',
        quoteStatus: '',
        quoteStatusBy: '',
        quoteStatusDate: '',
    });

    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get('/api/Transaction/GetAllQuotation', config);
            //console.log(response.data);
            setData(response.data || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setEntity({
            quoteId: 0,
            createdDate: '',
            modifiedDate: '',
            createdBy: '',
            Id: '',
            ClientID: 0,
            quoteNo: '',
            quoteDate: '',
            expDate: '',
            customField1: '',
            customField2: '',
            customField3: '',
            notes: '',
            privateNotes: '',
            taxType: '',
            subtotalAmount: '',
            vatAmount: '',
            totalAmount: '',
            quoteStatus: '',
            quoteStatusBy: '',
            quoteStatusDate: '',
        });
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = data.filter((row) =>
        Object.values(row).some((value) => value.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );


    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleToggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    useEffect(() => {
        if (!drawerOpen) {
            fetchData();
        }
    }, [drawerOpen]);

    const handleEdit = (editData) => {

        setEntity({
            quoteId: editData.quoteId,
            createdDate: editData.createdDate,
            modifiedDate: editData.modifiedDate,
            createdBy: editData.createdBy,
            Id: editData.Id,

            ClientID: editData.ClientID,
            quoteNo: editData.quoteNo,
            quoteDate: editData.quoteDate,
            expDate: editData.expDate,
            customField1: editData.customField1,
            customField2: editData.customField2,
            customField3: editData.customField3,
            notes: editData.notes,
            privateNotes: editData.privateNotes,
            taxType: editData.taxType,
            subtotalAmount: editData.subtotalAmount,
            vatAmount: editData.vatAmount,
            totalAmount: editData.totalAmount,
            quoteStatus: editData.quoteStatus,
            quoteStatusBy: editData.quoteStatusBy,
            quoteStatusDate: editData.quoteStatusDate,

        });
        setDrawerOpen(true);
    }

    const handleLoadData = () => {
        fetchData();
    };

    const handleTost = (msg) => {
        setSnackbarMessage(msg);
        setOpenSnackbar(true);
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const exportToExcel = () => {
        setAnchorEl(null);
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotation');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'quotation.xlsx');
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const openQBOModal = () => {
        setModalIsOpen(true);
    };

    const closeQBOModal = () => {
        setModalIsOpen(false);
    };

    const handlePushQB = (editData) => {
        setEntity(editData);
        setModalIsOpen(true);
    }

    const handleConvertInvoice = (editData) => {
        var answer = window.confirm("Are you sure you want to convert to invoice?");
        if (answer) {
            setLoading(true);
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            };
            const apiUrl = `/api/Transaction/QuotationToInvoice?quoteId=${editData.quoteId}`;
            api.post(apiUrl, null, config)
                .then(response => {
                    console.log(response.data);
                    setLoading(false);
                    handleTost(response.data);
                    handleLoadData();
                })
                .catch(error => {
                    console.error('Error:', error);
                    setLoading(false);
                    handleTost('Error:', error);
                }).finally(() => {
                    //closeQBOModal();
                });
        }
    }

    const handleDownloadInvoice = async (editData) => {
        try {
            setLoading(true); // Start loading
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                responseType: 'blob', // Important for downloading files
            };

            const response = await api.get('/api/Transaction/InvoiceReport?invId=' + editData.invId, config);
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Generate a file name for the PDF
            const pdfFileName = `${editData.invId}_Invoice_${new Date().toISOString()}.pdf`;

            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);

            // Create a link element
            const link = document.createElement('a');
            link.href = url;
            link.download = pdfFileName;

            // Append the link to the body
            document.body.appendChild(link);

            // Simulate a click event on the link
            link.click();

            // Remove the link from the body
            document.body.removeChild(link);

            // Revoke the blob URL to free up memory
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    }


    const handleDownloadQuotation = async (editData) => {
        try {
            setLoading(true); // Start loading
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                responseType: 'blob', // Important for downloading files
            };

            const response = await api.get('/api/Transaction/QuotationReport?quoteId=' + editData.quoteId, config);
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Generate a file name for the PDF
            const pdfFileName = `${editData.quoteNo}_Quotation_${new Date().toISOString()}.pdf`;

            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);

            // Create a link element
            const link = document.createElement('a');
            link.href = url;
            link.download = pdfFileName;

            // Append the link to the body
            document.body.appendChild(link);

            // Simulate a click event on the link
            link.click();

            // Remove the link from the body
            document.body.removeChild(link);

            // Revoke the blob URL to free up memory
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    }

    
    const handleDownloadQuotationGroup = async (editData) => {
        try {
            setLoading(true); // Start loading
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                responseType: 'blob', // Important for downloading files
            };

            const response = await api.get('/api/Transaction/QuotationReportGroup?quoteId=' + editData.quoteId, config);
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Generate a file name for the PDF
            const pdfFileName = `${editData.quoteNo}_Quotation_${new Date().toISOString()}.pdf`;

            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);

            // Create a link element
            const link = document.createElement('a');
            link.href = url;
            link.download = pdfFileName;

            // Append the link to the body
            document.body.appendChild(link);

            // Simulate a click event on the link
            link.click();

            // Remove the link from the body
            document.body.removeChild(link);

            // Revoke the blob URL to free up memory
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    }

    //-------popover-------

    const [popAnchorEl, setPopAnchorEl] = useState(null);

    const handleClick = (event, user) => {
        //console.log(user);
        setEntity(user);
        setInitialValues(user);
        setPopAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setPopAnchorEl(null);
    };

    const open = Boolean(popAnchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        if (entity) {
            // Convert date to YYYY-MM-DD format if needed
            const formattedDate = entity.quoteStatusDate
                ? formatDate3(entity.quoteStatusDate)
                : '';

            setInitialValues({
                quoteId: entity.quoteId || '',
                quoteStatus: entity.quoteStatus || 0,
                quoteStatusBy: entity.quoteStatusBy || '',
                quoteStatusDate: formattedDate,
            });
        }
    }, [entity]);

    const formatDate3 = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [initialValues, setInitialValues] = useState({
        quoteId: '',
        quoteStatus: 0,
        quoteStatusBy: '',
        quoteStatusDate: '',
    });

    const formik = useFormik({
        // initialValues: {
        //     quoteId:entity.quoteId,
        //     quoteStatus: entity.quoteStatus || 0,
        //     quoteStatusBy: entity.quoteStatusBy || '',
        //     quoteStatusDate: entity.quoteStatusDate || '',
        // },
        enableReinitialize: true, // Allows Formik to reinitialize form values
        initialValues,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const apiUrl = '/api/Transaction/UpdateQuotationStatus';
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const data = new FormData();
                data.append('quoteId', entity.quoteId);
                data.append('quoteStatus', values.quoteStatus);
                data.append('quoteStatusBy', values.quoteStatusBy);
                data.append('quoteStatusDate', values.quoteStatusDate);
                const response = await api.post(apiUrl, data, config);
                if (response.status === 200) {
                    //console.log('created successfully.');
                    //handleTost(response.data);
                    fetchData();
                    formik.resetForm();
                } else {
                    //handleTost('Something went wrong.');
                    //console.error('Something went wrong.');
                }
            } catch (error) {
                handleTost('Error:', error.message);
                console.error('Error:', error.message);
            } finally {
                setPopAnchorEl(null);
                setLoading(false);
            }
        },
    });

    return (
        <>
            <ReusableSnackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
            <SaveQuotation isOpen={drawerOpen} toggleDrawer={handleToggleDrawer} entity={entity} LoadData={handleLoadData} ShowTost={handleTost} />
            <QBOQuotation isOpen={modalIsOpen} closeQBOModal={closeQBOModal} LoadData={handleLoadData} ShowTost={handleTost} entity={entity} />
            <TransactionBreadcrumbs />
            <Card>
                <CardHeader title='Quotation' className={classes.header}
                    action={
                        <>
                            <IconButton
                                aria-label='more'
                                id='long-button'
                                aria-haspopup='true'
                                onClick={handleMenuClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={() => { handleToggleDrawer(); handleMenuClose(); }}>Add New</MenuItem>
                                <MenuItem onClick={() => { openQBOModal(); handleMenuClose(); }}>Sync From Quickbooks</MenuItem>
                                <MenuItem onClick={exportToExcel}>Export to Excel</MenuItem>
                            </Menu>
                        </>
                    }
                >
                </CardHeader>

                <CardContent>
                    <TextField
                        label='Search'
                        variant='outlined'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: '10px', float: 'right' }}
                    />
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.header}>
                                    <TableCell style={{ fontWeight: 'bold' }}>Id</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Created Date</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Created By</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Client</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Quote No</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Quote Date</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Total Amount</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Total Vat Amount</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Grand Total</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Invoice</TableCell>
                                    <TableCell style={{ float: 'right', fontWeight: 'bold' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={12}>
                                            <Skeleton animation='wave' />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    paginatedData.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{user.quoteId}</TableCell>
                                            <TableCell>{formatDate(user.createdDate)}</TableCell>
                                            <TableCell>{user.createdBy}</TableCell>
                                            <TableCell>{user.Client.Title} {user.Client.GivenName} {user.Client.MiddleName} {user.Client.FamilyName} - {user.Client.CompanyName}</TableCell>
                                            <TableCell>{user.quoteNo}</TableCell>
                                            <TableCell>{formatDate2(user.quoteDate)}</TableCell>
                                            <TableCell>{user.totalAmount.toFixed(2)}</TableCell>
                                            <TableCell>{user.vatAmount.toFixed(2)}</TableCell>
                                            <TableCell>{(user.totalAmount+user.vatAmount).toFixed(2)}</TableCell>
                                            <TableCell>
                                                <div style={{ position: 'relative' }}>

                                                    <Button aria-describedby={id} variant="contained" onClick={(e) => handleClick(e, user)}>
                                                        {user.quoteStatus}
                                                    </Button>
                                                    <Popover
                                                        id={id}
                                                        open={open}
                                                        anchorEl={popAnchorEl}
                                                        onClose={handleClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                    >

                                                        <>
                                                            <form onSubmit={formik.handleSubmit}>

                                                                <Grid container spacing={1} style={{ width: '550px', padding: '5px' }} >
                                                                    <ArrowDropUp style={{ position: 'absolute', top: '-12px', left: 'calc(90% - 12px)', fontSize: '2rem', color: '#3f51b5' }} />
                                                                    <Grid item xs={12} sm={3}>
                                                                        <FormControl fullWidth margin='normal'>
                                                                            <Select
                                                                                name='quoteStatus'
                                                                                value={formik.values.quoteStatus}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                            >
                                                                                <MenuItem key='Pending' value='Pending'>Pending</MenuItem>
                                                                                <MenuItem key='Accepted' value='Accepted'>Accepted</MenuItem>
                                                                                <MenuItem key='Closed' value='Closed'>Closed</MenuItem>
                                                                                <MenuItem key='Rejected' value='Rejected'>Rejected</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={3}>
                                                                        <FormControl fullWidth margin='normal'>
                                                                            <TextField
                                                                                name='quoteStatusBy'
                                                                                value={formik.values.quoteStatusBy}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <FormControl fullWidth margin='normal'>
                                                                            <TextField
                                                                                type='date'
                                                                                name='quoteStatusDate'
                                                                                value={formik.values.quoteStatusDate}
                                                                                onChange={(e) => formik.setFieldValue('quoteStatusDate', e.target.value)}
                                                                                onBlur={formik.handleBlur}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item sm={2}>
                                                                        <Button type='submit' disabled={loading} variant='contained' color='primary' style={{ marginTop: '24px' }} >
                                                                            {loading ? (
                                                                                <>
                                                                                    Please wait...
                                                                                    <CircularProgress size={20} style={{ marginLeft: 8 }} />
                                                                                </>
                                                                            ) : (
                                                                                'Save'
                                                                            )}
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </form>
                                                        </>

                                                    </Popover>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title='Download Invoice'>
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => handleDownloadInvoice(user)}

                                                    >
                                                        <DownloadIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title='Edit Quotation'>
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => handleEdit(user)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Push to QuickBooks'>
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => handlePushQB(user)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <QBPushIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Convert to Invoice'>
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => handleConvertInvoice(user)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <ConvertInvoiceIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Download Quotation'>
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => handleDownloadQuotation(user)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <DownloadIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Download Quotation (summarised)'>
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => handleDownloadQuotationGroup(user)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <DownloadIcon />
                                                    </IconButton>
                                                </Tooltip>

                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card>
        </>
    )
};

export default ManageQuotation;
