import React, { useState, useEffect } from 'react';
import { Drawer, Card, CardHeader, CardContent, CardActions, IconButton, Box, Avatar, Typography, TextField, TextareaAutosize, Button, CircularProgress, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Grid, Paper } from '@mui/material';
import { useFormik } from 'formik';
import api from '../../../../axios';
import { ManageUsersSchema } from './ManageUsersSchema';
import ReusableSnackbar from '../../../../Component/ReusableSnackbar';
import useStyles from '../../formStyle';
import CloseIcon from '@mui/icons-material/Close';

const SaveUsers = ({ isOpen, toggleDrawer, entity }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [userTypes, setUserTypes] = useState([]);
    const [roles, setRoles] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [imgFile, setImageFile] = useState(null);

    const formik = useFormik({
        initialValues: {
            userType: entity.userType || 0,
            firstName: entity.firstName || '',
            lastName: entity.lastName || '',
            saIdNo: entity.saIdNo || '',
            physicalAddress: entity.physicalAddress || '',
            tel: entity.tel || '',
            mobile: entity.mobile || '',
            email: entity.email || '',
            password: entity.password || '',
            roleId: entity.roleId || '',
            isActive: entity.isActive,
            notes: entity.notes || '',
            img: null,
        },
        validationSchema: ManageUsersSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const apiUrl = '/api/Users/SaveUser';
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    },
                };

                const data = new FormData();
                data.append('id', values.id);
                data.append('userType', values.userType);
                data.append('firstName', values.firstName);
                data.append('lastName', values.lastName);
                data.append('saIdNo', values.saIdNo);
                data.append('physicalAddress', values.physicalAddress);
                data.append('tel', values.tel);
                data.append('mobile', values.mobile);
                data.append('email', values.email);
                data.append('password', values.password);
                data.append('roleId', values.roleId);
                data.append('isActive', values.isActive);
                data.append('notes', values.notes);
                // if (values.img) {
                //     data.append('img', values.img);
                // }

                if (imgFile) {
                    data.append('img', imgFile);
                }

                //console.log(values.img);
                const response = await api.post(apiUrl, data, config);

                if (response.status === 200) {
                    console.log('Plan markers created successfully.');
                    setSnackbarMessage(response.data);
                    setOpenSnackbar(true);
                    setImageFile(null);
                    formik.resetForm();
                } else {
                    setSnackbarMessage('Something went wrong.');
                    setOpenSnackbar(true);
                    console.error('Something went wrong.');
                }
            } catch (error) {
                setSnackbarMessage('Error:', error.message);
                setOpenSnackbar(true);
                console.error('Error:', error.message);
            } finally {
                setLoading(false);
                toggleDrawer();
            }
        },
    });

    useEffect(() => {
        const apiUrl = '/api/Users/GetAllRole2';
        const user = JSON.parse(localStorage.getItem('user'));
        const token = user.token;
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };
        api.get(apiUrl, config)
            .then(response => {
                setUserTypes(response.data.UserTypes);
                setRoles(response.data.Roles);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    useEffect(() => {
        formik.setValues({
            id: entity.id || 0,
            userType: entity.userType || 0,
            firstName: entity.firstName || '',
            lastName: entity.lastName || '',
            saIdNo: entity.saIdNo || '',
            physicalAddress: entity.physicalAddress || '',
            tel: entity.tel || '',
            mobile: entity.mobile || '',
            email: entity.email || '',
            password: entity.password || '',
            roleId: entity.roleId || '',
            isActive: entity.isActive,
            notes: entity.notes || '',
            img: entity.img ? process.env.REACT_APP_BASE_URL + entity.img : '',
        });
    }, [entity]);

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImageFile(file);
            const imageUrl = URL.createObjectURL(file);
            formik.setValues({
                ...formik.values,
                img: imageUrl
            });
        }
    };


    return (
        <div>
            <ReusableSnackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
                type="error"
            />
            <Drawer anchor='right'
                open={isOpen}
                onClose={toggleDrawer}
                variant='temporary'
                ModalProps={{
                    keepMounted: true,
                }}
                PaperProps={{
                    sx: {
                        width: '100%',
                        maxWidth: '700px', // Adjust width as needed
                    },
                }} >

                <div style={{ height: '100vh', overflow: 'auto' }}>
                    <Card>
                        <CardHeader title="Save User" className={classes.header}
                            action={
                                <>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        aria-label="close"
                                        onClick={toggleDrawer}
                                        style={{ marginRight: 2 }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </>
                            }>
                        </CardHeader>
                        <CardContent>
                            <form onSubmit={formik.handleSubmit}>



                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ width: '75%' }}>
                                        <Grid container spacing={1} >
                                            <Grid item xs={12} sm={12}>
                                                <FormControl fullWidth margin="normal">
                                                    <InputLabel id="userType-label">User Type</InputLabel>
                                                    <Select
                                                        name="userType"
                                                        label="user Type"
                                                        labelId="userType-label"
                                                        value={formik.values.userType}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched.userType && Boolean(formik.errors.userType)}
                                                        helpertext={formik.touched.userType && formik.errors.userType}
                                                    >
                                                        {userTypes.map(userType => (
                                                            <MenuItem key={userType.Value} value={userType.Value}>
                                                                {userType.Name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <FormControl fullWidth margin="normal">
                                                    <InputLabel id="role-label">Role</InputLabel>
                                                    <Select
                                                        name="roleId"
                                                        label="Role"
                                                        labelId="role-label"
                                                        value={formik.values.roleId}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched.roleId && Boolean(formik.errors.roleId)}
                                                        helpertext={formik.touched.roleId && formik.errors.roleId}
                                                    >
                                                        {roles.map(roleId => (
                                                            <MenuItem key={roleId.id} value={roleId.id}>
                                                                {roleId.roleName}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div style={{ width: '25%' }}>
                                        <div style={{ border: '2px dashed #ccc', marginLeft: '4px', padding: '10px', textAlign: 'center' }}>
                                            <Box mt={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Avatar
                                                    //src={formik.values.img ? URL.createObjectURL(formik.values.img) : formik.values.img}
                                                    src={formik.values.img}
                                                    sx={{ minWidth: '80px', minHeight: '80px' }} // Adjust width to fill the available space
                                                    variant="square"
                                                />
                                            </Box>

                                            <input
                                                type="file"
                                                accept="image/*"
                                                name="img"
                                                //onChange={(event) => formik.setFieldValue('img', event.currentTarget.files[0])}
                                                onChange={handleFileChange}
                                                onBlur={formik.handleBlur}
                                                style={{ display: 'none' }}
                                                id="img-upload"
                                            />
                                            <label htmlFor="img-upload">
                                                <Button variant="contained" component="span">
                                                    Image
                                                </Button>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <Grid container spacing={1} >
                                    <Grid item xs={12} sm={4}>
                                        <FormControl fullWidth margin="normal">
                                            <TextField
                                                label="First Name"
                                                name="firstName"
                                                value={formik.values.firstName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                                helpertext={formik.touched.firstName && formik.errors.firstName}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <FormControl fullWidth margin="normal">
                                            <TextField
                                                label="Last Name"
                                                name="lastName"
                                                value={formik.values.lastName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                                helpertext={formik.touched.lastName && formik.errors.lastName}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <FormControl fullWidth margin="normal">
                                            <TextField
                                                label="Telephone"
                                                name="tel"
                                                value={formik.values.tel}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormControl fullWidth margin="normal">
                                            <TextField
                                                label="Mobile"
                                                name="mobile"
                                                value={formik.values.mobile}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                                                helpertext={formik.touched.mobile && formik.errors.mobile}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <FormControl fullWidth margin="normal">
                                            <TextField
                                                label="Email"
                                                name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.email && Boolean(formik.errors.email)}
                                                helpertext={formik.touched.email && formik.errors.email}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <FormControl fullWidth margin="normal">
                                            <TextField
                                                label="SA Id No"
                                                name="saIdNo"
                                                value={formik.values.saIdNo}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <FormControl fullWidth margin="normal">
                                            <TextField
                                                label="Physical Address"
                                                name="physicalAddress"
                                                minRows={3}
                                                maxRows={4}
                                                multiline
                                                value={formik.values.physicalAddress}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <FormControl fullWidth margin="normal">
                                            <TextField
                                                minRows={3}
                                                maxRows={4}
                                                multiline
                                                label="Notes"
                                                name="notes"
                                                value={formik.values.notes}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="isActive"
                                                    checked={formik.values.isActive}
                                                    onChange={formik.handleChange}

                                                />
                                            }
                                            label="Active"
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12}>
                                        <div style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center' }}>
                                            <Box mt={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Avatar
                                                    //src={formik.values.img ? URL.createObjectURL(formik.values.img) : formik.values.img}
                                                    src={formik.values.img}
                                                    sx={{ minWidth: '160px', minHeight: '160px' }} // Adjust width to fill the available space
                                                    variant="square"
                                                />
                                            </Box>

                                            <input
                                                type="file"
                                                accept="image/*"
                                                name="img"
                                                //onChange={(event) => formik.setFieldValue('img', event.currentTarget.files[0])}
                                                onChange={handleFileChange}
                                                onBlur={formik.handleBlur}
                                                style={{ display: 'none' }}
                                                id="img-upload"
                                            />
                                            <label htmlFor="img-upload">
                                                <Button variant="contained" component="span">
                                                    Upload Image
                                                </Button>
                                            </label>
                                        </div>
                                    </Grid> */}


                                </Grid>

                                <CardActions className={classes.footer}>

                                    <Button type="submit" disabled={loading} variant="contained" color="primary" size="large" className={classes.largeButton}>
                                        {loading ? (
                                            <>
                                                Please wait...
                                                <CircularProgress size={20} style={{ marginLeft: 8 }} />
                                            </>
                                        ) : (
                                            'Save'
                                        )}
                                    </Button>
                                </CardActions>

                            </form>
                        </CardContent>
                    </Card></div>
            </Drawer >
        </div >
    )
};

export default SaveUsers;
