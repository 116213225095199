import React, { useState, useEffect } from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, TextField, TablePagination, Card, CardHeader, CardContent, IconButton, Skeleton, Menu, MenuItem, Chip } from '@mui/material';
import { Edit as EditIcon, Security as PermissionIcon } from '@mui/icons-material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import api from '../../../axios';
import useStyles from '../formStyle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SupplierBreadcrumbs from './SupplierBreadcrumbs';

const ManageSupplierItem = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get('/api/Suppliers/GetAllSupplierItem', config);
            console.log(response);
            setData(response.data || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = data.filter((row) =>
        Object.values(row).some((value) => {
            if (typeof value === 'object' && value !== null) {
                return Object.values(value).some((nestedValue) =>
                    nestedValue !== null && nestedValue.toString().toLowerCase().includes(searchTerm.toLowerCase())
                );
            }
            return value !== null && value.toString().toLowerCase().includes(searchTerm.toLowerCase());
        })
    );

    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const exportToExcel = () => {
        setAnchorEl(null);
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'SupplierItems');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'supplieritems.xlsx');
    };

    return (
        <>
            <SupplierBreadcrumbs />
            <Card>
                <CardHeader title='Supplier Items' className={classes.header}
                    action={
                        <>
                            <IconButton
                                aria-label='more'
                                id='long-button'
                                aria-haspopup='true'
                                onClick={handleMenuClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={exportToExcel}>Export to Excel</MenuItem>
                            </Menu>
                        </>
                    }
                >
                </CardHeader>

                <CardContent>
                    <TextField
                        label='Search'
                        variant='outlined'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: '10px', float: 'right' }}
                    />
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.header}>
                                    <TableCell style={{ fontWeight: 'bold' }}>Company Name</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Phone</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Mobile</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Item</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Description</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Qty On Hand</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Reorder Point</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            <Skeleton animation='wave' />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    paginatedData.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{user.CompanyName}</TableCell>
                                            <TableCell>{user.PrimaryPhone}</TableCell>
                                            <TableCell>{user.Mobile}</TableCell>
                                            <TableCell>{user.PrimaryEmailAddr}</TableCell>
                                            
                                            <TableCell>{user.Items.Name}</TableCell>
                                            <TableCell>{user.Items.Description}</TableCell>
                                            <TableCell>{user.Items.QtyOnHand}</TableCell>
                                            <TableCell>{user.Items.ReorderPoint}</TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card>
        </>
    )
};

export default ManageSupplierItem;
