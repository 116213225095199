import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Grid, Table, TableContainer, TableBody, TableRow, TableCell, Paper, Button, CircularProgress, Card, CardHeader, CardContent, Skeleton, Box, Typography } from '@mui/material';
import useStyles from '../../admin/formStyle';
import api from '../../../axios';
import { useParams } from 'react-router';
import { format } from 'date-fns';

import SurveyLocation from './SurveyLocation';
//import SurveyTechnician from './SurveyTechnician';
import SurveyDocument from './SurveyDocument';
//import SiteSurveyCanvas from './SiteSurveyCanvas';
import SurveyBreadcrumbs from './SurveyBreadcrumbs';

const formatDate = (dateString) => {
    return format(new Date(dateString), 'dd-MMM-yyyy HH:mm');
};

const SiteSurveyTechDetails = () => {
    const classes = useStyles();
    const { id } = useParams();
    const [active, setActive] = useState(true);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get(`/api/Technician/GetSiteSurveyById?id=${id}`, config); // <-- Correct endpoint construction
            //console.log(response.data);
            setData(response.data || []);
            if (response.data.surveyStatus == 'Pending') {
                setActive(true);
            } else {
                setActive(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();

    }, []);

    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const handleTabChange = (e, tabIndex) => {
        console.log(tabIndex);
        setCurrentTabIndex(tabIndex);
    };

    const handleComplete = async (id) => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.post(`/api/Technician/SetTechSurveyComplete?id=${id}`,null, config); // <-- Correct endpoint construction
            fetchData();
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <SurveyBreadcrumbs />
            <Card>
                <CardHeader title='Site Survey Details' className={classes.header}></CardHeader>
                <CardContent>

                    <Tabs value={currentTabIndex} onChange={handleTabChange} variant='scrollable' scrollButtons='auto' allowScrollButtonsMobile >
                        <Tab label='Survey Information' />
                        <Tab label='Dept / Section / Area' />
                        {/* <Tab label='Technician' /> */}
                        <Tab label='Documents' />
                        {/* <Tab label='Survey Details' /> */}
                    </Tabs>

                    {/* TAB 1 Contents */}
                    {currentTabIndex === 0 && (
                        <Box sx={{ p: 3 }}>

                            <Grid container spacing={1} >
                                <Grid item xs={12} sm={4}>
                                    <TableContainer component={Paper}>
                                        <Table size="small" aria-label="a dense table">
                                            <TableBody>
                                                {loading ? (
                                                    <>
                                                        {[...Array(6)].map((_, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell colSpan={2}>
                                                                    <Skeleton animation='wave' />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        <TableRow>
                                                            <TableCell colSpan={2} component="th" scope="row">
                                                                <Typography sx={{ m: 1, mb: 2 }} variant='h6'>Survey Information</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">Created Date</TableCell>
                                                            <TableCell align="right">{formatDate(data.createdDate)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">Survey Reference</TableCell>
                                                            <TableCell align="right">{data.surveyRef}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">Survey Date</TableCell>
                                                            <TableCell align="right">{formatDate(data.surveyDateTime)}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">Building</TableCell>
                                                            <TableCell align="right">{data.building}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">Site Contact Person</TableCell>
                                                            <TableCell align="right">{data.siteContactPerson}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">Status</TableCell>
                                                            <TableCell align="right">{data.surveyStatus}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row" colSpan={2}>Notes : {data.notes}</TableCell>
                                                        </TableRow>

                                                        {data.techCompleteStatus ? (
                                                            <>
                                                                <TableRow>
                                                                    <TableCell component="th" scope="row">Technician Status</TableCell>
                                                                    <TableCell align="right">{data.techCompleteStatus ? 'Completed' : 'Incomplete'}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell component="th" scope="row">Technician Completed Date</TableCell>
                                                                    <TableCell align="right">{formatDate(data.techCompleteDate)}</TableCell>
                                                                </TableRow>
                                                            </>
                                                        ) : (
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">Technician Site Survey Status</TableCell>
                                                                <TableCell align="right">
                                                                    <Button type='button' disabled={loading} onClick={() => handleComplete(data.id)} variant='contained' color='primary' size='small'>
                                                                        {loading ? (
                                                                            <>
                                                                                Please wait...
                                                                                <CircularProgress size={20} style={{ marginLeft: 8 }} />
                                                                            </>
                                                                        ) : (
                                                                            'COMPLETE'
                                                                        )}
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}

                                                        {data.quotNo && (
                                                            <>
                                                                <TableRow>
                                                                    <TableCell component="th" scope="row">Quotation Created Date</TableCell>
                                                                    <TableCell align="right">{formatDate(data.quotCreatedDate)}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell component="th" scope="row">Quotation Number</TableCell>
                                                                    <TableCell align="right">{data.quotNo}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell component="th" scope="row">Quotation Amount</TableCell>
                                                                    <TableCell align="right">{data.quotAmount}</TableCell>
                                                                </TableRow>
                                                            </>
                                                        )}

                                                    </>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <TableContainer component={Paper}>
                                        <Table size="small" aria-label="a dense table">
                                            <TableBody>

                                                {loading ? (
                                                    <>
                                                        {[...Array(6)].map((_, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell colSpan={2}>
                                                                    <Skeleton animation='wave' />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        <TableRow>
                                                            <TableCell colSpan={2} component="th" scope="row">
                                                                <Typography sx={{ m: 1, mb: 2 }} variant='h6'>Client Information</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">Name</TableCell>
                                                            <TableCell align="right">{data.Client.Title} {data.Client.GivenName} {data.Client.MiddleName} {data.Client.FamilyName}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">Company Name</TableCell>
                                                            <TableCell align="right">{data.Client.CompanyName}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">Mobile Number</TableCell>
                                                            <TableCell align="right">{data.Client.Mobile}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">Email Address</TableCell>
                                                            <TableCell align="right">{data.Client.PrimaryEmailAddr}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">Website</TableCell>
                                                            <TableCell align="right">{data.Client.WebAddr}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">Billing Address</TableCell>
                                                            <TableCell align="right">{data.Client.BillAddrLine1} {data.Client.BillAddrLine2} {data.Client.BillAddrCity} {data.Client.BillAddrCountrySubDivisionCode} {data.Client.BillAddrPostalCode} {data.Client.BillAddrCountry}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">Shipping Address</TableCell>
                                                            <TableCell align="right">{data.Client.ShipAddrLine1} {data.Client.ShipAddrLine2} {data.Client.ShipAddrCity} {data.Client.ShipAddrCountrySubDivisionCode} {data.Client.ShipAddrPostalCode} {data.Client.ShipAddrCountry}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">Active</TableCell>
                                                            <TableCell align="right">{data.Client.Active ? 'Yes' : 'No'}</TableCell>
                                                        </TableRow>
                                                    </>
                                                )}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={12} sm={3}>

                                    <TableContainer component={Paper}>
                                        <Table size="small" aria-label="a dense table">
                                            <TableBody>

                                                {loading ? (
                                                    <>
                                                        {[...Array(6)].map((_, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell colSpan={2}>
                                                                    <Skeleton animation='wave' />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                <Typography sx={{ m: 1, mb: 2 }} variant='h6'>Created By</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">User Name : {data.Users.firstName} {data.Users.lastName}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">Mobile : {data.Users.mobile}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">Email : {data.Users.email}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">Notes : {data.Users.notes}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">Active : {data.Users.isActive ? 'Yes' : 'No'}</TableCell>
                                                        </TableRow>
                                                    </>
                                                )}


                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>


                        </Box>
                    )}

                    {/* TAB 2 Contents */}
                    {currentTabIndex === 1 && (
                        <Box sx={{ p: 3 }}>
                            <SurveyLocation id={id} pending={active} />
                        </Box>
                    )}

                    {/* TAB 3 Contents */}
                    {/* {currentTabIndex === 2 && (
                        <Box sx={{ p: 3 }}>
                            <SurveyTechnician id={id} pending={active}/>
                        </Box>
                    )} */}

                    {/* TAB 4 Contents */}
                    {currentTabIndex === 2 && (
                        <Box sx={{ p: 3 }}>
                            <SurveyDocument id={id} pending={active} />
                        </Box>
                    )}

                    {/* TAB 5 Contents */}
                    {/* {currentTabIndex === 4 && (
                        <Box sx={{ p: 3 }}>
                            <SiteSurveyCanvas id={id} pending={active}/>
                        </Box>
                    )} */}

                </CardContent>
            </Card>
        </>
    );
};

export default SiteSurveyTechDetails;
