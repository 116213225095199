import React from 'react';
import useStyles from '../formStyle';
import { Paper, Grid, Card, CardHeader } from '@mui/material';
import Tile1 from './tile1';
import Tile2 from './tile2';
import Tile3 from './tile3';
import { useNavigate } from 'react-router-dom';

const styles = {
  tileButton: {
    display: 'inline-block',
    width: '100%',
    height: '100px',
    backgroundColor: '#DE6D16',
    color: 'white',
    border: 'none',
    borderRadius: '10px',
    fontSize: '20px',
    cursor: 'pointer',
    textAlign: 'center',
    lineHeight: '100px',
    transition: 'background-color 0.3s',
  },
  tileButtonHover: {
    backgroundColor: '#d43a59',
  },
};

function AdminDashboard() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <>
      <Card>
        <CardHeader title='Dashboard' className={classes.header}></CardHeader>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} style={{ padding: '20px' }}>
              <Tile1 />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
              <Tile2 />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
              <Tile3 />
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
            <Paper elevation={3} style={{ padding: '30px', textAlign: 'center' }}>
              <button
                style={styles.tileButton}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={()=>navigate(`/admin/sitesurvey`)}
              >
                Site Surveys
              </button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} style={{ padding: '30px', textAlign: 'center' }}>
              <button
                style={styles.tileButton}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={()=>navigate(`/admin/jobs`)}
              >
                Job Cards
              </button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} style={{ padding: '30px', textAlign: 'center' }}>
              <button
                style={styles.tileButton}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={()=>navigate(`/admin/groups`)}
              >
                Option Master
              </button>
            </Paper>
          </Grid>
         
          
        </Grid>
      
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} style={{ padding: '30px', textAlign: 'center' }}>
              <button
                style={styles.tileButton}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={()=>navigate(`/admin/quotation`)}
              >
                Quotations
              </button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} style={{ padding: '30px', textAlign: 'center' }}>
              <button
                style={styles.tileButton}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={()=>navigate(`/admin/invoice`)}
              >
                Invoices
              </button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} style={{ padding: '30px', textAlign: 'center' }}>
              <button
                style={styles.tileButton}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={()=>navigate(`/admin/report`)}
              >
                Reports
              </button>
            </Paper>
          </Grid>
        </Grid>
      
      </Card>
    </>
  );
}

export default AdminDashboard;
