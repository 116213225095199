import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Grid, InputLabel, Select } from '@mui/material';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CardHeader, CardActions, IconButton, FormControl, FormHelperText, Menu, MenuItem, CircularProgress } from "@mui/material";


import useStyles from "../../formStyle";

import { useFormik } from "formik";
import { CompanySchema } from "./cmpanySchema";
import api from "../../../../axios";
import ReusableSnackbar from "../../../../Component/ReusableSnackbar";

import CompanyLogo from "./companyLogo";
import SettingBreadcrumbs from "../SettingBreadcrumbs";

const initCompanyValues = {
  companyName: '',
  slogan: '',
  regNo: '',
  vatNo: '',
  physicalAddressL1: '',
  physicalAddressL2: '',
  physicalAddressL3: '',
  physicalAddressL4: '',
  physicalAddressLPostalCode: '',
  province: '',
  postalAddress1: '',
  postalAddress2: '',
  postalAddress3: '',
  postalAddress4: '',
  postalAddressPostalCode: '',
  telPhone: '',
  fax: '',
  mobile: '',
  contactEmail: '',
  website: '',
  instagram: '',
  facebook: '',
  twitter: '',
  tiktok: '',
  bankName: '',
  bankAccountNo: '',
  bankBranch: '',
  bankBranchCode: '',
  bankAccountType: '',
  logo: '',
  notes: '',
  tc: '',

}


const Company = () => {

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [provinces, setProvinces] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState('');
  const [logoname, setLogoname] = useState('');

  useEffect(() => {

    const token = JSON.parse(localStorage.getItem('user'));
    const fetchData = async () => {
      try {
        let key = '';
        if (token != null) {
          key = token.token;
        }
        const response = await api.get(`/api/Account/GetCompany?authToken=${key}`);
        if (response.status === 200) {
          const userData = response.data; // Assuming user data is in response.data
          console.log('aa');
          //console.log(userData);
          setProvinces(userData.Provinces);
          setLogoname(userData.company.logo);

          formik.setValues({
            companyName: userData.company.companyName,
            slogan: userData.company.slogan,
            regNo: userData.company.regNo,
            vatNo: userData.company.vatNo,
            physicalAddressL1: userData.company.physicalAddressL1,
            physicalAddressL2: userData.company.physicalAddressL2,
            physicalAddressL3: userData.company.physicalAddressL3,
            physicalAddressL4: userData.company.physicalAddressL4,
            physicalAddressLPostalCode: userData.company.physicalAddressLPostalCode,
            province: userData.company.province,
            postalAddress1: userData.company.postalAddress1,
            postalAddress2: userData.company.postalAddress2,
            postalAddress3: userData.company.postalAddress3,
            postalAddress4: userData.company.postalAddress4,
            postalAddressPostalCode: userData.company.postalAddressPostalCode,
            telPhone: userData.company.telPhone,
            fax: userData.company.fax,
            mobile: userData.company.mobile,
            contactEmail: userData.company.contactEmail,
            website: userData.company.website,
            instagram: userData.company.instagram,
            facebook: userData.company.facebook,
            twitter: userData.company.twitter,
            tiktok: userData.company.tiktok,
            bankName: userData.company.bankName,
            bankAccountNo: userData.company.bankAccountNo,
            bankBranch: userData.company.bankBranch,
            bankBranchCode: userData.company.bankBranchCode,
            bankAccountType: userData.company.bankAccountType,
            notes: userData.company.notes,
            tc: userData.company.tc,
          });

        }
      } catch (error) {
        console.log('Login failed', error);
      }
    }

    fetchData();


  }, []);

  const formik = useFormik({
    initialValues: initCompanyValues,
    validationSchema: CompanySchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const user = JSON.parse(localStorage.getItem('user'));
        const token = user.token;
        const config = {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
          },
          //withCredentials: true,
        };
        const response = await api.post('/api/Settings/UpdateCompany', values, config);
        if (response.status === 200) {
          const userData = response.data;
          setSnackbarMessage(userData);
          setOpenSnackbar(true);
        } else {
          const userData = response.message;
          setSnackbarMessage(userData);
          setOpenSnackbar(true);
        }

      } catch (error) {
        setSnackbarMessage(error.message);
        setOpenSnackbar(true);
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

  })

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleToggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (

    <>
      <div>
        <ReusableSnackbar
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          type="error"
        />
      </div>

      <CompanyLogo isOpen={drawerOpen} toggleDrawer={handleToggleDrawer} logoname={logoname} />

      <SettingBreadcrumbs/>
      <Card>
        <CardHeader title="Company" className={classes.header}
          action={
            <>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                onClick={handleMenuClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {/* Add your menu items here */}
                <MenuItem onClick={() => { handleToggleDrawer(); handleMenuClose(); }}>Company Logo</MenuItem>
              </Menu>
            </>
          }
        >

        </CardHeader>
        <form onSubmit={formik.handleSubmit}>
          <CardContent>

            <Grid container spacing={1} >

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Company Name"
                    name="companyName"
                    margin="normal"
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                  />
                  {formik.touched.companyName && formik.errors.companyName && (
                    <FormHelperText error>{formik.errors.companyName}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Slogan"
                    name="slogan"
                    margin="normal"
                    value={formik.values.slogan}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Registration Number"
                    name="regNo"
                    fullWidth
                    margin="normal"
                    value={formik.values.regNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.regNo && Boolean(formik.errors.regNo)}
                  />
                  {formik.touched.regNo && formik.errors.regNo && (
                    <FormHelperText error>{formik.errors.regNo}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="VAT Number"
                    name="vatNo"
                    fullWidth
                    margin="normal"
                    value={formik.values.vatNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Physical Address"
                    name="physicalAddressL1"
                    fullWidth
                    margin="normal"
                    value={formik.values.physicalAddressL1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Physical Address2"
                    name="physicalAddressL2"
                    fullWidth
                    margin="normal"
                    value={formik.values.physicalAddressL2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Physical Address3"
                    name="physicalAddressL3"
                    fullWidth
                    margin="normal"
                    value={formik.values.physicalAddressL3}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Physical Address4"
                    name="physicalAddressL4"
                    fullWidth
                    margin="normal"
                    value={formik.values.physicalAddressL4}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Postal Code"
                    name="physicalAddressLPostalCode"
                    fullWidth
                    margin="normal"
                    value={formik.values.physicalAddressLPostalCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3} mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="province">Province</InputLabel>
                  <Select
                    labelId="province"
                    id="province"
                    name="province"
                    label="Province"
                    value={formik.values.province}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {provinces.map((province) => (
                      <MenuItem key={province} value={province}>
                        {province}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Postal Address"
                    name="postalAddress1"
                    fullWidth
                    margin="normal"
                    value={formik.values.postalAddress1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Postal Address2"
                    name="postalAddress2"
                    fullWidth
                    margin="normal"
                    value={formik.values.postalAddress2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Postal Address3"
                    name="postalAddress3"
                    fullWidth
                    margin="normal"
                    value={formik.values.postalAddress3}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Postal Address4"
                    name="postalAddress4"
                    fullWidth
                    margin="normal"
                    value={formik.values.postalAddress4}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Postal Address Postal Code"
                    name="postalAddressPostalCode"
                    fullWidth
                    margin="normal"
                    value={formik.values.postalAddressPostalCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Telephone"
                    name="telPhone"
                    fullWidth
                    margin="normal"
                    value={formik.values.telPhone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Fax"
                    name="fax"
                    fullWidth
                    margin="normal"
                    value={formik.values.fax}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Mobile"
                    name="mobile"
                    fullWidth
                    margin="normal"
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Contact Email"
                    name="contactEmail"
                    fullWidth
                    margin="normal"
                    value={formik.values.contactEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Website"
                    name="website"
                    fullWidth
                    margin="normal"
                    value={formik.values.website}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Instagram"
                    name="instagram"
                    fullWidth
                    margin="normal"
                    value={formik.values.instagram}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Facebook"
                    name="facebook"
                    fullWidth
                    margin="normal"
                    value={formik.values.facebook}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Twitter"
                    name="twitter"
                    fullWidth
                    margin="normal"
                    value={formik.values.twitter}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="TikTok"
                    name="tiktok"
                    fullWidth
                    margin="normal"
                    value={formik.values.tiktok}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Bank Name"
                    name="bankName"
                    fullWidth
                    margin="normal"
                    value={formik.values.bankName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Bank Account No"
                    name="bankAccountNo"
                    fullWidth
                    margin="normal"
                    value={formik.values.bankAccountNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Bank Branch"
                    name="bankBranch"
                    fullWidth
                    margin="normal"
                    value={formik.values.bankBranch}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Bank Branch Code"
                    name="bankBranchCode"
                    fullWidth
                    margin="normal"
                    value={formik.values.bankBranchCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Bank Account Type"
                    name="bankAccountType"
                    fullWidth
                    margin="normal"
                    value={formik.values.bankAccountType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Notes"
                    name="notes"
                    fullWidth
                    margin="normal"
                    value={formik.values.notes}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Terms and conditions"
                    name="tc"
                    fullWidth
                    margin="normal"
                    value={formik.values.tc}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
              </Grid>

            </Grid>

          </CardContent>
          <CardActions className={classes.footer}>

            <Button type="submit" disabled={loading} variant="contained" color="primary" size="large" className={classes.largeButton}>
              {loading ? (
                <>
                  Please wait...
                  <CircularProgress size={20} style={{ marginLeft: 8 }} />
                </>
              ) : (
                'Save Company'
              )}
            </Button>
          </CardActions>
        </form>
      </Card>
    </>
  );
};

export default Company;
