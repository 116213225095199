import React, { useState, useEffect } from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, TextField, TablePagination, Card, CardHeader, CardContent, IconButton, Skeleton, Tooltip } from '@mui/material';
import { Delete as DeleteIcon, LockReset as LockResetIcon } from '@mui/icons-material';
import api from '../../../../axios';
import useStyles from "../../formStyle";
import { format } from 'date-fns';
import UserBreadcrumbs from '../UserBreadcrumbs';

const formatDate = (dateString) => {
    return format(new Date(dateString), 'dd-MMM-yyyy HH:mm');
};

const UserRequest = () => {
    const classes = useStyles();

    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get('/api/Users/GetAllUserRequest', config);
            setData(response.data || []); // Ensure data is not undefined or null
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDelete = async (id) => {
        const confirmation = window.confirm('Are you sure you want to delete?');
        if (confirmation) {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const response = await api.get(`/api/Users/DeleteUserRequest?id=${id}`, config);
                if (response.status === 200) {
                    fetchData();
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    const handleResetPassword = async (id) => {
        const confirmation = window.confirm('Are you sure you want to reset password back to mobile number?');
        if (confirmation) {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const response = await api.get(`/api/Users/UpdateUserRequestPassword?id=${id}`, config);
                if (response.status === 200) {
                    fetchData();
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }

    const filteredData = data.filter((row) =>
        Object.values(row).some((value) => (value !== null && value.toString().toLowerCase().includes(searchTerm.toLowerCase())))
    );

    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <>
            <UserBreadcrumbs />
            <Card>
                <CardHeader title="User Request" className={classes.header}></CardHeader>
                <CardContent>
                    <TextField
                        label="Search"
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: '10px', float: 'right' }}
                    />
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.header}>
                                    <TableCell style={{ fontWeight: 'bold' }}>Created Date</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Mobile</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Message</TableCell>
                                    <TableCell style={{ float: 'right', fontWeight: 'bold' }}>Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    // Placeholder Skeleton while loading
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <Skeleton animation="wave" />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    // Render actual data
                                    paginatedData.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{formatDate(row.createdDate)}</TableCell>
                                            <TableCell>{row.mobile}</TableCell>
                                            <TableCell>{row.email}</TableCell>
                                            <TableCell>{row.message}</TableCell>
                                            <TableCell>
                                                <Tooltip title='Delete'>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => handleDelete(row.id)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Reset Password'>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => handleResetPassword(row.id)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <LockResetIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            </Card>
        </>
    )


};

export default UserRequest;
