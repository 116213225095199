import React from "react"
import { Navigate } from "react-router-dom";

const Logout = () => {
    const token = JSON.parse(localStorage.getItem('user'));
    if(token!=null){
        localStorage.clear('user');
    }
    return <Navigate to="/" replace />;
};

export default Logout;
