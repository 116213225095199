import React, { useState, useEffect } from 'react';
import { DateRange } from '@mui/icons-material';
import { format } from 'date-fns';

const Tile2 = () => {
    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {
        const updateDate = () => {
            const now = new Date();
            setCurrentDate(format(now, 'dd-MMMM-yyyy'));
        };

        updateDate();
        // Update the date every minute
        const interval = setInterval(updateDate, 60000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={styles.container}>
            <div style={styles.dateContainer}>
                <DateRange style={styles.sunIcon} />
                {currentDate}
            </div>
        </div>
    );
};

const styles = {
    container: {
        height: '80px',
        backgroundColor: '#3E4095',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '10px',
        fontFamily: 'Arial, sans-serif'
    },
    dateContainer: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '18px',
        color: '#fff',
        backgroundColor: '#000000aa',
        padding: '10px 20px',
        borderRadius: '50px',
        textAlign: 'center'
    },
    sunIcon: {
        marginRight: '10px',
        color: '#FFD700'
    }
};

export default Tile2;
