import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../axios';
import { TextField, Button, Grid, Card, CardHeader, CardContent, CardActions } from '@mui/material';
import useStyles from "./styles";
import ReusableSnackbar from '../../Component/ReusableSnackbar';
import { CircularProgress } from '@mui/material';

const ChangePassword = () => {
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required('Old Password is required'),
      newPassword: Yup.string()
        .required('New Password is required')
        .min(6, 'New Password must be at least 6 characters'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const user = JSON.parse(localStorage.getItem('user'));
        const token = user.token;
        const config = {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await api.post(`/api/Account/ChangePassword`,values,config);
        console.log(response);
        if (response.status === 200) {
          const userData = response.data.Item1;
          setSnackbarMessage(userData);
          setOpenSnackbar(true);
        } else {
          const userData = response.data.Item1;
          setSnackbarMessage(userData);
          setOpenSnackbar(true);
        }
      } catch (error) {
        setSnackbarMessage(error.message);
        setOpenSnackbar(true);
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (

    <>
      <div>
        <ReusableSnackbar
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          type="error"
        />
      </div>
      <Card>
        <CardHeader title="Change Password" className={classes.header}>
        </CardHeader>
        <form onSubmit={formik.handleSubmit}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Old Password"
                  type="password"
                  fullWidth
                  {...formik.getFieldProps('oldPassword')}
                  error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                  helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="New Password"
                  type="password"
                  fullWidth
                  {...formik.getFieldProps('newPassword')}
                  error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                  helperText={formik.touched.newPassword && formik.errors.newPassword}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Confirm New Password"
                  type="password"
                  fullWidth
                  {...formik.getFieldProps('confirmPassword')}
                  error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                  helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.footer}>

            <Button type="submit" disabled={loading} variant="contained" color="primary" size="large" className={classes.largeButton}>
              {loading ? (
                <>
                  Please wait...
                  <CircularProgress size={20} style={{ marginLeft: 8 }} />
                </>
              ) : (
                'Change Password'
              )}
            </Button>
          </CardActions>
        </form>


      </Card>
    </>

  );
};

export default ChangePassword;
