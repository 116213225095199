import React, { useState } from "react";
import { FormControl, FormHelperText, InputLabel, OutlinedInput, InputAdornment, IconButton, Button, CircularProgress } from '@mui/material';
import { AccountCircle, VisibilityOff, Visibility, Lock, RotateLeft, Close } from '@mui/icons-material';

import useStyles from "./styles";
import { useFormik } from "formik";
import { LoginSchema } from "./loginSchema";

import api from "../../axios";
import { useNavigate } from "react-router-dom";
// import Snackbar from '@mui/material/Snackbar';

import ReusableSnackbar from "../../Component/ReusableSnackbar";

const initLoginValues = {
    loginId: '',
    password: '',
}

export default function Login() {
    const classes = useStyles();
    const navigate = useNavigate();
    //const [open, setOpen] = React.useState(false);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const formik = useFormik({
        initialValues: initLoginValues,
        validationSchema: LoginSchema,
        onSubmit: async (values) => {
            // Handle form submission here
            try {
                setLoading(true);
                const response = await api.post(`/api/Account/GetAuthToken?loginId=${values.loginId}&password=${values.password}`);
                if (response.status === 200) {
                    const userData = response.data; // Assuming user data is in response.data
                    localStorage.setItem('user', JSON.stringify(userData));
                    if (userData.user.userType === 1 || userData.user.userType === 2) {
                        navigate("/admin");
                    } else if (userData.user.userType === 3) {
                        navigate("/technician");
                    } else if (userData.user.userType === 4) {
                        navigate("/client");
                    }
                }
            } catch (error) {
                //setSnackbarMessage(error.response.data.message);
                //setOpen(true);
                setSnackbarMessage(error.response.data.message);
                setOpenSnackbar(true);
                //console.error('Login failed', error.response.status);
            } finally {
                setLoading(false);
            }

            //console.log(values);
        },
    })

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <>
            <div>
                <ReusableSnackbar
                    open={openSnackbar}
                    onClose={handleCloseSnackbar}
                    message={snackbarMessage}
                    type="error"
                />
            </div>
            <form onSubmit={formik.handleSubmit}>

                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                    <InputLabel htmlFor="loginId">Login Id</InputLabel>
                    <OutlinedInput
                        id="loginId"
                        name="loginId"
                        type='text'
                        margin="dense"
                        tabIndex={0}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" edge="end" tabIndex={100}>
                                    <AccountCircle />
                                </IconButton>
                            </InputAdornment>
                        }
                        label="loginId"
                        value={formik.values.loginId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.loginId && Boolean(formik.errors.loginId)}
                    //helperText={formik.touched.loginId ? formik.errors.loginId : ''}
                    />
                    {formik.touched.loginId && formik.errors.loginId && (
                        <FormHelperText error>{formik.errors.loginId}</FormHelperText>
                    )}
                </FormControl>

                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput
                        id="password"
                        name="password"
                        margin="dense"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    tabIndex={99}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                    //helperText={formik.touched.password ? formik.errors.password : ''}
                    />
                    {formik.touched.password && formik.errors.password && (
                        <FormHelperText error>{formik.errors.password}</FormHelperText>
                    )}
                </FormControl>

                <div className={classes.buttonCenter}>
                    <Button variant="contained" type="submit" disabled={loading} endIcon={<Lock />} sx={{ mt: 2 }}>
                        {loading ? (
                            <>
                                Please wait...
                                <CircularProgress size={20} style={{ marginLeft: 8 }} />
                            </>
                        ) : (
                            'Login'
                        )}
                    </Button>
                    <Button onClick={formik.resetForm} variant="outlined" endIcon={<RotateLeft />} sx={{ mt: 2, marginLeft: 2 }}>
                        RESET
                    </Button>
                </div>
                {/* <button onClick={handleButtonClick}>Go to Another Page</button> */}
            </form >
        </>
    )

}