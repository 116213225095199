import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, Button, CircularProgress } from '@mui/material';
import api from "../../../../axios";

const QBOQuotation = ({ isOpen, closeQBOModal, LoadData, ShowTost, entity }) => {
    const [loading, setLoading] = useState(false);

    const handleStartSync = () => {
        setLoading(true);

        const user = JSON.parse(localStorage.getItem('user'));
        const token = user.token;
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };

        //console.log(entity);
        if (entity.quoteId > 0) {

            const apiUrl = `/api/Transaction/QBOPushQuotation?quoteId=${entity.quoteId}`;
            api.post(apiUrl, null, config)
                .then(response => {
                    console.log(response.data);
                    setLoading(false);
                    ShowTost(response.data);
                    LoadData();
                })
                .catch(error => {
                    console.error('Error:', error);
                    setLoading(false);
                    ShowTost('Error:', error);
                }).finally(() => {
                    closeQBOModal();
                });

        } else {

            const apiUrl = '/api/Transaction/QBOPullQuotation';
            api.post(apiUrl, null, config)
                .then(response => {
                    console.log(response.data);
                    setLoading(false);
                    ShowTost(response.data);
                    LoadData();
                })
                .catch(error => {
                    console.error('Error:', error);
                    setLoading(false);
                    ShowTost('Error:', error);
                }).finally(() => {
                    closeQBOModal();
                });
        }

    };

    return (
        <Dialog open={isOpen} onClose={closeQBOModal}>
            <DialogTitle>Sync</DialogTitle>
            <DialogContent>
                <Button type='button' disabled={loading} variant='contained' size='large' fullWidth margin='normal' onClick={handleStartSync}>
                    {loading ? (
                        <>
                            Please wait...
                            <CircularProgress size={20} style={{ marginLeft: 8 }} />
                        </>
                    ) : (
                        'Start Synchronization'
                    )}
                </Button>
            </DialogContent>
        </Dialog>
    )

};

export default QBOQuotation;
