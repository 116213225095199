import React, { useState } from 'react';
import { Typography, List, ListItem, ListItemText, Divider, ListItemIcon, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import {
    Dashboard as DashboardIcon,
    Assignment as SurveyIcon,
    Engineering as JobIcon,
    RequestQuote as QuotationIcon,
    Receipt as InvoiceIcon,
    ContactPhone as CommunicationIcon,
    SettingsApplications as SettingIcon,
    LocalFireDepartment as NestedIcon,
} from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';

const itemsList = [
    { title: 'Dashboard', hasNested: false, icon: <DashboardIcon />, path: '/client' },
    { title: 'Survey', hasNested: false, icon: <SurveyIcon />, path: '/client/survey' },
    { title: 'Jobs', hasNested: false, icon: <JobIcon />, path: '/client/jobs' },
    { title: 'Quotation', hasNested: false, icon: <QuotationIcon />, path: '/client/quotation' },
    { title: 'Invoice', hasNested: false, icon: <InvoiceIcon />, path: '/client/invoice' },
    { title: 'Communication', hasNested: false, icon: <CommunicationIcon />, path: '/client/communication' },
    { title: 'Change Password', hasNested: false, icon: <SettingIcon />, path: '/client/changepassword' },
];

const SidebarClient = ({ handleDrawerClose }) => {

    const [openItems, setOpenItems] = useState({});
    const navigate = useNavigate(); // Use useNavigate instead of useHistory

    const handleClick = (index, path) => {
        setOpenItems((prevOpenItems) => ({
            ...prevOpenItems,
            [index]: !prevOpenItems[index],
        }));
        // Navigate to the specified path
        navigate(path); // Use navigate instead of history.push
    };

    const handleNestedItemClick = () => {
        handleDrawerClose();
    };

    const handleNonNestedClick = (hasNested) => {
        console.log(hasNested);
        if (hasNested == false) {
            handleDrawerClose();
        }
    };

    return (
        <List style={{marginBottom:'60px'}}>
            {itemsList.map((item, index) => (
                <React.Fragment key={index}>
                    <ListItem button onClick={() => { handleClick(index, item.path); handleNonNestedClick(item.hasNested) }}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={
                            <Typography variant="body1">
                                {item.title}
                            </Typography>
                        } />
                        {item.nestedItems && item.nestedItems.length > 0 && (openItems[index] ? <ExpandLess /> : <ExpandMore />)}
                    </ListItem>
                    {item.nestedItems && item.nestedItems.length > 0 && (
                        <Collapse in={openItems[index]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {item.nestedItems.map((nestedItem, nestedIndex) => (
                                    <ListItem button key={nestedIndex} onClick={() => { handleClick(nestedIndex, nestedItem.path); handleNestedItemClick(); }}>
                                        <ListItemIcon sx={{ marginLeft: '15px' }}><NestedIcon /></ListItemIcon>
                                        <ListItemText primary={nestedItem.title} />
                                    </ListItem>
                                ))}
                            </List>
                        </Collapse>
                    )}
                </React.Fragment>
            ))}
        </List>

    )
}

export default SidebarClient;