import * as Yup from 'yup';

export const LoginSchema = Yup.object({
    loginId:Yup.string().min(3,"Too Short!").required("Please enter login id"),
    password:Yup.string().min(3,"Too Short!").required("Please enter password"),
})

export const ForgetPasswordSchema = Yup.object({
    mobile:Yup.string().min(10,"Too Short!").required("Please enter mobile number"),
    email:Yup.string().min(3,"Too Short!").required("Please enter email address"),
    message:Yup.string().min(15,"Too Short!").max(250).required("Please enter message"),
})