import React, { useState, useEffect } from 'react';
import { Avatar, Grid, FormControl, InputLabel, Select, MenuItem, TextField, Button, CircularProgress, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, IconButton, Skeleton, Tooltip } from '@mui/material';
import { Edit as EditIcon, Close as DeleteIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../../axios';
import UserImageModel from '../users/manageusers/UserImageModel';

const SurveyTechnician = (props) => {
    const [data, setData] = useState([]);
    const [tech, setTechs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [entity, setEntity] = useState({
        id: 0,
        createdDate: '',
        surveyId: props.id,
        techId: 0,
    });

    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get(`/api/Survey/GetAllSiteSurveyTechnician?surveyId=${props.id}`, config);
            setData(response.data || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchTech = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user.token;
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            };
            const response = await api.get(`/api/Survey/GetAllTechnician`, config);
            setTechs(response.data || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        fetchTech();
    }, []);

    const formik = useFormik({
        initialValues: {
            id: entity.id || 0,
            surveyId: props.id,
            techId: entity.techId || 0,
        },
        validationSchema: Yup.object({
            techId: Yup.string().required('Please select Technician'),
        }),
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const apiUrl = '/api/Survey/SaveSiteSurveyTechnician';
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const data = new FormData();
                data.append('id', values.id);
                data.append('surveyId', values.surveyId);
                data.append('techId', values.techId);
                const response = await api.post(apiUrl, data, config);
                if (response.status === 200) {
                    fetchData();
                    console.log('created successfully.');
                    formik.resetForm();
                } else {
                    console.error('Something went wrong.');
                }
            } catch (error) {
                console.error('Error:', error.message);
            } finally {
                setLoading(false);
            }
        },
    });


    const handleEdit = (editData) => {
        formik.setValues({
            id: editData.id,
            surveyId: props.id,
            techId: editData.techId,
        });
    }

    const handleDelete = async (id) => {
        const confirmation = window.confirm('Are you sure you want to delete?');
        if (confirmation) {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                const token = user.token;
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const response = await api.post(`/api/Survey/DeleteSiteSurveyTechnician?id=${id}`, null, config);
                if (response.status === 200) {
                    fetchData();
                }
            } catch (error) {
                console.error('Error deleting data:', error);
            }
        }
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [imagePath, setImagePath] = useState('');
    const openImageModal = (path) => {
        setImagePath(path);
        setModalIsOpen(true);
    };

    const closeImageModal = () => {
        setModalIsOpen(false);
    };

    return (
        <>
            <UserImageModel isOpen={modalIsOpen} closeImageModal={closeImageModal} imagePath={imagePath} />

            {props.pending ? (

                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={1} >
                        <Grid item xs={12} sm={5}>
                            <FormControl fullWidth margin='normal'>
                                <InputLabel id='techId-label'>Technician</InputLabel>
                                <Select
                                    name='techId'
                                    label='Tech Id'
                                    labelId='techId-label'
                                    value={formik.values.techId}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.techId && Boolean(formik.errors.techId)}
                                    helperText={formik.touched.techId && formik.errors.techId}
                                >
                                    {tech.map(data => (
                                        <MenuItem key={data.id} value={data.id}>
                                            {data.firstName} {data.lastName} - {data.mobile}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button
                                type='submit'
                                disabled={loading}
                                variant='contained'
                                color='primary'
                                size='large'

                                style={{ marginTop: 20 }}
                            >
                                {loading ? (
                                    <>
                                        Please wait...
                                        <CircularProgress size={20} style={{ marginLeft: 8 }} />
                                    </>
                                ) : (
                                    'Add Technician'
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </form>

            ) : (
                <span></span>
            )}


            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Image</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Mobile</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <Skeleton animation='wave' />
                                </TableCell>
                            </TableRow>
                        ) : (
                            data.map((location, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Avatar src={process.env.REACT_APP_BASE_URL + location.Technician.img} onClick={() => { openImageModal(location.Technician.img) }} variant="square" />
                                    </TableCell>
                                    <TableCell>{location.Technician.firstName} {location.Technician.lastName}</TableCell>
                                    <TableCell>{location.Technician.mobile}</TableCell>
                                    <TableCell>{location.Technician.email}</TableCell>
                                    <TableCell>{location.Technician.isActive ? 'Active' : 'Not Active'}</TableCell>
                                    <TableCell>
                                        {props.pending ? (
                                            <Tooltip title='Delete'>
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => handleDelete(location.id)}
                                                    style={{ float: 'right' }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>

                                        ) : (
                                            <span></span>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
};

export default SurveyTechnician;
