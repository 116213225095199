import React, { useState, useEffect } from "react";
import {
    Drawer,
    Divider,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Typography,
    List,
    ListItem,
    ListItemText,
    CircularProgress
} from "@mui/material";
import api from "../../../../axios";

const SavePermission = ({ isOpen, toggleDrawerPermission, roleId }) => {
    const [loading, setLoading] = useState(false);
    const [modules, setModules] = useState([]);
    const [selectedModules, setSelectedModules] = useState([]);
    const [permissionId,setPermissionId]=useState(0);

    const fetchData = async () => {
        try {
            setLoading(true);

            const user = JSON.parse(localStorage.getItem("user"));
            const token = user.token;

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            const response = await api.get(
                `/api/Users/GetPermissionByRoleId?roleId=${roleId}`,
                config
            );

            //console.log("Response Data:", response.data);

            if (typeof response.data.modules === "string") {
                // Parse the string into a JavaScript object
                setPermissionId(response.data.id);
                const parsedModules = JSON.parse(response.data.modules);

                if (Array.isArray(parsedModules)) {
                    setModules(parsedModules || []);
                    const initialSelectedModules = parsedModules
                        .filter((module) => module.isAuth)
                        .map((module) => module.moduleId);
                    setSelectedModules(initialSelectedModules);
                } else {
                    console.error("Invalid response data format:", parsedModules);
                }
            } else {
                console.error("Invalid response data format:", response.data.modules);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        if (isOpen) {
            fetchData();
        }
    }, [isOpen]);

    const handleCheckboxChange = (moduleId) => {
        setSelectedModules((prevSelectedModules) => {
            if (prevSelectedModules.includes(moduleId)) {
                // Module was selected, so remove it from the list
                return prevSelectedModules.filter((id) => id !== moduleId);
            } else {
                // Module was not selected, so add it to the list
                return [...prevSelectedModules, moduleId];
            }
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);

            const user = JSON.parse(localStorage.getItem("user"));
            const token = user.token;

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            const requestBody = {
                id:permissionId,
                roleId: roleId,
                modules: JSON.stringify(
                    modules.map((module) => ({
                        moduleId: module.moduleId,
                        moduleName: module.moduleName,
                        isAuth: selectedModules.includes(module.moduleId)
                    }))
                )
            };

            await api.post("/api/Users/UpdatePermission", requestBody, config);

            console.log("Permissions updated successfully");
        } catch (error) {
            console.error("Error updating permissions:", error);
        } finally {
            toggleDrawerPermission();
            setLoading(false);
        }
    };

    return (
        <Drawer anchor="right" open={isOpen} onClose={toggleDrawerPermission}>
            <div style={{ width: "300px", paddingLeft: "10px", paddingRight: "10px" }}>
                <h2>Save Permissions</h2>
                <Divider style={{ marginBottom: "25px" }} />

                <form onSubmit={handleSubmit}>
                    <List>
                        {modules.map((module) => (
                            <ListItem key={module.moduleId}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedModules.includes(module.moduleId)}
                                            onChange={() => handleCheckboxChange(module.moduleId)}
                                        />
                                    }
                                    label={module.moduleName}
                                />
                            </ListItem>
                        ))}
                    </List>

                    <Box mt={3}>
                        <Button
                            type="submit"
                            disabled={loading}
                            fullWidth
                            variant="contained"
                            size="large"
                            style={{ marginTop: "10px" }}
                        >
                            {loading ? (
                                <>
                                    Please wait...
                                    <CircularProgress size={20} style={{ marginLeft: 8 }} />
                                </>
                            ) : (
                                "Save"
                            )}
                        </Button>
                    </Box>
                </form>
            </div>
        </Drawer>
    );
};

export default SavePermission;
